import React, { Component } from 'react';
import { Avatar } from 'antd';
import { MentionsInput, Mention } from 'react-mentions';

const axios = require('axios')

export default class MentionInput extends Component{

	constructor(props){
		super(props);
		this.state = {
			value: '',
		}
	}


	componentDidMount(){
	}


	/**
	 * @methotOf handleChange
	 * @description Se ejecuta cada vez que hay un cambio en el texto y obiene los usuarios mencionados
	 * */
	handleChange = (value) => {
		this.setState({value: value});

		let mentions = []
		if(value && value.includes("]{")){
            mentions = value.match(/[^{}]+(?=})/g);
		}

		//Formatea el texto para que no aparezcan los ids del usaurio mencionado
		value = value.replaceAll(/\{.+?}/g, '').replaceAll(']','').replaceAll('[','@')

		this.props.updateText({text: value, mentions})

	}

	/**
	 * @methotOf MentionInput
	 * @description busca los usaurios filtrando con la busqueda que ingresa el usuario
	 * */
	fetchUsers(query, callback) {

	 	if (!query) return
		
		axios.get("/usuarios", {
			params:{
				search: query
			}
		}).then(({data}) => {
	    		console.log("data.data.itemsList", data.data.itemsList);
	    	return data.data.itemsList.map(e => {
				return {
					id: e._id,
                    display: e.nombres + ' ' + e.apellido_paterno,
                    color: e.color,
                    avatar: e.avatar,
                    nombre: e.nombres + ' ' + e.apellido_paterno
				}
			})
	    }).then(callback)
	}

	/**
	 * @methotOf clean
	 * @description limpia el input
	 * */
	clean = () => {
		this.setState({value: ''})
	}


	render(){
		return(
			<MentionsInput
                    value={this.state.value}
                    onChange={value => this.handleChange(value.target.value)}
                    className="mentions-input"
                    placeholder="Escribe un mensaje..."
                    allowSuggestionsAboveCursor={true}
                    allowSpaceInQuery={true}
                    onKeyDown={(event)=>this.props.onKeyDownPress(event)}
                    singleLine={true}
                >
                    <Mention
                        markup="[__display__]{__id__}"
                        data={this.fetchUsers}
                        displayTransform={(id, display) => {
                            return `@${display}`
                        }}
                        renderSuggestion={(
                            suggestion,
                            search,
                            highlightedDisplay,
                            index,
                            focused
                        ) => (
                            <div className={`user ${focused ? 'focused' : ''}`} key={index} style={{padding: '4px'}}>
                                {suggestion.display}
                            </div>
                        )}
                    />
                </MentionsInput>
		)
	}

}
