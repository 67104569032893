import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, Spin, Space, Popconfirm, message } from 'antd';
import axios from 'axios';
import { EditOutlined, DeleteOutlined, } from "@ant-design/icons";
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'
import ModalBeneficiario from './ModalBeneficiario'


const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class Cuentas
 * @extends {React.Component}
 */
export default class Cuentas extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			beneficiarios: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
               
            }, 
            modalBeneficiario: false,
            beneficiario_id: null,
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getBeneficiarios();
	}

	/**
	* @method getBeneficiarios
	* @description Obtiene las Cuentas del usuario
	*/
	getBeneficiarios = ({
        page = this.state.beneficiarios.page,
        limit = this.state.beneficiarios.limit,
    } = this.state.beneficiarios) => {
		this.setState({ loading: true })
		axios.get('/customer/beneficiarios', {
			params: {
				page: page,
                limit: limit,
			}
		}).then(response => {
			
            // console.log(response)



			this.setState({
				beneficiarios: response.data,
			})

		}).catch(error => {
			
			message.error('Ocurrio un error al cargar datos')

		}).finally(() => this.setState({ loading: false }))
	}

	render() {

		return (
			<>
				<Spin spinning={false}>
					<Content className="hm-layout-content layout-dashboard" >
						<PageHeader className="site-page-header" ghost={false} title="Beneficiarios">
						</PageHeader>
						<List
							loading={this.state.loading}
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.beneficiarios.data}
							locale={{ emptyText: "Sin Beneficiarios" }}
							pagination={{
                                current: this.state.beneficiarios.page,
                                pageSize: this.state.beneficiarios.limit,
                                total: this.state.beneficiarios.total,
                                position: 'bottom',
                                className: "flex-left ml-1",
                                onChange: (page) => this.getBeneficiarios({page}),
                                hideOnSinglePage: true
                            }}
							header={<Row className=" width-100 pl-1 pr-1 ">
								<Col span={2} className="center">
								</Col>
								<Col xs={{span: 10, offset:2}} md={{span:6, offset:0}} className="center">
									Nombre
								</Col>
								<Col xs={{span: 10, offset:0}} md={{span:6, offset:0}} className="center">
									Teléfono
								</Col>
								<Col xs={{span: 10, offset:4}} md={{span:7, offset:0}} className="center">
									Email
								</Col>
							</Row>
							}
							renderItem={item => (
								<List.Item className="component-list-item">
									<Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
										<Row className="width-100 ">
											<Col span={2}>
												<Avatar src="/img/zeus_logo_sin_texto.svg" />
											</Col>
											<Col className="center" xs={{span: 10, offset:2}} md={{span:6, offset:0}}>
												<Text>{item.nombre}</Text>
											</Col>
											<Col className="center" xs={{span: 10, offset:0}} md={{span:6, offset:0}}>
												<Text>{item.telefono}</Text>
											</Col>
											<Col className="center" xs={{span: 10, offset:4}} md={{span:7, offset:0}}>
												<Text>{item.email}</Text>
											</Col>
											<Col xs={{span: 10, offset:0}} md={{span:3, offset:0}} className="center">
												<Space direction="horizontal">
													<Button
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														type="primary"
														title="Editar"
														onClick={() => this.setState({ modalBeneficiario: true, beneficiario_id: item._id })}>
													</Button>
													<Popconfirm
														placement="topRight"
														title="¿Deseas eliminar esta beneficiario?"
														onConfirm={() => axios.delete('/beneficiario/delete', { params: { beneficiario_id: item._id } }).then((response) => {
															message.success('Beneficiario Eliminado')
															this.getBeneficiarios()
														}).catch((error) => {
															message.error('Beneficiario no eliminado')
															this.getBeneficiarios();
														})
														}
														okText="Si"
														cancelText="No"
													>
														<Button
															type="primary"
															danger
															icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar"
														/>
													</Popconfirm>
												</Space>
											</Col>
										</Row>
									</Card>
								</List.Item>
							)}
						/>
					</Content>
					<FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modalBeneficiario: true, beneficiario_id: undefined })} />

				</Spin>
				<ModalBeneficiario
					visible={this.state.modalBeneficiario}
					onClose={() => {
						this.setState({ modalBeneficiario: false, beneficiario_id: undefined })
						this.getBeneficiarios(this.state.page)
					}}
					beneficiario_id={this.state.beneficiario_id}
				/>
			</>
		)
	}
}