import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card } from 'antd'
import axios from 'axios';
import { IconBtnDelete, IconBtnEdit, } from '../../Widgets/Iconos'
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'
import ModalCategorias from './ModalCategorias'
const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Categorias
 * @extends {Component}
 * @description Vista donde se listan todos los Categorias
 */
export default class Categorias extends Component {



  /**
   *Creates an instance of Categorias.
   * @param {*} props
   * @memberof Categorias
   */
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      page: 1,
      limit: 10,
      total: 0,

      producto_selected: null,
      search: this.props.search
    }
  }


  componentDidMount() {
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    this.getCategorias();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      this.setState({ search: this.props.search }, () => this.getCategorias())
    }
  }

  /**
  * @method getCategorias
  * @description Obtiene ls lista de Categorias
  */
  getCategorias = (page = this.state.page) => {
    this.setState({ loading: true })
    axios.get('/categorias', {
      params: {
        page: page,
        search: this.state.search,
        limit:this.state.limit
      }
    }).then(response => {
      
      this.setState({
        data: response.data.data.itemsList,
        page: response.data.data.paginator.currentPage,
        total: response.data.data.paginator.itemCount,
      })
    }).catch(error => {
      
      message.error('Error al obtener la Información')
    }).finally(() => this.setState({ loading: false }))
  }


  render() {
    return (
      <>
          <PageHeader className="admin-page-header" title="Categorias" />
          <Content className="admin-content content-bg pd-1">
            <List
              loading={this.state.loading}
              className="component-list"
              itemLayout="horizontal"
              locale={{ emptyText: "Sin Categorias" }}
              dataSource={this.state.data}
              pagination={{
                current: this.state.page,
                pageSize: this.state.limit,
                total: this.state.total,
                onChange: page => this.getCategorias(page),
                showSizeChanger: false,
                hideOnSinglePage: false,
                position: 'bottom',
                className: "flex-left"

              }}
              header={<Row className="width-100" >
                <Col  xl={10} lg={10}  md={10}  xs={24}  className="center md-flex-left">
                  <Text strong>Nombre</Text>
                </Col>
                <Col  xl={10} lg={10}  md={10}  xs={24}  className="center md-flex-left">
                  <Text strong>Descripción</Text>
                </Col>

                <Col  xl={3}  lg={3}   md={4}  xs={2} className="center md-flex-left">
                  <Text strong></Text>
                </Col>

              </Row>
              }

              renderItem={item => (
                <List.Item className="component-list-item width-100">
                  <Card className="card-list">
                    <Row className="width-100 ">
                      <Col  xl={10} lg={10}  md={10}  xs={24}  className="center md-flex-left">
                        <Text ellipsis strong>{item.nombre}</Text>
                      </Col>
                      <Col  xl={10} lg={10}  md={10}  xs={24}  className="center md-flex-left">
                        <Text ellipsis strong>{item.descripcion}</Text>
                      </Col>
                      <Col  xl={3}  lg={3}   md={4} xs={24} className="center md-flex-left">
                        <Space wrap>
                          <Button type='primary' className='btn-secondary' icon={<IconBtnEdit />} onClick={() => this.setState({ modal_visible: true, categoria_selected: item._id })} />
                          <Popconfirm
                            placement="topRight"
                            title="¿Deseas eliminar esta categoría?"
                            onConfirm={() => axios.delete('/categorias/delete', { params: { id: item._id } }).then((response) => {
                              message.success(response?.data?.message)
                              this.getCategorias()
                            })
                              .catch((error) => {
                                message.error(error?.response?.data?.message);
                                this.getCategorias();
                              })
                            }
                            okText="Si"
                            cancelText="No"
                          >
                            <Button type="primary" className='btn-delete' danger title="Eliminar" icon={<IconBtnDelete />} />
                          </Popconfirm>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              )}
            />

          </Content>
        <FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modal_visible: true, categoria_selected: null })} />
        <ModalCategorias
          visible={this.state.modal_visible}
          id={this.state.categoria_selected}
          onClose={() => {
            this.setState({ modal_visible: false, categoria_selected: null })
            this.getCategorias(this.state.page)
          }}
        />
      </>
    )
  }
}
