import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';

// componentes
import Reventas from '../../Components/Admin/Reventas/Reventas'

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterInversion(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Reventas {...props}  />} />
        </Routes>
    )
}

 export default RouterInversion

