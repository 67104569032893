import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, message, Empty, Spin, Radio, Carousel as AntCarousel, Image, Space, Menu, List, Avatar, Tooltip } from 'antd';
import Carousel from 'react-multi-carousel';
import axios from 'axios'
import { Link } from 'react-router-dom'

import ModalReventa from './ModalReventa'

import 'react-multi-carousel/lib/styles.css';


import "../../../Styles/Modules/Customer/Inversion.scss";
import { FilePdfOutlined, ShoppingCartOutlined, RightOutlined } from "@ant-design/icons";
import Decimal from "decimal.js";


const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const moment = require('moment')

const momentPreciseRangePlugin = require('moment-precise-range')(moment);


/**
 *
 *
 * @export
 * @class Inversion
 * @extends {React.Component}
 */
export default class  Inversion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            reventas: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            },
            reventa: null,
            visibleModalReventa: false
        }
    }


    componentDidMount() {
        // axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        // this.getHaciendas()

        if (this.props.hacienda_id)
            this.getReventas()
    }


    componentDidUpdate(prevProps) {
        if (prevProps.hacienda_id !== this.props.hacienda_id && this.props.hacienda_id !== null)
            this.getReventas()

    }


    /**
* @method getreventas
* @description Obtiene las reventas realizadas
*/
    getReventas = ({
        page = this.state.reventas.page,
        limit = this.state.reventas.limit,
    } = this.state.reventas) => {
        this.setState({ reventas: { ...this.state.reventas, page, limit, }, loading: true })
        axios.get('/customer/reventas', {
            params: {
                page,
                limit,
                comprador: true,
                hacienda_id: this.props.hacienda_id
            }
        }).then(({ data }) => {
            // console.log('DATA REVENTAS', data);
            this.setState({
                reventas: {
                    ...this.state.reventas,
                    ...data
                }
            })
        }).catch(error => {
            message.error("No fue posible obtener las reventas")
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return [
            <List
                loading={this.state.loading}
                className="component-list"
                itemLayout="horizontal"
                // dataSource={this.state.transacciones.data}
                dataSource={this.state.reventas.data}
                locale={{ emptyText: "Sin Reventas " }}
                pagination={{
                    current: this.state.reventas.page,
                    pageSize: this.state.reventas.limit,
                    total: this.state.reventas.total,
                    position: 'bottom',
                    className: "flex-left",
                    hideOnSinglePage: true,
                    onChange: (page) => this.getReventas({ page })
                }}
                style={{ width: "100%" }}
                renderItem={item => (
                    <List.Item className="component-list-item">
                        <Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
                            <Row className="width-100 ">
                                <Col span={2} className="center">
                                    <Avatar src="/img/zeus_logo_sin_texto.svg" />
                                </Col>
                                <Col className="center" xs={4} md={7}>
                                    {/* {"{{FECHA}}"} */}
                                    <Text>{item?.cliente_name} {item?.estatus === 2 ? "vendió" : "vende"} {item?.cantidad} Planta(s)</Text>
                                </Col>
                                <Col className="center" xs={4} md={6}>
                                    <Text>{moment(item.fecha).format("DD-MM-YYYY")}</Text>
                                </Col>
                                <Col className="center" xs={18} md={3}>
                                    <Text>{item?.precio_reventa.toLocaleString("en-US", { style: "currency", currency: "USD" })} {item.moneda}</Text>
                                </Col>
                                <Col className="center" xs={18} md={3}>
                                    {/* {"{{TOTAL}}"} */}
                                    <Text>{Decimal(item?.precio_reventa || 0).mul(item.cantidad).toDecimalPlaces(2).toNumber().toLocaleString("en-US", { style: "currency", currency: "USD" })} </Text>
                                </Col>
                                <Col xs={18} md={3} className="center">
                                    <Tooltip title={item?.pendiente ? "Esta reventa tiene una transaccion pendiente" : item.estatus === 2 ? "Vendido" : "Comprar"}>
                                        <Button
                                            disabled={item?.pendiente || item?.estatus === 2}
                                            onClick={() => {
                                                this.setState({
                                                    visibleModalReventa: true,
                                                    reventa: item._id
                                                })
                                            }}
                                            icon={<ShoppingCartOutlined />} style={{ float: "right" }}>
                                            {item.estatus === 2 ? 'VENDIDO' : 'COMPRAR'}
                                        </Button>
                                    </Tooltip>

                                </Col>
                                
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />,
            <ModalReventa
                visible={this.state.visibleModalReventa}
                reventa={this.state.reventa}
                id={this.props.hacienda_id}
                hacienda={this.props.hacienda}
                onClose={() => {
                    this.setState({ visibleModalReventa: false })
                    this.getReventas()
                }}
            />
        ]
    }
}