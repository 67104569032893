import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'animate.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import moment from "moment"

function convertToMoney(string = false) {
  try {
    if (this) {
      var r = this.toString().replace(',', '').match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return (string === true) ? parseFloat(r).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(r)
    }
    else
      return 0.00
  } catch (error) {
    return 0.00
  }
}



Number.prototype.toMoney = convertToMoney

String.prototype.toMoney = convertToMoney

JSON.tryParse = function tryParse(stringParse, returnOnError = null) {
  try {
    return JSON.parse(stringParse)
  } catch (error) {
    console.log("error", error)
    return returnOnError
  }
}


let banner = (process.env.REACT_APP_ENVIROMENT === "staging" || process.env.NODE_ENV === "development") ? <div className='test-banner'>
  <p className='test-banner-text'>
    Esta software es solo una versión de prueba, las actividades y modificaciones que se realicen en esta versión no afectarán el entorno real.
  </p>
</div> : null

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


