import React, {useContext} from "react";
import { Layout, Button, Typography, Menu, Dropdown, Space, message, Col, Row } from 'antd';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
//componentes
import ModalAuth from '../Auth/ModalAuth'
import Recovery from '../Auth/Recovery'
import UpdatePassword from '../Auth/UpdatePassword'
//css
import "../../Styles/Modules/headers.scss";
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { User, SetUser } from '../../Hooks/Logged';
import MobileMenu from "./MobileMenu";
const { Header } = Layout;
const { Text } = Typography;


class HeaderPublic extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			email: this.props.params.email,
			token: decodeURIComponent(this.props.params.token),
			tarifa: 0,
			user: {},
			to: '',
		}
	}

	componentDidMount() {

		if (sessionStorage.getItem('token') != null || sessionStorage.getItem('token' != undefined)) {
			axios.get('/user/logged', {
				headers: { Authorization: sessionStorage.getItem('token') }
			}).then(({ data }) => {

				console.log('data logged',data)
				if(data.data.tipo === 3)
                    this.setState({to: '/customer/resume', user: data.data})
                else if (data.data.tipo === 1 || data.data.tipo === 2)
                    this.setState({to: '/admin/dashboard', user: data.data});
				
			}).catch((error) => { })
		}

		this.getPrecioKg()
	}

	getPrecioKg = () => {
		axios.get("/plantas")
			.then(({data}) => {
				this.setState({tarifa: data.data.precio_kilo})
			})
			.catch(error => {
				message.error("Error al obtener tarifa")		
			})
	}

	render() {

		const menu = (
			<Menu>
				<Menu.Item onClick={() => {this.props.changeLanguage("en"); this.props.updateText()}}>
					English
				</Menu.Item>
				<Menu.Item onClick={() => {this.props.changeLanguage("es"); this.props.updateText()}}>
					Español
				</Menu.Item>
			</Menu>
		);
		return (
			<>
				<div className="header-public-space">
				</div>
				<Header className={`header-public header-public-${this.props.compact ? 'compact' : 'normal'}`} >
					
				<Row className="w-100" align={"middle"} gutter={[16, 16]} justify={"space-between"}>
					<Col xs={9} md={4}>
						<img className="header-public-logo w-100" src="/img/zeus_logo.svg" alt="logo Zeus" />
					</Col>
					<Col xs={16} md={16} className="header-public-menu-desktop">
						<Menu className="header-menu ml-2 width-100" theme="dark" mode="horizontal" defaultSelectedKeys={['1']} triggerSubMenuAction="click">
							<Menu.Item key="1">
								
								<a href="/#home">
									<Text className="text-white">
										{t("header.home")}
									</Text>
								</a>
								
							</Menu.Item>
							<Menu.Item key="2">
								<a href="#about-us">
									<Text className="text-white">{t("header.about_us")}</Text>
								</a>
							</Menu.Item>
							{/* <Menu.Item key="3">{t("header.faq")}</Menu.Item> */}
							<Menu.Item key="4">
								<a href="/#contact-us">
									<Text className="text-white">{t("header.contact")}</Text>
								</a>
							</Menu.Item>

							<Menu.Item key="5" >
									<a href="/tyc#start">
										<Text className="text-white">{t("header.terms")}</Text>
									</a>
							</Menu.Item>
							<Menu.Item key="6" >
							
								
								<a href="/#haciendas">
									<Text className="text-white">{t("header.ranchs")}</Text>
								</a> 
							</Menu.Item>
							<Menu.Item key="10" className="right menu-item-desktop">
								<Space>
									<Text className="text-cyan-light">{t("header.price")}</Text>
									<Text strong className="text-white">$ {this.state.tarifa?.toMoney ? this.state.tarifa?.toMoney(true) : this.state.tarifa} MXN kg</Text>
									<Dropdown overlay={menu}>
										<Button type="link" ghost className="ant-dropdown-link text-white" onClick={e => e.preventDefault()}>
											{t("header.lang")} <DownOutlined />
										</Button>
									</Dropdown>
								</Space>
							</Menu.Item>
						</Menu>
					</Col>
					<Col className="header-public-menu-mobile">
						<Button 
							className="header-menu-btn"
							type="default"
							onClick={() => this.setState({isOpen: true})}
							icon={<MenuOutlined />}
						/>
					</Col>
					<Col xs={0} md={4} lg={4} className="header-public-logged-btns header-public-desktop">
						<Space>
						{this.props.user ? (<>
							<Button 
								className="btn-green logged-btn"
								onClick={() => this.props.navigate(this.props.user?.rol_id ? "/admin/dashboard" : "/customer/resume")}
							>
								{t("header.account")}
							</Button>
							<Button 
								className="btn-green logged-btn"
								onClick={() => {
									axios.get('/logout')
										.then(() => {
											this.props.setUser(0)
											message.success(t("header.closed_session"))
										})
										.catch(error => {
											console.log(error)
											message.error(t("header.logout_error"))
										})
								}}
							>
								{t("header.logout")}
							</Button>
							</>) : (
							<Button className="btn-green" onClick={() => this.setState({ modalAuth: true })}>{t("header.invest")}</Button>
						)}
						</Space>
					</Col>
				</Row>

				</Header>
				<ModalAuth
					visible={this.state.modalAuth}
					onClose={() => {this.setState({ modalAuth: false});}}
					openRecovery={() => this.setState({ modalAuth: false, modalRecovery: true })}
				/>

				<Recovery
					visible={this.state.modalRecovery}
					onClose={() => this.setState({ modalRecovery: false })}
				/>

				<UpdatePassword
					visible={this.props.params.token}
					token={this.state.token}
					onClose={() => this.setState({ modalRecovery: false, token: undefined })}
					onLogin={() => this.setState({ modalAuth: true, token: undefined })}
				/>
				<MobileMenu
					open={this.state.isOpen}
					tarifa={this.state.tarifa}
					updateText={this.props.updateText}
					changeLanguage={this.props.changeLanguage}
					openAuth={() => {this.setState({ modalAuth: true})}}
					onClose={() => this.setState({isOpen: false})}
				/>
			</>
		)
	}
}


export default React.forwardRef((props, ref) => {


	const { t, i18n } = useTranslation();
	const setUser = useContext(SetUser)
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const params = useParams()

	const changeLanguage = lng => i18n.changeLanguage(lng)


	return <HeaderPublic ref={ref} changeLanguage={changeLanguage} setUser={setUser} navigate={navigate} {...props} params={params} />
});



// export default function (props) {

// 	const { t, i18n } = useTranslation();
//     const setUser = useContext(SetUser)
// 	const navigate = useNavigate()
// 	const [searchParams] = useSearchParams()
// 	const params = useParams()

// 	const changeLanguage = lng => i18n.changeLanguage(lng)

// 	return <HeaderPublicWithRef  changeLanguage={changeLanguage} setUser={setUser} navigate={navigate} {...props} params={params}/>
// }
