import React, { Component, useState } from "react";
import { Row, Col, Modal,  } from 'antd';

import Login from './Login'
import Registro from './Registro'

import './../../Styles/Global/modales.css'


/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {
    const { visible = false, onClose = () => { } } = props

    const [registro, setRegistro] = useState(false)
    const [estatus, setEstatus] = useState(0)


    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={450}
        className="modal-border"
    >
        <Row className="mb-1">
            <Col span={12} className="flex-left">
                <img src="/img/zeus_logo.svg" width="150" />
            </Col>
        </Row>
        {registro ?
            <Registro {...props} changeView={() => {setRegistro(!registro);}} estatus={estatus} />
            :
            <Login {...props} changeView={(e) => {setEstatus(e); setRegistro(!registro);}} />}
    </Modal>

}