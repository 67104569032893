import React, { useEffect, useState, } from "react";
import { Dropdown, Checkbox, List, } from "antd";
import { DownOutlined } from '@ant-design/icons';

import PropTypes from 'prop-types';

import './filtros.css'
const axios = require('axios')

/**
 *
 *
 * @param {*} props
 * @returns Component
 */
function FilterCategoria(props) {

    const { onChange, value } = props

    /** @type Boolean  @visible */
    let [visible, setVisible] = useState(false)
    /** @type Array  @data */
    let [data, setData] = useState([])
    /** @type Boolean  @selected */
    let [selected, setSelected] = useState({})
    /** @type Boolean  @loading */
    let [loading, setLoading] = useState([])
    /** @type Integer  @page */
    let [page, setPage] = useState(1)



    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token');
        get()
    }, [])


    const triggerChange = (selected) => {
        onChange(Object.keys(selected));
        setSelected(selected)
    };

    /**
     *
     *
     * @param {number} currentPage Pagina actual
     * @param {string|null} search Nombre del area
     * 
     * @method get
     * @description Obtenemos la lista de opciones.
     */
    const get = ({ currentPage = page, search = null } = {}) => {
    }


    /**
     *
     *
     * @param {string} _id Identificador de area
     * @param {string} nombre Nombre del area
     * @method update
     * @description Cuando se actualiza un valor, se agrega al objeto de seleccionados
     */
    const update = (_id,nombre) => {
        if (selected[_id])
            delete selected[_id]
        else
            selected[_id] = nombre
        triggerChange({ ...selected });
    }


    return (
        <Dropdown
            onVisibleChange={setVisible}
            visible={visible}
            trigger={["click"]}
            overlayStyle={{backgroundColor:'#fff'}}
            className="dropdown-filter"
            overlay={
                <div >
                    <List
                        size="small"
                        dataSource={data}
                        loading={loading}
                        renderItem={({ _id, nombre, color, avatar, }) => <List.Item >
                            <Checkbox
                                onChange={() => update(_id,nombre)}
                                style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                                checked={selected[_id]}
                            >
                                {nombre}
                            </Checkbox>

                        </List.Item>}
                    />
                </div>
            }>
            <a>Categoria  <DownOutlined /></a>
        </Dropdown>

    )
}

FilterCategoria.propTypes = {
    onChange: PropTypes.func,
    onChange: PropTypes.func,
};

export default FilterCategoria
