import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Spin, Typography,  Select, Row, Col, Divider, InputNumber, Upload, Empty, DatePicker} from 'antd';
import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import FormatedInput from "../../Widgets/Inputs/FormatedInput";
const axios = require('axios').default;
const moment = require('moment');


const { Title, } = Typography;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};


/**
 *
 *
 * @export
 * @class FormCliente 
 * @extends {Component}
 */
class FormCliente extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],

            paises: [],
            estados: [],
            vendedores: [],
            pais_id: null
        }
    }

    formRef = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.cliente_id) {
            this.getCliente()
        }
        this.getPaises()
        this.getVendedores()
    }


    /**
    * @memberOf FormCliente
    * @method getPaises
    * @description obtiene una lista de los paises
    */
    getPaises = () => {
        axios.get('/paises')
            .then(response => {
                this.setState({ paises: response.data.data })
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los paises.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf FormCliente
    * @method getEstados
    * @description obtiene una lista de los estados
    */
    getEstados = (pais_id = this.state.pais_id, clean = false) => {

        if (clean)
            this.formRef.current.setFieldsValue({ estado_id: null })

        axios.get('/estados', {
            params: { pais_id }
        })
            .then(response => {
                this.setState({ estados: response.data.data })
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los estados.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    getVendedores = (search) => {
        axios.get('/usuarios', {
            params: { 
                search,
                tipos: [2]
            }
        })
            .then(response => {
                this.setState({ vendedores: response.data.data.itemsList })
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los vendedores.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf FormCliente
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = values => {

        const v = {...values}

        if(Array.isArray(v.evidencias) && v.evidencias?.length > 0)
            v.evidencias = v.evidencias?.map(archivo =>  archivo.name) 
        else 
            delete v.evidencias

        if(this.props.cliente_id){
            this.updateCliente(v)
        }else{
            this.addCliente(v)
        }
    }

    /**
    * @memberOf FormCliente
    * @method addCliente
    * @param {object} cliente El objeto cliente
    * @description Crea un nuevo registro de cliente
    */
    addCliente = cliente => {
        axios.post('/clientes/add', cliente)
            .then(response => {
                message.success('Se ha guardado exitosamente.')
                this.props.onClose()
            })
            .catch(error => {
                
                message.error(error?.response?.data?.message)
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberOf FormCliente
    * @method updateCliente
    * @param {object} cliente El objeto cliente
    * @description Actualiza la informacion de un cliente
    */
    updateCliente = cliente => {
        axios.put('/clientes/update', {
            ...cliente,
            cliente_id: this.props.cliente_id
        }).then(response => {
            message.success('Se ha guardado exitosamente.')
            this.props.onClose()
        })
            .catch(error => {
                let msg = "Ha ocurrido un error al actualizar la información."
                if (error.response?.status === 401) {
                    msg = error.response?.data?.message
                }
                message.error(msg)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf FormCliente
    * @method getCliente
    * @param {string} cliente_id Identificador del cliente
    * @description Obtiene la informacion de un cliente
    */
    getCliente = (cliente_id = this.props.cliente_id) => {

        axios.get('/cliente', { params: { cliente_id } })
            .then(({ data }) => {
                
                const cliente = data.data
                const usuario = data.data?.usuario_id

                cliente.fecha_nacimiento = moment(cliente.fecha_nacimiento)

                if(cliente.evidencias){
                    cliente.evidencias = cliente.evidencias?.map((archivo, index) => ({
                        uid: index,
                        name: archivo,
                        status: 'done',
                        url: `${axios.defaults.baseURL}/upload/${archivo}`,
                      }))
                }

                this.formRef.current.setFieldsValue({
                    ...usuario,
                    ...cliente
                })
                this.getEstados(cliente.pais_id)

            })
            .catch(error => {
                
                message.error(error?.response?.data?.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {object} e - Obtiene los datos del evento
     * @memberof FormCliente
     * @method normFile
     * @description Maneja el evento de carga de archivos
     */
    normFile = (e) => {
        const { file, fileList } = e;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.setState({ update: !this.state.update, loading: true })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de archivos, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loading: false
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response?.filename !== undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

     /**
     *
     *
     * @param {string} filename - Nombre del documento
     * @memberof FormCliente
     * @method removeFile
     * @description Elimina el documento en base a sun nombre
     */
    removeFile = (filename) => {
        axios.post("/upload/delete", {
            filename
        })
            .then(res => {
                
            })
            .catch(res => {
                
            })
    };

    render() {
        const {  formRef, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.cliente_id ? "Editar Cliente" : "Nuevo Cliente"}</Title>
                <Form
                    {...formItemLayout}
                    layout="vertical"
                    ref={formRef}
                    initialValues={form}
                    onFinish={this.onFinish}
                    scrollToFirstError={true}
                >
                    <Row className="w-100" gutter={[16, 16]}>
                        <Col span={24}>
                            <Divider orientation="left">Información Personal</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Nombres"
                                name="nombres"
                                rules={[
                                    { required: true, message: "Ingrese los nombres del cliente" }
                                ]} >
                                <Input placeholder="Luis Gerardo" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Apellido Paterno"
                                name="apellido_paterno"
                                rules={[
                                    { required: true, message: "Ingrese el apellido paterno del cliente" }
                                ]} >
                                <Input placeholder="Mendez" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Apellido Materno"
                                name="apellido_materno">
                                <Input placeholder="Rodriguez" />
                            </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                            <Divider orientation="left">Información de Contacto</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Teléfono"
                                name="telefono">
                                <PhoneInput placeholder="Teléfono"/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Email',
                                    },
                                ]}
                            >
                                <FormatedInput 
                                    controls={false} 
                                    className="width-100"
                                    trim
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">Dirección</Divider>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Calles"
                                name="calles"
                                rules={[
                                    { required: true, message: "Ingrese las calles" }
                                ]}>
                                <Input placeholder="Calles" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Número"
                                name="numero"
                                rules={[
                                    { required: true, message: "Ingrese el número" }
                                ]}>
                                <Input placeholder="Numero" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                rules={[
                                    { required: true, message: "Ingrese el código postal" }
                                ]}>
                                <InputNumber className="w-100" placeholder="Numero" />
                            </Form.Item>
                        </Col>

                        <Form.Item
                            name="fecha_nacimiento"
                            label="Fecha de Nacimiento"
                            className="width-100 "
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu Fecha de Nacimiento',
                                },
                            ]}
                        >
                            <DatePicker className=" width-100 " />
                        </Form.Item>

                        <Col span={24}>
                            <Form.Item
                                label="Ciudad"
                                name="ciudad"
                                rules={[
                                    { required: true, message: "Ingrese la ciudad" }
                                ]}>
                                <Input placeholder="Numero" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="País"
                                name="pais_id"

                                rules={[
                                    { required: true, message: "Seleccione el pais" }
                                ]}>
                                <Select onSelect={estado_id => this.getEstados(estado_id, true)}>
                                    {this.state.paises.map(({ _id, nombre }) => <Option value={_id}>{nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Estado"
                                name="estado_id"
                                rules={[
                                    { required: true, message: "Seleccione el estado" }
                                ]}>
                                <Select>
                                    {this.state.estados.map(({ _id, nombre }) => <Option value={_id}>{nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Estatus"
                                name="estatus"
                                rules={[
                                    { required: true, message: "Seleccione el estatus" }
                                ]}>
                                <Select>
                                    <Option value={1}>Se registró</Option>
                                    <Option value={2}>Contactado</Option>
                                    <Option value={3}>Ya compró algo</Option>
                                    <Option value={0}>Desistió / Cancelado</Option>
                                    <Option value={4}>Pagado</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Vendedor"
                                name="vendedor_id"
                            >
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(value) => this.getVendedores(value)}
                                    notFoundContent={<Empty
                                        description="No se encontró Cliente"
                                    />}
                                >
                                    {
                                        this.state.vendedores.map(({ _id, nombres, apellido_paterno, apellido_materno }) => {
                                            return <Option key={_id} value={_id}>{`${nombres} ${apellido_paterno || ""} ${apellido_materno || ""}`}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Evidencias"
                                name="evidencias"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={true}
                                    className="upload-large"
                                >
                                    <Button>
                                        <UploadOutlined /> Subir Evidencias
                                    </Button>
                                </Upload>   
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="fuente"
                                rules={[
                                    { required: true, message: "Seleccione la fuente" }
                                ]}>
                                <Select placeholder={"Seleccione la fuente"}>
                                    <Option value={1}>Sistema</Option>
                                    <Option value={2}>Facebook</Option>
                                    <Option value={3}>Instagram</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading} >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            // closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={600}
            className="modal-zeus"
        >
            <FormCliente {...props} />
        </Modal>
    )
}
