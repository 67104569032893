import { Input } from "antd"
import { useState } from "react"

const FormatedInput = (props) => {

    const { value, onChange, multiple, trim, lowerCase, upperCase, ...etc} = props

    const [inputValue, setInputValue] = useState(value)
    
   /**
     *
     * @method handleInput
     * @param {object} e
     * @description Maneja el input
     */
    const handleInput = (e) => {
        let formated = e
        if(multiple) formated = formated.replace(/\s{2,}/, ' ')
        if(lowerCase) formated = formated.toLowerCase()
        if(upperCase) formated = formated.toUpperCase()
        setInputValue(formated)
        onChange(formated)
    }

    const handleInputBlur = () => {
        let formated = inputValue
        if(trim) formated = formated.trim()
        setInputValue(formated)
        onChange(formated)
    }   

    return (
        <Input onChange={(e=>handleInput(e.target.value))} onBlur={(e => handleInputBlur())} value={inputValue} {...etc}/>
    );
}

export default FormatedInput