import React, { Component } from 'react';
import { Drawer, Button, Space, Form, Select, message, Divider, Empty, DatePicker} from 'antd';
import axios from 'axios';

const { Option } = Select;



/**
 *
 *
 * @export
 * @class DrawerNominas
 * @extends {React.Component}
 */
export default class DrawerNominas extends React.Component {


    /**
     *Creates an instance of DrawerNominas.
     * @param {*} propsw
     * @memberof DrawerNominas
     */
    constructor(props) {
        super(props)
        this.state = {
            cliente: null,
            update: false,
            clientes: {
                data: [],
                loading: false,
                total: 0,
                page: 1,
                search: null,
            }
        }
    }
    formRef = React.createRef();


    /**
     *
     *
     * @memberof DrawerNominas
     */
    componentDidMount() {

    }

    /**
     *
     *
     * @memberof DrawerNominas
     * @method onClose
     * @description Cierra el drawer
     */
    onClose = () => {
        this.setState({ cliente: null });
        this.props.onClose()
    }

    /**
     *
     *
     * @param {*} changedValues Valores que cambiarion
     * @param {*} {year, month, date, ...values} Todos los valores del formulario
     * @memberof DrawerNominas
     * @method onFormChange
     * @description Maneja el cambio de valores en el formulario
     */
    onFormChange = (changedValues, {year, month, date, ...values}) => {
        let filters = values

        if(changedValues.year) filters.date = changedValues.year
        if(changedValues.month) filters.date = changedValues.month
        if(changedValues.date) filters.date = changedValues.date

        this.props.filterInfo(filters)
    }

    render() {
        const { visible } = this.props;
        return <Drawer
            title="Filtros"
            placement={"right"}
            height="70vh"
            width="450px"
            onClose={this.onClose}
            visible={visible}
            destroyOnClose={true}
        >
            <Form
                layout='vertical'
                onFinish={() => {}}
                onValuesChange={this.onFormChange}
                ref={this.formRef} 
            >
                <Form.Item
                    label={"Día"}
                    name="date"
                >
                    <DatePicker picker="date" className='width-100' />
                </Form.Item>
                <Form.Item
                    label={"Mes"}
                    name="month"
                >
                    <DatePicker picker="month" className='width-100' />
                </Form.Item>
                <Form.Item
                    label={"Año"}
                    name="year"
                >
                    <DatePicker picker="year" className='width-100' />
                </Form.Item>
                <Form.Item
                    label={"Estatus"}
                    name="estatus"
                >
                    <Select>
                        <Option value={1}>Activa</Option>
                        <Option value={2}>Completa</Option>
                        <Option value={3}>Sin Completar</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Drawer>
    }
}