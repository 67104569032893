import React, { Component, useContext, createRef } from 'react';
import {
    Row,
    Col, Card, Button, Popconfirm, PageHeader, Layout, message, Space, Form, Typography, Avatar,
    Input,
} from 'antd'
import MessagesList from './MessageList';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import '../../../Styles/Modules/Admin/soporte.scss'


import Logged from '../../../Hooks/Logged'
import Socket from '../../../Hooks/Socket'
import { useRefContext } from '../../../Hooks/MainRef'
import usePermissions from '../../../Hooks/usePermissions';

const { Content } = Layout;
const { Text, Title } = Typography;

/**
 * @export
 * @class Roles
 * @extends {Component}
 * @description Vista de Roles
 */
export class Roles extends Component {

    constructor(props) {
        super(props)
        this.state = {

            loading: false,

            conversacion: {
                page: 1,
                limit: 20,
                data: []
            },
            clientes: {
                data: [],
                page: 1,
                limit: 20,
                pages: 0,
                total: 0,
            },
            id: null,
            cliente_nombre: null,
            remitente_id: null,
            filesArray: [],
            filesDictionary: {},



        }
    }

    formReply = createRef()

    getClientes = ({

        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,


    } = this.state.clientes, { clientes } = this.state
    ) => {

        let clearCheckbox = (page === 1)

        this.setState({ clientes: { ...this.state.clientes, page, limit, search }, clearCheckbox, loading: true })
        axios.get('/clientes', {
            params: {
                page: clientes.data.length < 1 && page !== 1 ? 1 : page,
                limit: clientes.data.length < 1 && page !== 1 ? limit * page : limit,
                search,
                soporte: true,

            }
        })
            .then(({ data }) => {

                console.log(data)
                this.setState({
                    clientes: {
                        ...this.state.clientes,
                        data: data.data,
                        total: data.total,
                        search
                    }
                })

            })
            .catch(error => {
                //console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }



    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        if (this.state.id !== null) {
            this.IO_connect(this.state.id)
            this.props.socket.on('connect', this.IO_connect)
        }

        this.getClientes()

        this.props.socket.on('new_message', this.IO_newMessage)

        this.props.socket.on('error', this.IO_error)
        this.props.socket.on('mb_successful', (data) => console.log('conexion exitosa con el id', data))

        this.props.socket.on('sucessful', (data) => this.IO_loadMessages(data))
        this.props.socket.on('refresh_list', (data) => this.getClientes())

    }


    componentWillUnmount() {

        if (this.state.id) {
            this.props.socket.emit('/admin/cliente/leave', this.state.id)
        }
    }

    endConversation = () => {
        console.log("end", this.state.id)
        this.props.socket.emit('/admin/cliente/end', this.state.id)
        this.setState({ id: null, conversacion: { ...this.state.conversacion, data: [] }, cliente_nombre: null, })

    }

    IO_connect = (id) => {
        console.log('conectando a socket', id)
        this.props.socket.emit('/admin/cliente/join', id)

    }

    IO_error = (msg) => {
        console.log("ERROR", msg)
        message.error(msg)
    }

    IO_loadMessages = (res => {

        console.log("res", res)

        this.setState({
            loading: true,
        })

        let old = this.state.conversacion
        let data = (res.page == 1) ? res.data : [...res.data, ...old.data]

        let new_conversacion = {
            data: data,
            page: res.page,
            limit: res.limit,
            total: res.total
        }

        this.setState({
            conversacion: new_conversacion,
            loading: false,
            push: false
        })
    })

    IO_newMessage = (data) => {

        let mensajes = this.state.conversacion.data
        mensajes.push(data)
        this.setState({
            mensajes: {
                ...this.state.conversacion,
                data: mensajes
            }
        })
    }



    submit = (values) => {
        if (!this.state.id) {
            message.error('Hubo un error al enviar la consulta')
        }

        if (values.content && values.content.length > 0) {
            this.formReply.current.resetFields()
            this.props.socket.emit('/admin/cliente/message/add', {
                id: this.state.id,
                entrada: values.content,
                usuario: this.props.user._id,
                cliente_id: this.state.id,
            })
        }


    }

    loadMore = () => {
        let conversacion = this.state.conversacion;
        let actual = conversacion.page;
        let maxima = conversacion.pages;

        if (actual < maxima)
            this.IO_getConversacion({ page: actual + 1 })
    }

    IO_getConversacion = (
        {
            page = this.state.conversacion.page,
            limit = this.state.conversacion.limit,

        } = this.state.conversacion,
    ) => {

        this.setState({ loading: true })
        this.props.socket.emit('/admin/cliente/messages', { page: page, limit: limit, id: this.state.id })

    }

    selectCliente = ({ id, nombre }) => {
        this.setState(function (prevState) {
            if (prevState.id !== id && id !== null) {


                this.props.socket.emit('/admin/cliente/leave', this.state.id)
                this.IO_connect(id)


                return { id, cliente_nombre: nombre }
            } else {
                return
            }


        })
    }

    getConversacion = (id) => {

        this.setState({ loading: true })
        axios.get('/admin/cliente/' + id)
            .then(({ data }) => {
                this.setState({ conversacion: data, id, loading: false })
            })
            .catch(error => {
                //console.log(error)
                message.error('Error al obtener la Información')
            }
            )
    }

    render() {
        return (
            <>
                <PageHeader className="admin-page-header" title="Soporte" />
                <Content className="admin-content content-bg pd-1">
                    <Row
                        className="row-content"
                        gutter={[16, 0,]}
                    >
                        <Col
                            xs={4}
                            md={8}
                            //span={8}
                            className="row-list-cards"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                overflowY: 'scroll',
                            }}
                        >
                            <Input.Search onChange={e => this.getClientes({ search: e.target.value })} />
                            {this.state.clientes?.data?.map(e => <Card
                                // onClick={() => {
                                //     this.selectCliente(e._id)
                                // }}
                                size="small"

                            >
                                <div style={{ borderBottom: '1px solid #E6EDF1 !important', paddingBottom: 4 }}>

                                    <Row>
                                        <Col xs={24} md={4} className='center'>
                                            <Avatar size={32} onClick={() => {
                                                console.log("select cliente", e._id)
                                                this.selectCliente({ id: e._id, nombre: e.nombre + ' ' + e.apellido_paterno })
                                            }}>
                                                {e.nombre.slice(0, 1) + e.apellido_paterno.slice(0, 1)}
                                            </Avatar>
                                        </Col>
                                        <Col xs={0} md={20}>
                                            <Space direction="vertical" size={2}>
                                                <button className='link-button' onClick={() => {
                                                    console.log("select cliente", e._id)
                                                    this.selectCliente({ id: e._id, nombre: e.nombre + ' ' + e.apellido_paterno })
                                                }} ><Title type='link' level={5} style={{ margin: 0 }}>{e.nombre} {e.apellido_paterno} {e.apellido_materno}</Title></button>

                                                <Text ellipsis>{e.email}</Text>
                                            </Space>
                                        </Col>


                                    </Row>


                                </div>
                            </Card>)}

                        </Col>
                        <Col
                            md={16}
                            xs={20}
                            //span={16}
                            flex={1}
                            className="row-messenger"
                        >
                            <Card className="conversation" >
                                <Row className="titulo">

                                    CHAT SOPORTE: {this.state.cliente_nombre}
                                    {
                                        this.state.id ? <Col span={24}>
                                            <Popconfirm title={<>¿Estas seguro de terminar esta conversación?<br />Al terminar la conversación no podra ser recuperada a menos que el cliente se vuelva a contactar</>} okText="Si" cancelText="No" onConfirm={() => this.endConversation()}>
                                                <Button type='danger' style={{ float: 'right' }} >Terminar Conversacion</Button>
                                            </Popconfirm>

                                        </Col> : null
                                    }

                                </Row>


                                <div className="chat-container width-100">

                                    <div className="message-box">
                                        <MessagesList
                                            data={this.state.conversacion.data}
                                            loadMore={this.loadMore}
                                        />
                                    </div>
                                    <div className="bottom-content">
                                        <Form
                                            layout="inline"
                                            className="width-100 pd-1 "
                                            onFinish={this.submit}
                                            ref={this.formReply}>


                                            <Col xs={19} md={19} >

                                                <Form.Item name='content'>
                                                    <Input placeholder="Escribe tu mensaje aqui..." className="width-100" style={{ marginTop: 6 }} />
                                                </Form.Item>


                                            </Col>
                                            <Col xs={5} md={5}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" style={{ marginTop: '5px' }}>
                                                        Enviar
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Form>
                                    </div>

                                </div>


                            </Card>
                            {/*

                            {/* <Card style={{ height: '100%'}}></Card> */}

                        </Col>
                    </Row>
                </Content>
            </>
        )
    }
}

export default function (props) {

    let ref = useRefContext()
    let user = React.useContext(Logged)
    let socket = useContext(Socket)

    let permissions = usePermissions(user?.rol_id?.permisos, {
        editRoles: ["roles", "edit"],
        createRoles: ["roles", "create"],
        deleteRoles: ["roles", "delete"]
    })

    // console.log("Contexto Logged", user)
    console.log("ref X", ref)

    return <Roles {...props} mainRef={ref} navigate={useNavigate()} {...permissions} socket={socket} user={user} />

}