import React from 'react'
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";


// componentes
import Usuarios from '../../Components/Admin/Usuarios/Usuarios'

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterUsuarios(props) {
    
    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Usuarios  {...props}  />} />
        </Routes>
    )
}

export default RouterUsuarios;