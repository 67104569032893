import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Card, message, Spin} from 'antd';
import axios from 'axios';
import { IconPDFColor, IconAgabeColor, IconAgabe } from '../../Widgets/Iconos';
import { LightenDarkenColor } from 'lighten-darken-color';

import '../../../Styles/Global/modales.css'
import PagoTarjeta from '../Inversion/PagoTarjeta';
import { User } from '../../../Hooks/Logged'

const { Title, Text } = Typography;
const moment = require("moment")


/**
 *
 *
 * @class ModalEstadoCuenta
 * @extends {Component}
 */
class ModalEstadoCuenta extends Component {

    static contextType = User

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            inversion:{
                hacienda_id:{
                    color: 'white'
                }
            }      
        }
    }
    
    componentDidMount( ){
        if(this.props?.ficha?.inversion?._id){
            this.getInversion()
        }
    }

    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getInversion
     * @description Obtiene informacion del Cliente
     */
    getInversion = () => {
        this.setState({loading: true})
        axios.get('/inversion',{
            params: {
                id: this.props.ficha?.inversion?._id,
                
            }
        }).then(response => {
            let inversion = response.data.data
            console.log("inversion", inversion);

            this.setState({inversion})
            
        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información de la inversion")
        }).finally(()=>this.setState({loading: false}))
    }



    render() {

        let { inversion } = this.state;

        return (
            <Form
                layout="vertical"
            >
                <Spin spinning={this.state.loading}>
                    <Card className="card-hacienda-modal" style={{ 
                        background: `linear-gradient(204.67deg, ${LightenDarkenColor(inversion?.hacienda_id?.color, 50)} 9.43%, ${inversion?.hacienda_id?.color} 84.49%)` }} 
                    >
                        <Row>
                            <Col span={19}>
                                <Title>{inversion?.hacienda_id?.nombre}</Title>
                            </Col>
                            <Col span={5} className="center">
                                <IconAgabe />
                            </Col>
                        </Row>
                    </Card>
                    <Row className="mb-3">
                        <Col span={12}>
                            <Text style={{fontSize: '16px'}} strong className="text-cyan">Folio:</Text>
                        </Col>
                        <Col span={12} className="flex-right">
                            <Text style={{fontSize: '16px'}} strong>{inversion.folio}</Text>
                        </Col>
                        <Col span={12}>
                            <Text style={{fontSize: '16px'}} strong className="text-cyan">Cantidad de Plantas:</Text>
                        </Col>
                        <Col span={12} className="flex-right">
                            <Text style={{fontSize: '16px'}} strong>{inversion.cantidad}</Text>
                        </Col>
                         <Col span={12}>
                            <Text style={{fontSize: '16px'}} strong className="text-cyan">Precio por Planta:</Text>
                        </Col>
                        <Col span={12} className="flex-right">
                            <Text style={{fontSize: '16px'}} strong>$ {inversion.precio_planta} {inversion.moneda}</Text>
                        </Col>
                        <Col span={12}>
                            <Text style={{fontSize: '20px'}} strong className="text-cyan">Total a Invertir:</Text>
                        </Col>
                        <Col span={12} className="flex-right">
                            <Text style={{fontSize: '20px'}} strong>$ {inversion.monto?.toMoney(true)} {inversion.moneda}</Text>
                        </Col>
                    </Row>
                </Spin>         
            </Form>
        )
    }
}

export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose={true}
        zIndex={1000}
        className="modal-zeus"
    >
        <div className="center">
            <img src="/img/zeus_logo.svg" alt="logo Zeus" width="120"/>
        </div>
        <Title className="mb-1 mt-1 text-center" style={{fontSize: "30px"}}>Pago de Inversión</Title>
        <ModalEstadoCuenta {...props}/>
        <PagoTarjeta ficha={props.ficha} onClose={()=>props.onClose()}/>
    </Modal>

}