import React, { Component, useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Form, message, Input, Select, InputNumber } from 'antd';
import { User, SetUser } from '../../../Hooks/Logged'

import { useTranslation } from 'react-i18next';
import { SelectPais, SelectEstado } from '../../Widgets/Inputs/Inputs'
const { Title, Text } = Typography;
const axios = require('axios')
const { Option } = Select



/**
 * @class Direccion
 * @description 
 */
const Direccion = React.forwardRef((props, ref) => {

    const { t, i18n } = useTranslation();

    let [form] = Form.useForm();
    let [paises, setPaises] = useState([])
    let [pais, setPais] = useState(null)
    let [estados, setEstados] = useState([])
    const setUser = useContext(SetUser)
    let user = useContext(User)

    useEffect(() => {
        console.log("DATA", form, user)
        getPaises()
        form.setFieldsValue({...user})
    }, []);
    /**
  * @memberOf FormCliente
  * @method getPaises
  * @description obtiene una lista de los paises
  */
    const getPaises = () => {
        axios.get('/paises',{
            params: {
                locale: i18n.language
            }
        })
            .then(response => {
                setPaises(response.data.data)
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los paises.')
            })
    }

    /**
    * @memberOf FormCliente
    * @method getEstados
    * @description obtiene una lista de los estados
    */
    const getEstados = (pais_id = pais_id) => {
        console.log("p", pais)
        axios.get('/estados', {
            params: { pais_id }
        })
            .then(response => {
                setEstados(response.data.data)
            })
            .catch(error => {
                message.error('Ha ocurrido un error al obtener los estados.')
            })
    }
    const submit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        axios.put('/usuarios/update-registro', {
            status: 1,
            id: user._id,
            ...values
        }).then(({data, headers}) => {  
            setUser(data.user);
            props.next()
        })
    }


    React.useImperativeHandle(ref, () => ({ submit }));

    return (
        <>
            <Form
                layout={"vertical"}
                form={form}
                onFinish={onFinish}
                onFinishFailed={(values) => message.error('falta')}
                style={{ minHeight: '480px' }}
                initialValues={{ ...props.data, ...user }}
            >
                <Row justify="center">
                    <Col span={24} className="flex-column mb-3 mt-3">
                        <Title level={3} className="modal-title">Dirección</Title>
                        <Text className="modal-subtitle">Toda la información de este formulario, solo se te pedirá 1 vez para la creación de tu cuenta.</Text>
                    </Col>
                    <Col span={18}>

                        <Form.Item
                            label="Calles"
                            name="calles"
                            rules={[
                                { required: true, message: "Ingrese las calles" }
                            ]}>
                            <Input placeholder="Calles" />
                        </Form.Item>
                        <Form.Item
                            label="Número"
                            name="numero"
                            rules={[
                                { required: true, message: "Ingrese el número" }
                            ]}>
                            <Input placeholder="Numero" />
                        </Form.Item>
                        <Form.Item
                            label="Código Postal"
                            name="codigo_postal"
                            rules={[
                                { required: true, message: "Ingrese el código postal" }
                            ]}>
                            <Input placeholder="C.P." />
                        </Form.Item>
                        <Form.Item
                            label="Ciudad"
                            name="ciudad"
                            rules={[
                                { required: true, message: "Ingrese la ciudad" }
                            ]}>
                            <Input className="w-100" placeholder="Ciudad" />
                        </Form.Item>
                        <Form.Item
                            label="País"
                            name="pais_id"
                            rules={[

                                { required: true, message: "Seleccione el pais" }
                            ]}>
                                
                            <SelectPais
                                onSelect={(pais_id)=>setPais(pais_id)}
                            />                            
                        </Form.Item>
                        
                        <Form.Item
                            label="Estado"
                            name="estado_id"
                            rules={[
                                { required: true, message: "Seleccione el estado" }
                            ]}>
                            <SelectEstado pais_id={pais}/>
                        </Form.Item>

                    </Col>
                </Row>
            </Form>
        </>
    )

})

export default Direccion

