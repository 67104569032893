import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { FaSuitcase, FaUsers, FaHandHoldingUsd } from "react-icons/fa";
import "../../Styles/Global/sidebar.css";

import { User } from '../../Hooks/Logged';

import { AppstoreFilled, MonitorOutlined, SwapOutlined, WalletOutlined, ProfileOutlined, PartitionOutlined, FontSizeOutlined, LineChartOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { IconUsuarios, IconHaciendas, IconPOS, IconProductos, IconCategorias } from '../Widgets/Iconos'
import usePermissions from "../../Hooks/usePermissions";
import { PiUsersFourLight } from "react-icons/pi";
import { TbSettingsAutomation } from "react-icons/tb";
import { LuBookTemplate } from "react-icons/lu";




const { Sider } = Layout;
const { SubMenu } = Menu;

/**
 * @export
 * @class Sidebar
 * @extends {Component}
 */
class Sidebar extends Component {

    state = {
        selectedProject: null,
        loading: true,
        proyectos: {
            data: [],
            page: 1,
            limit: 10,
            total: 0,
        },
    }



    render() {

        const { user } = this.props
        const { roles } = user || {}

        // user = {tipo: 1}

        return (
            <Sider
                className="sider-admin"
                theme="light"
                width={250}
                breakpoint="lg"
                collapsedWidth="0"
            >
                <Menu className="hm-menu" defaultSelectedKeys={['0']} mode="inline" >

                    {
                        this.props.agave ? (
                            <Menu.ItemGroup
                                title="ZEUS AGAVE"
                            >

                                {this.props.verAgaveDashboard && <Menu.Item key="0" icon={<AppstoreFilled />}>
                                    <Link to={"/admin/dashboard"}>
                                        Dashboard
                                    </Link>
                                </Menu.Item>}

                                {this.props.verHaciendas && <Menu.Item key="2" icon={<IconHaciendas color="currentColor" />}>
                                    <Link to="/admin/haciendas">
                                        Haciendas
                                    </Link>
                                </Menu.Item>}

                                {this.props.verTransacciones && <Menu.Item key="3" icon={<SwapOutlined />}>
                                    <Link to="/admin/transacciones">
                                        Transacciones
                                    </Link>
                                </Menu.Item>}
                                {this.props.verTransacciones && <Menu.Item key="authorize" icon={<SwapOutlined />}>
                                    <Link to="/admin/authorize">
                                        Authorize
                                    </Link>
                                </Menu.Item>}
                                {(this.props.verCRM || this.props.verClientes) && <SubMenu key="crm" title={this.props.verCRM ?<Link to={'/admin/crm'}>CRM</Link> : "CRM"} icon={<AppstoreFilled />}>
                                    <Menu.Item key="crm-12clientes" icon={<FaUsers />} >
                                        <Link to={"/admin/crm/clientes/listado"}>
                                            Clientes
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="crm-clientes-reventas" icon={<FaUsers />} >
                                        <Link to={"/admin/crm/clientes/reventas"}>
                                            Reventas
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item key="crm-clientes" icon={<FaUsers />} >
                                        <Link to={"/admin/crm/clientes"}>
                                            Estatus
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>}
                                {
                                    this.props.verInversiones ? (
                                        <Menu.Item key="6" icon={<MonitorOutlined />}>
                                            <Link to="/admin/inversiones">
                                                Inversiones
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {
                                    this.props.verReventas ? (
                                        <Menu.Item key="9" icon={<MonitorOutlined />}>
                                            <Link to="/admin/reventas">
                                                Reventas
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {
                                    this.props.verVentas ? (
                                        <Menu.Item key="4" icon={<WalletOutlined />}>
                                            <Link to="/admin/ventas">
                                                Ventas
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {this.props.verCotizador ? (
                                    <Menu.Item key="5" icon={<LineChartOutlined />}>
                                        <Link to={"/admin/cotizador"}>
                                            Cotizador
                                        </Link>
                                    </Menu.Item>)
                                    : null}
                                {this.props.verSoporte ? (
                                    <Menu.Item
                                        key="/admin/soporte"
                                        icon={<QuestionCircleOutlined />}
                                    >
                                        <Link to={"/admin/soporte"}>
                                            Soporte
                                        </Link>
                                    </Menu.Item>)
                                    : null}
                            </Menu.ItemGroup>



                        ) : null
                    }


                    {
                        this.props.pos ? (
                            <Menu.ItemGroup
                                title="POS"
                            >
                                {
                                    // roles?.pos_dashboard || user.tipo === 1 ? (
                                    this.props.verPOSDashboard ? (
                                        <Menu.Item key="41" icon={<AppstoreFilled />}>
                                            <Link to={"/admin/dashboard/pos"}>
                                                Dashboard
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {
                                    // roles?.pos || user.tipo === 1 ? (
                                    this.props.verPOS ? (
                                        <Menu.Item key="pos" icon={<IconPOS />}>
                                            <Link to={"/admin/pos"}>
                                                Punto de Venta
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {
                                    this.props.verProductos ? (
                                        // roles?.pos_productos || user.tipo === 1 ? (
                                        <SubMenu key="productos" title={<Link to={'/admin/productos'}>Productos</Link>} icon={<IconProductos />}>
                                            {this.props.verProductosReportes ? (
                                                <Menu.Item key="productos-reportes" icon={<ProfileOutlined />} >
                                                    <Link to={"/admin/productos/reportes"}>
                                                        Reportes
                                                    </Link>
                                                </Menu.Item>
                                            ) : null}
                                            {this.props.verProductosMovimientos ? (
                                                <Menu.Item key="productos-movimientos" icon={<PartitionOutlined />} >
                                                    <Link to={"/admin/productos/movimientos"}>
                                                        Movimientos
                                                    </Link>
                                                </Menu.Item>
                                            ) : null}
                                        </SubMenu>
                                    ) : null
                                }
                                {
                                    this.props.verTamanos ? (
                                        <Menu.Item key="7" icon={<FontSizeOutlined />}>
                                            <Link to={"/admin/tamanos"}>
                                                Tamaños
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {
                                    this.props.verCategorias ? (
                                        <Menu.Item key="8" icon={<IconCategorias />}>
                                            <Link to={"/admin/categorias"}>
                                                Categorías
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                            </Menu.ItemGroup>
                        ) : null
                    }

                    {
                        this.props.erp && (
                            <Menu.ItemGroup title="ERP"  >
                                {
                                    this.props.verUsuarios ? (
                                        <Menu.Item key="1" icon={<IconUsuarios />}>
                                            <Link to={"/admin/usuarios"}>
                                                Usuarios
                                            </Link>
                                        </Menu.Item>
                                    ) : null
                                }
                                {
                                    this.props.verRoles && (
                                        <Menu.Item key="roles" icon={<PiUsersFourLight />}>
                                            <Link to="/admin/roles">
                                                Roles
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                                {
                                    this.props.verAutomatizaciones && (
                                        <Menu.Item key="automatizaciones" icon={<TbSettingsAutomation />}>
                                            <Link to="/admin/automatizaciones">
                                            Automatizaciones
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                                {
                                    this.props.verTemplates && (
                                        <Menu.Item key="templates" icon={<LuBookTemplate />}>
                                            <Link to="/admin/templates">
                                            Templates
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                            </Menu.ItemGroup>
                        )
                    }

                    {
                        this.props.nominas && (
                            <Menu.ItemGroup title="Nomina"  >
                                {
                                    this.props.verNominas && (
                                        <Menu.Item key="admin-nominas" icon={<FaHandHoldingUsd />}>
                                            <Link to={"/admin/nominas"}>
                                                Nóminas
                                            </Link>
                                        </Menu.Item>
                                    )
                                }

                                {
                                    this.props.verEmpleados && (
                                        <Menu.Item key="empleados" icon={<FaSuitcase />}>
                                            <Link to="/admin/empleados">
                                                Empleados
                                            </Link>
                                        </Menu.Item>
                                    )
                                }
                            </Menu.ItemGroup>
                        )
                    }
                </Menu>
            </Sider>
        )
    }
}

export default function View(props) {

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        verAgaveDashboard: ["dashboard"],
        verHaciendas: ["haciendas", "access"],
        verTransacciones: ["transacciones", "access"],
        verCRM: ['crm'],
        verClientes: ['clientes', 'access'],
        verEstatus: ["estatus", "access"],
        verInversiones: ["inversiones", "access"],
        verVentas: ["ventas", "access"],
        verCotizador: ["cotizador"],
        verPOSDashboard: ["pos"],
        verPOS: ["pos"],
        verProductos: ["productos", "access"],
        verProductosReportes: ["productos", "productos-reportes", "access"],
        verProductosMovimientos: ["productos", "productos-movimientos", "access"],
        verTamanos: ["tamanos", "access"],
        verCategorias: ["categorias", "access"],
        verUsuarios: ["usuarios", "access"],
        verRoles: ["roles", "access"],
        verNominas: ["nominas", "access"],
        verEmpleados: ["empleados", "access"],
        verReventas: ["reventas", "access"],
        verSoporte: ["soporte", "access"],
        verAutomatizaciones: ["automatizaciones", "access"],
        verTemplates:["templates","access"]
    })

    permisos.agave = [
        permisos.verAgaveDashboard,
        permisos.verHaciendas,
        permisos.verTransacciones,
        permisos.verCRM,
        permisos.verClientes,
        permisos.verEstatus,
        permisos.verInversiones,
        permisos.verVentas,
        permisos.verCotizador
    ].find(p => !!p)

    permisos.pos = [
        permisos.verPOS,
        permisos.verPOSDashboard,
        permisos.verProductos,
        permisos.verTamanos,
        permisos.verCategorias
    ].find(p => !!p)

    permisos.erp = [
        permisos.verUsuarios,
        permisos.verRoles,
        permisos.verAutomatizaciones,
        permisos.verTemplates
    ].find(p => !!p)

    permisos.nominas = [
        permisos.verNominas,
        permisos.verEmpleados
    ].find(p => !!p)

    return <Sidebar {...props} user={user} {...permisos} />

};