import React, { useState, useEffect, useRef, } from 'react';
import { Input } from 'antd'


import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import './Mapa.scss';


const { isEqual } = require('lodash');

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;



/**
 *
 *
 * @export
 * @class Mapa
 * @extends {React.Component}
 */
export default class Mapa extends React.Component {


    static defaultProps = {
        onChange: () => { }
    }

    constructor(props) {
        super(props)

        this.state = {
            longitude: -99.133,
            latitude: 19.4373,

            zoom: 13,

            polygon: {},
            draw: {},

            previousValue: null
        }
    }

    map = React.createRef()
    mapContainer = React.createRef()

    componentDidMount() {

        const { latitude, longitude, zoom } = this.state

        this.map.current = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/av-isy/cl1wxf6tw000a14n3rf5wpit8', // style URL
            center: [longitude, latitude],
            zoom // starting zoom
        });
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true,
            },
            // defaultMode: 'draw_polygon'
        });

        this.map.current.addControl(new mapboxgl.FullscreenControl());
        this.map.current.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true
            })
        );
        this.map.current.addControl(draw);

        this.setState({ draw })
        this.map.current.on('draw.create', this.onChangePolygon);
        this.map.current.on('draw.delete', () => {
            // console.log("Fdelete", )
            draw.trash()
        });

        this.map.current.on('draw.modechange', (e) => {
            console.log("draw.modechange",draw)
            const data = draw.getAll();
            if (draw.getMode() == 'draw_polygon') {
                var pids = []
                const lid = data.features[data.features.length - 1].id
                data.features.forEach((f) => {
                    if (f.geometry.type === 'Polygon' && f.id !== lid) {
                        pids.push(f.id)
                    }
                })
                draw.delete(pids)
            }
        });

        this.map.current.on('draw.update', (e) => {
            this.onChangePolygon()
        });

        window.draw = draw

        this.map.current.on('zoomend', () => {
            this.setCurrentDragValues()
        });
        this.map.current.on('dragend', () => {

            this.setCurrentDragValues()
        });
    }


    componentDidUpdate() {
        const { value } = this.props
        const { previousValue } = this.state

        console.log("value", value)

        if (
            typeof value == "object" && value.polygon &&
            !isEqual(value, previousValue)
        ) {
            this.setState({ previousValue: value }, () => this.triggerChange(value, () => {
                this.map.current.setCenter([value.longitude, value.latitude]);
                this.map.current.setZoom(value.zoom);
                if (value.polygon?.type)
                    this.state.draw.set(value.polygon)
            }))
        }
    }

    componentWillUnmount () {
        // this?.map.remove();

    }


    /**
     *
     *
     * @param {object} values Valores del mapa
     * @param {function} callback Funcion callback
     * @memberof Mapa
     * @method triggerChange
     * @description Actualiza los valores del mapa
     */
    triggerChange = (values, callback) => {
        const { longitude, latitude, polygon, zoom } = this.state
        values = { latitude, longitude, polygon, zoom, ...values }


        this.props.onChange(values)
        this.setState(values, callback)
    }


    /**
     *
     *
     * @memberof Mapa
     * @method getCurrentDragValues
     * @description Obtiene los valores de los punteros
     */
    getCurrentDragValues = () => {
        const { lng, lat } = this.map.current.getCenter();

        let dragValues = {
            longitude: lng,
            latitude: lat,
            zoom: this.map.current.getZoom()
        }
        return dragValues
    }

    /**
     *
     *
     * @memberof Mapa
     * @method setCurrentDragValues
     * @description Establece los valores de los punteros
     */
    setCurrentDragValues = () => {
        const dragValues = this.getCurrentDragValues();
        this.triggerChange({ ...dragValues, polygon: this.state.polygon })
    }

    /**
     *
     *
     * @memberof Mapa
     * @method onChangePolygon
     * @description Maneja los cambios del poligono
     */
    onChangePolygon = () => {
        let polygon = this.state.draw.getAll()

        if (polygon.features.length > 0) {
            const dragValues = this.getCurrentDragValues()
            this.triggerChange({ ...dragValues, polygon })
        }
    }

    render() {

        return <div className="mapbox-container">
            <div className="mapbox-element" ref={this.mapContainer} />
        </div>

    }

}