import React, { useState, useEffect } from 'react';
import { Input } from 'antd'


const PhoneInput = (props) => {

    const { value, onChange, icon = undefined } = props

    const [inputValue, setInputValue] = useState(value)
    const [valid, setValid] = useState(false)

    /**
     *
     * @method handleInput
     * @param {object} e
     * @description Maneja el input
     */
    const handleInput = (e) => {
        const res = validateEmail(e.target.value)
        setInputValue(e.target.value)
        setValid(res)
    }

    const onBlur = () => {
    	if(valid){
    		onChange(inputValue)
    	}
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])

    return (
        <Input {...props} onChange={(e=>handleInput(e))} value={inputValue} onBlur={()=>onBlur()} />
    );
}

function validateEmail(value){
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)){
    	return true
  	}else{
  		return false
  	}
    
}


export default PhoneInput;