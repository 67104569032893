import React, { Component } from 'react';
import { Row, Col, Typography, Button, PageHeader, Layout, message, Card, Space, Tabs, Popconfirm, Breadcrumb } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

//componentes
import Compras from './Listas/Compras';
import Inversiones from './Listas/Inversiones';
import Transacciones from './Listas/Transacciones';
import EstadosCuenta from './Listas/EstadosCuenta';
import Beneficiarios from './Listas/Beneficiarios';
import Reventas from './Listas/Reventas';
import { IconArrowBack, IconFlag, IconBtnDelete, IconPDF, IconMail, IconBtnEdit} from '../../Widgets/Iconos';
import ModalDetalle from './ModalClienteDetalle'

//css
import '../../../Styles/Modules/Admin/CRM.scss'
import usePermissions from "../../../Hooks/usePermissions";
// import Reventas from './Listas/Reventas';

const { Content } = Layout;
const { Title, Text } = Typography
const { TabPane } = Tabs
const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class ClientesDetalles
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un cliente
 */
class ClientesDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            cliente_id: this.props.params.cliente_id,
            cliente: {
                
            },
            cuentas: [],
            modal_visible: false,
            cantidad_plantas: 0,
            monto_vendido: 0,
            monto_pendiente: 0,
            monto_pagado: 0,
            plantas_vendidas: 0,
            loading_contrato: false,
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if(this.props.params.cliente_id){
            this.getCliente()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getCliente
     * @description Obtiene informacion del Cliente
     */
    getCliente = () => {
        this.setState({loading: true})
        axios.get('/cliente',{
            params: {
                cliente_id: this.state.cliente_id,
                pais_id: true,
                estado_id: true,
                cuentas: true,
            }
        }).then(response => {
            this.setState({
                cliente: response.data.data,
                cuentas: response.data.cuentas,
                cantidad_plantas: response.data.plantas,
                monto_total: response.data.monto_total,
                monto_total_dolar: response.data.monto_total_dolar,
                monto_pesos: response.data.monto_pesos,
                monto_dolar: response.data.monto_dolar,
                monto_pendiente_pesos: response.data.monto_pendiente_pesos,
                monto_pendiente_dolar: response.data.monto_pendient_dolar,
                plantas_vendidas: response.data.plantas_vendidas,
                monto_pagado_pesos: response.data.monto_pagado_pesos,
                monto_pagado_dolar: response.data.monto_pagado_dolar,

            })

        }).catch(error => {
            console.log("error", error);
            message.error("Error al obtener la información del cliente")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @param num Fuente
     * @description Renderiza la fuenta del cliente
     */
    renderFuente = (fuente) => {

        let steps = {
            1: "Sistema",
            2: "Facebook",
            3: "Instagram",
        }

        return fuente !== undefined ? steps[fuente] : 'N/A'
    }

    /**
     *
     * @memberof ClientesDetalles
     * 
     * @method getPDF
     * @description Generamos el PDF del estado de cuenta del cliente
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/crm/estado-cuenta');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('cliente_id', this.state.cliente_id)
        return urlPDF.href
    }
    

     /**
     *
     *
     * @memberof MatrizCostos
     *
     * @method sendPDF
     * @description Enviamos la cotizacion al cliente
     */
    sendPDF = () => {
        this.setState({ loading_mail: true });
        return axios
            .get("/crm/estado-cuenta", {
                params: {
                    cliente_id: this.state.cliente_id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Recibo Enviado");
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status === 403)
                    message.error(error.response.data.message)
                else
                    message.error("Error al enviar el recibo");
            })
            .finally(() => {
                this.setState({ loading_mail: false });
            });
    };

     /**
     * @memberof DetalleCliente
     *
     * @method renderMontoVendido
     * @description renderiza los montos 
     */
    renderMontoVendido = () => {
        const { monto_total, monto_total_dolar, monto_pesos, monto_dolar } = this.state;

        let amountInPesos, amountInDollars,size = 17;
        if (monto_pesos > 0) {
            amountInPesos = `$ ${monto_pesos.toMoney(true)} MXN`;
        }
        if (monto_dolar > 0) {
            amountInDollars = `$ ${monto_dolar.toMoney(true)} USD`;
        }

        if(monto_dolar > 0 && monto_pesos > 0){
            amountInPesos = `$ ${monto_total.toMoney(true)} MXN`;
            amountInDollars = `$ ${monto_total_dolar.toMoney(true)} USD`;
            size = 14
        }

        if(!amountInPesos && !amountInDollars) return <Text ellipsis className="text-info"> $ 0 MXN </Text>;

        return  <Text ellipsis className="text-info" style={{fontSize: size}}> {amountInPesos} {amountInPesos && amountInDollars ? " / " : ""} {amountInDollars}</Text>;
    }


    /**
     * @memberof DetalleCliente
     *
     * @method renderMontos
     * @description renderiza los montos 
     */
    renderMontos = ({monto_pesos, monto_dolar, color = "info"}) => {

        let amountInPesos, amountInDollars, size = 17;
        if (monto_pesos > 0) {
            amountInPesos = `$ ${monto_pesos.toMoney(true)} MXN`;
        }
        if (monto_dolar > 0) {
            amountInDollars = `$ ${monto_dolar.toMoney(true)} USD`;
        }

        if(monto_pesos > 0 && monto_dolar > 0){
            size = 14
        }

        if(!amountInPesos && !amountInDollars) return <Text ellipsis className={`text-${color}`}> $ 0 MXN </Text>;

        return  <Text ellipsis className={`text-${color}`} style={{fontSize: size}}> {amountInPesos} {amountInPesos && amountInDollars ? " / " : ""} {amountInDollars}</Text>;

    }

    render() {

        const { cliente, cuentas } = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<div className="flex-left">
                        <Button ghost onClick={this.back} style={{ border: "none" }}>
                            <IconArrowBack />
                        </Button>
                        <Breadcrumb >
                            <Breadcrumb.Item>Detalle Cliente</Breadcrumb.Item>
                            <Breadcrumb.Item>{ cliente.nombre } { cliente.apellido_paterno } { cliente.apellido_materno }</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>}
                />
                <Content className="pd-1">
                    <Card className="card-cliente" loading={this.state.loading}>
                        <Row gutter={[8]}>
                            <Col xs={16} xl={8}>
                                <Title className="text-title" level={3}>Información del Cliente</Title>
                            </Col>
                            <Col xs={8} xl={8} className="flex-left">
                                <IconFlag/><Text className="text-date ml-1">Cliente desde {moment(cliente.createdAt).format("DD-MM-YYYY")}</Text>
                            </Col>
                            <Col xs={24} xl={8} className="flex-right">
                                <Space>
                                    <Button
                                        title="Enviar estado de cuenta"
                                        type='primary' 
                                        className='btn-primary center' 
                                        icon={<IconMail />} 
                                        onClick={()=>this.sendPDF()}
                                        loading={this.state.loading_mail}
                                    />
                                    <a href={this.getPDF()} target="_blank">
                                        <Button
                                            title="Ver estado de cuenta"
                                            type='primary' 
                                            className='btn-primary center' 
                                            icon={<IconPDF />} 
                                        />
                                    </a>
                                    <Button
                                        title="Editar cliente"
                                        type='primary' 
                                        className='btn-primary center' 
                                        icon={<IconBtnEdit />} 
                                        disabled={!this.props.editarCliente}
                                        onClick={()=>this.setState({modal_visible: true})}
                                        />
                                    <Popconfirm
                                        placement="topRight"
                                        title="¿Deseas eliminar este cliente?"
                                        onConfirm={() => axios.delete('/clientes/delete', { params: { cliente_id: cliente._id } })
                                            .then((response) => {
                                                message.success(response?.data?.message)
                                                this.back()
                                            })
                                            .catch((error) => {
                                                message.error(error?.response?.data?.message);
                                            })
                                        }
                                        okText="Sí"
                                        cancelText="No"
                                        disabled={!this.props.deleteCliente}
                                    >
                                        <Button type="primary" className='btn-delete center' danger title="Eliminar" icon={<IconBtnDelete />} />
                          
                                    </Popconfirm>
                                </Space>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Nombre Completo</Text>
                                <Text ellipsis className="text-info">{cliente.nombre} {cliente.apellido_paterno} {cliente.apellido_materno ?? ''}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Fecha de nacimiento</Text>
                                <Text ellipsis className="text-info">{moment(cliente.fecha_nacimiento).format("DD-MM-YYYY")}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Email</Text>
                                <Text ellipsis className="text-info">{cliente.email}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Teléfono</Text>
                                <Text ellipsis className="text-info">{cliente.telefono}</Text>
                            </Col>
                            <Col xs={24} xl={12} className="flex-left-column" style={{overflow: 'hidden' }}>
                                <Text className="text-label">Cuentas de Beneficiario </Text>
                                <Space>
                                    {cuentas?.map((cuenta, index) => {
                                        if(index < 3)  return <Text ellipsis className="text-cuenta">{cuenta.nombre}</Text>
                                        else if(index === 3) return <Text ellipsis className="text-cuenta">...</Text>
                                        return null
                                    })}
                                </Space>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Vendedor</Text>
                                <Text ellipsis className="text-info">{cliente.vendedor_id?.nombres} {cliente.vendedor_id?.apellido_paterno}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={6} className="flex-left-column">
                                <Text className="text-label">Fuente</Text>
                                <Text ellipsis className="text-info">{this.renderFuente(cliente.fuente)}</Text>
                            </Col>
                            <Col xs={20} className="flex-left-column">
                                <Text className="text-label">Dirección</Text>
                                <Text ellipsis className="text-info">
                                    {cliente.calles} {cliente.numero} {cliente.colonia} {cliente.ciudad} {cliente.estado_id?.nombre} {cliente.pais_id?.nombre} {cliente.codigo_postal}
                                </Text>
                            </Col>
                            <Col xs={4} className="flex-right">
                                <Space>
                                    
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="card-financiero">
                        <Row gutter={[8]}>
                            <Col xs={12} md={8} xl={5} className="flex-column">
                                <Text className="text-label">Plantas Compradas</Text>
                                <Text ellipsis className="text-info">{this.state.plantas_vendidas.toMoney(true)} </Text>
                            </Col>
                            <Col xs={12} md={8} xl={5} className="flex-column">
                                <Text className="text-label">Monto Vendido</Text>
                                  
                                    {this.renderMontoVendido()}
                                
                            </Col>
                            <Col xs={12} md={8} xl={5} className="flex-column">
                                <Text className="text-label">Monto Pagado</Text>
                                {this.renderMontos({
                                    monto_pesos: this.state.monto_pagado_pesos,
                                    monto_dolar: this.state.monto_pagado_dolar,
                                    color: "green"
                                })}
                            </Col>
                            <Col flex="auto" className="flex-column">
                                <Text className="text-label">Cantidad Plantas</Text>
                                <Text ellipsis className="text-info">{this.state.cantidad_plantas?.toMoney(true)}</Text>
                            </Col>
                            <Col xs={24} md={12} xl={5} className="flex-column">
                                <Text className="text-label">Monto Pendiente</Text>
                                 {this.renderMontos({
                                    monto_pesos: this.state.monto_pendiente_pesos,
                                    monto_dolar: this.state.monto_pendiente_dolar,
                                    color: "red"
                                })}
                            </Col>
                        </Row>
                    </Card>
                    <Tabs 
                        defaultActiveKey="1" 
                        destroyInactiveTabPane={true}
                    >
                        <TabPane tab="Inversiones" key="1">
                            <Inversiones 
                                cliente_id={this.state.cliente_id} 
                                cliente={this.state.cliente}
                                getCliente={()=>this.getCliente()}
                            />
                        </TabPane>
                        <TabPane tab="Transacciones" key="2">
                            <Transacciones 
                                cliente_id={this.state.cliente_id} 
                                cliente={this.state.cliente}
                                getCliente={()=>this.getCliente()}
                            />
                        </TabPane>
                        <TabPane tab="Reventas" key="6">
                            <Reventas 
                                cliente_id={this.state.cliente_id} 
                                cliente={this.state.cliente}
                                getCliente={()=>this.getCliente()}
                            />
                        </TabPane>
                        <TabPane tab="Compras Realizadas" key="3">
                            <Compras cliente_id={this.state.cliente_id}/>
                        </TabPane>
                        <TabPane tab="Estados de Cuenta" key="4">
                            <EstadosCuenta cliente_id={this.state.cliente_id}/>
                        </TabPane>
                        <TabPane tab="Beneficiarios" key="5">
                            <Beneficiarios cliente_id={this.state.cliente_id}/>
                        </TabPane>
                    </Tabs>
                </Content>
                <ModalDetalle
                    visible={this.state.modal_visible}
                    cliente_id={this.state.cliente_id}
                    onCancel={()=>{
                        this.setState({modal_visible: false})
                        this.getCliente()
                    }}
                />
            </>
        )
    }
}


export default function (props){

    const params = useParams()
    const navigate = useNavigate()


    return <ClientesDetalles  {...props} params={params} navigate={navigate}
    {...usePermissions(null, {
        editarCliente: ["clientes", "edit"],
        deleteCliente: ["clientes", "delete"],
        createCliente: ["clientes", "create"],
    })}
    />
}