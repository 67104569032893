import React, { Component } from 'react';
import { Row, Col, PageHeader, Layout, Card } from 'antd'
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { IconWallet, IconGraphic, IconCuenta, IconShield } from './../Widgets/Iconos'
import { CardStatistics } from './../Widgets/Cards'
import { useNavigate, useSearchParams } from 'react-router-dom';

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            plantas: {
                nombres: [],
                disponibles: [],
                ocupados: []
            },
            montos: {
                totales: 0,
                mes: 0,
                invertido: 0,
                confirmar: 0

            },
            ventas: {
                year: {
                    meses: [],
                    data: []
                },
                total: []
            }
        }
    }


    componentDidMount() {
        this.props.clearSearch()
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getDashboard()
    }

    /**
     *
     *
     * @memberof Usuarios
     * @method getDashboard
     * @description Obtiene los datos necesarios para la analitica
     */
    getDashboard = () => {
        axios.get('/dashboard/agave', {
            params: {
                //limit_plantas: 10
            }
        })
            .then(({ data }) => {
                console.log(data)


                // Grafica de Plantas por Hacienda

                const plantas = {
                    nombres: [],
                    disponibles: [],
                    ocupados: []
                }

                for (let planta of data.data.plantas) {
                    plantas.nombres.push(planta.nombre)
                    plantas.disponibles.push(planta.disponible)
                    plantas.ocupados.push(planta.ocupado)
                }

                // Montos de Cards
                const montos = {
                    confirmar: data.data.montos.confirmar.monto,
                    totales: data.data.montos.totales.monto,
                    mes: data.data.montos.mes.monto,
                    invertido: data.data.montos.invertido.monto
                }

                // Ventas
                const ventas = {
                    year: data.data.ventas_meses,
                    total: data.data.ventas
                }

                this.setState({
                    plantas,
                    montos,
                    ventas
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        let option = {
            xAxis: {
                type: 'category',
                data: this.state.ventas?.total?.map(hacienda => hacienda.nombre),
                axisLabel: {
                    rotate: 90
                }
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                data: this.state.ventas?.total?.map(hacienda => hacienda.monto),
                type: 'bar'
            }]
        };

        let option_2 = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: this.state.ventas?.year?.meses
            },
            series: this.state.ventas?.year?.data?.map(hacienda => ({
                name: hacienda.nombre,
                type: 'bar',
                stack: 'total',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: hacienda.historico.map(value => value !== '-' ? value.mont : '-')
            }))

        };

        var emphasisStyle = {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.3)'
            }
        };

        let option_3 = {
            legend: {
                data: this.state.plantas?.nombres,
                left: '10%'
            },
            tooltip: {},
            xAxis: {
                data: this.state.plantas?.nombres,
                axisLine: {
                    onZero: true
                },
                splitLine: {
                    show: false
                },
                splitArea: {
                    show: false
                },
                axisLabel: {
                    rotate: 90
                }
            },
            yAxis: {},
            grid: {
                bottom: 100
            },
            series: [{
                name: 'Disponibles',
                type: 'bar',
                barWidth: 30,
                barCategoryGap: 20,
                stack: 'one',
                emphasis: emphasisStyle,
                data: this.state.plantas?.disponibles
            }, {
                name: 'Ocupados',
                type: 'bar',
                barWidth: 30,
                barCategoryGap: 20,
                stack: 'one',
                emphasis: emphasisStyle,
                data: this.state.plantas?.ocupados
            },]
        };
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Agave"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={6} className="grid">
                        <CardStatistics title="Ventas Totales" suffix={"MXN"} monto={this.state.montos.totales?.toMoney ? this.state.montos.totales.toMoney(true) : 0} icon={(color) => <IconWallet color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={6} className="grid">
                        <CardStatistics title="Ventas del Mes" suffix={"MXN"} monto={this.state.montos.mes?.toMoney ? this.state.montos.mes.toMoney(true) : 0} icon={(color) => <IconGraphic color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={6} className="grid">
                        <CardStatistics title="Estimado Inversión" suffix={"MXN"} monto={this.state.montos.invertido?.toMoney ? this.state.montos.invertido.toMoney(true) : 0} icon={(color) => <IconCuenta color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={6} className="grid">
                        <CardStatistics title="Ventas por Confirmar" suffix={"MXN"} monto={this.state.montos.confirmar?.toMoney ? this.state.montos.confirmar.toMoney(true) : 0} icon={(color) => <IconShield color={color} />} />
                        </Col>
                        <Col xs={24} md={12}>
                            <Card title={'Ventas por Hacienda'}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option}
                                            style={{ width: '100%', maxWidth: 400 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12}>
                            <Card title={'Plantas por Hacienda'}>
                                <Row>
                                    <Col span={24}>
                                        <ReactECharts
                                            option={option_3}
                                            style={{ width: '100%', maxWidth: 700 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={'Ventas del Año'}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={option_2}
                                            style={{ width: '100%', maxWidth: 700 }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();

   

    return <Dashboard {...props} navigate={navigate} params={params} />
}
