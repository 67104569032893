import React, { Component } from "react";
import { Button, Col, Form, InputNumber, Input, message, Modal, Row, Spin, Switch, Typography, Select, Divider } from 'antd';
import PropTypes from "prop-types";

const axios = require('axios').default;

const { Title, } = Typography;
const { Option } = Select;
const formItemLayout = { labelCol: { span: 24, }, wrapperCol: { span: 24, } };
/**
 *
 *
 * @export
 * @class FormVariantes 
 * @extends {Component}
 */
class FormVariantes extends Component {


    /**
     *
     *
     * @static
     * @memberof FormVariantes
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sizes: {
                data: [],
                loading: false,
                page: 1,
                total: 0,
                limit: 50,
                search: ''
            }

        }
    }

    formRef = React.createRef();


    componentDidMount() {

        
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getSizes()
        if (this.props.id != null) {
            this.getVariante()
        }
    }



    /**
     *
     *
     * @memberof FormVariantes
     * @method getSizes
     * @description Obtener el listado de sizes
     */
    getSizes = ({
        page = this.state.sizes.page,
        limit = this.state.sizes.limit,
        search = this.state.sizes.search,
    } = this.state.sizes) => {
        this.setState(state => {
            state.sizes.loading = true;
            state.sizes.page = page;
            state.sizes.limit = limit;
            return state;

        })
        axios.get('/sizes', {
            params: {
                page, limit, search
            }
        })
            .then(response => {
                
                this.setState(state => {
                    if (page === 1)
                        state.sizes.data = response.data.data.itemsList
                    else
                        state.sizes.data = [...state.sizes.data, ...response.data.data.itemsList]

                    state.sizes.total = response.data.data.paginator.itemCount
                    state.sizes.pages = response.data.data.paginator.pageCount
                    state.sizes.loading = false
                    return state;
                })
            })
            .catch(error => {
                
                message.error('Error al traer la información')
            })

    }

    /**
     *
     *
     * @memberof FormVariantes
     * 
     * @method get
     * @description Obtenemos la variante
     * @param {string} id Identificador de la variante
     */
    getVariante = (id = this.props.id) => {
        this.setState({ loading: true })
        axios.get("/variantes/get", {
            params: { id }
        })
            .then(res => {
                
                let variante = res.data.data.variante;
                this.formRef.current.setFieldsValue({
                    nombre: variante.nombre,
                    sku_variante: variante.sku_variante,
                    descripcion: variante.descripcion,
                    size_id: (variante.size_id != undefined) ? variante.size_id._id : null,
                    precio: variante.precio,
                    activo: variante.activo,
                })
            })
            .catch(res => {
                message.error('No se pudo cargar la Variante')
                
            }).finally(() => this.setState({ loading: false }))
    }



    /**
     *
     *
     * @memberof FormVariantes
     * 
     * @method onFinish
     * @description Cuando se guarda la variante
     * @param {object} values - Objecto que contiene los valores del formulario
     */
    onFinish = values => (this.props.id !== null) ? this.update(values) : this.save(values)

    /**
     *
     *
     * @memberof FormVariantes
     * 
     * @method save
     * @description Guardamos la variante
     * @param {object} values - Objecto que contiene los valores del formulario 
     */
    save = (values) => {

        this.setState({ loading: true })

        axios.post('/variantes/add', {
            ...values,
            producto_id: this.props.producto_id
        })
            .then(() => {
                message.success("¡Se ha guardado correctamente la variante!")
                this.props.onClose();
            })
            .catch((e) => {
                message.error('No se pudo guardar la variante')
                
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }

    /**
     *
     *
     * @memberof FormVariantes
     * 
     * @method update
     * @description Actualizamos la variante.
     * @param {object} values - Objecto que contiene los valores del formulario
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/variantes/update', {
            ...values,
            id: this.props.id,
            producto_id: this.props.producto_id
        })
            .then(() => {
                message.success("¡Se ha guardado correctamente la variante!")
                this.props.onClose();
            })
            .catch((e) => {
                message.error('No se pudo guardar la variante')
                
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }




    render() {
        const { formRef, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">  {`${this.props.id ? 'Editar' : 'Nueva'}  Variante`}</Title>
                <Form
                    {...formItemLayout}
                    layout="vertical"
                    ref={formRef}
                    initialValues={form}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[
                            { required: true, message: "Ingrese el nombre de la variante" }
                        ]} >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                        rules={[
                            { required: true, message: "Ingrese la descripción de la variante" }
                        ]} >
                        <Input.TextArea placeholder="Descripción"  maxLength={100}/>
                    </Form.Item>

                    <Form.Item
                        label="SKU"
                        name="sku_variante"

                        rules={[
                            { required: true, message: "Ingrese el SKU" }
                        ]} >
                        <Input placeholder="SKU" />
                    </Form.Item>

                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item
                                label="Tamaño"
                                name="size_id"
                                rules={[
                                    { required: true, message: "Ingrese el Tamaño" }
                                ]} >
                                <Select >
                                    {this.state.sizes.data.map(item => <Option key={item._id} value={item._id}>{item.size}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Precio"
                                name="precio"
                                rules={[
                                    { required: true, message: "Ingrese el Precio" }
                                ]} >
                                <InputNumber
                                    className="width-100"
                                    prefix="$" placeholder="0.00" min={0}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item
                                label="Activo"
                                name="activo"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>

                    </Row>

                    {(this.props.id == null) ?
                        <>
                            <Divider orientation="left">Inventario</Divider>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Existencias"
                                        name="existencias"
                                    >
                                        <Input type="number" placeholder="0" min={1} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Inventario Mínimo"
                                        name="inv_minimo"
                                    >
                                        <Input type="number" placeholder="0" min={1} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Inventario Máximo"
                                        name="inv_maximo"
                                    >
                                        <Input type="number" placeholder="0" min={1} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                        : null}



                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading} >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin >

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            className="modal-zeus"
        >
            <FormVariantes {...props} />
        </Modal>
    )
}
