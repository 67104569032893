import React, { useContext } from "react";
import { PageHeader, Row, Col, Button, Card, Avatar, List, Spin, Space, Typography, Popover, message, Popconfirm, Tag } from 'antd';
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, EditOutlined, FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
//componentes
import { IconArrowBack, IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import useSearchParams from '../../../Hooks/SearchParams';
//modales
import ModalConfirmar from './ModalCorfirmar'
import ModalTransaccionDetalle from './ModalTransaccionDetalle'
import ModalTransaccion from './ModalTransaccion'
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import { User } from "../../../Hooks/Logged";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";


import usePermissions from "../../../Hooks/usePermissions";

const moment = require('moment')
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {React.Component}
 */
class Transacciones extends React.Component {

	static contextType = User

	constructor(props) {
		super(props)
		let { page, limit, filters, search, sort } = this.props.params
		this.state = {

			loading: false,

			transacciones: {
				loading: false,
                data: [],
                page: page ?? 1,
                limit: limit ?? 10,
                filters: filters || [],
                total: 0,
                sort: sort || {},
            },
		}
	}

	drawerFiltros = React.createRef()

	componentDidMount() {

		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getTransacciones();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.search !== this.props.search) {
			this.setState({ search: this.props.search }, () => this.getTransacciones())
		}
	}

	/**
	* @method getTransacciones
	* @param {number} page - Numero de la pagina listada
	* @description Obtiene las transacciones realizadas
	*/
	getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.state.search,
        filters = this.state.transacciones.filters,
        sort = this.state.transacciones.sort,
    } = this.state.transacciones, { transacciones } = this.state
    ) => {

		const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)

		this.props.setParams(params)

		let clearCheckbox = (page === 1)

		this.setState({ transacciones: { ...this.state.transacciones, page, limit, search, filters, sort }, clearCheckbox, loading: true })

		axios.get('/transacciones', {
			params: {
				page: transacciones.data.length < 1 && page !== 1 ? 1 : page,
                limit: transacciones.data.length < 1 && page !== 1 ? limit * page : limit,
                search: this.props.search,
                filters,
                sort
			}
		}).then(({ data }) => {

			console.log(data.data)
			this.setState({
				transacciones: {
					...this.state.transacciones,
					data: data.data.itemsList,
					total: data.data.itemCount,
					search
				}
			})

		}).catch(error => {
			console.log(error)
			message.error('Error al obtener la Información')

		}).finally(() => this.setState({ loading: false, clearCheckbox: false }))
	}


	/**
	 *
	 *
	 * @memberof Transacciones
	 * @method back
	 * @description Realiza la accion de volver a la anterior pagina
	 */
	back = () => {
		this.props.navigate(-1);
	}
	render() {

		const { transacciones } = this.state

		return (
			<Spin spinning={false}>
				<PageHeader
					className="admin-page-header"
					title="Transacciones"

					extra={<Space>
						<Button
							onClick={() => this.setState({ visibleFilters: true })}
							className="button-plus"
							icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
					</Space>}
					backIcon={(this.props.params.inversion_id != undefined) ? < IconArrowBack /> : null}
					onBack={(this.props.params.inversion_id != undefined) ? this.back : null}

				/>
				
				<List
					loading={transacciones.loading}
					className="component-list pd-1"
					itemLayout="horizontal"
					dataSource={transacciones.data}
					locale={{ emptyText: "Sin Transacciones" }}
					pagination={{
						current: transacciones.page,
						pageSize: transacciones.limit,
						total: transacciones.total,
						pageSizeOptions: [10, 20, 50, 100, 500, 1000],
						position: 'bottom',
						className: "flex-left ml-1",
						onChange: (page,limit) => this.getTransacciones({page,limit})
					}}
					header={<Row className=" width-100 pl-1 pr-1 ">
						<Col xs={12} md={4} className="center">
							Concepto
						</Col>
						<Col xs={12} md={4} className="center">
							Hacienda
						</Col>
						<Col xs={12} md={3} className="center">
							Fecha
						</Col>
						<Col xs={12} md={3} className="center">
							Tipo
						</Col>
						<Col xs={24} md={4} className="center">Comprador</Col>

						<Col xs={24} md={3} className="center">
							Monto
						</Col>
						<Col xs={24} md={3}></Col>
					</Row>
					}
					renderItem={item => (
						<List.Item className="component-list-item">
							<Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
								<Row className="width-100 " gutter={[0, 12]}>
									<Col className="center" xs={12} md={4}>
										{item.concepto}
									</Col>
									<Col className="center" xs={12} md={4}>
										<CustomAvatar
											image={item.hacienda?.imagenes?.length > 0 ? item.hacienda?.imagenes[0]?.filename : null}
											name={item.hacienda?.nombre}
										/>
										<Text className="ml-1">{item.hacienda ? item.hacienda?.nombre : 'N/A'}</Text>
									</Col>

									<Col className="center" xs={12} md={3}>
										{moment(item.createdAt).format('DD-MM-YYYY')}
									</Col>
									<Col className="center" xs={12} md={3}>
										{item.pos === true ? <Tag className="admin-tag tag-semi-cyan">POS</Tag> : <Tag className="admin-tag tag-cyan-light">Agave</Tag>}
									</Col>
									<Col xs={24} md={4} className="center">
										<Link to={`/admin/usuarios/${item.cliente_id?.usuario_id?._id}`}>
											<CustomAvatar
												image={item.cliente_id?._id ? item.cliente_id?.avatar : null}
												name={`${item.cliente_id?.nombre} ${item.cliente_id?.apellido_paterno}`}
												style={{ minWidth: '35px', marginRight: '8px' }}
											/>
											<Text ellipsis>{item.cliente_id?._id ? `${item.cliente_id?.nombre} ${item.cliente_id?.apellido_paterno}` : 'N/A'}</Text>
										</Link>
									</Col>

									<Col className="center" xs={24} md={3}>
										$ {(typeof item.monto === "number") ? item.monto.toMoney(true) : 0.00} {item.moneda ? item.moneda : "MXN"}
									</Col>
									<Col className="center" xs={24} md={3}>
										<Space direction="horizontal">
											<Button
												type="primary"
												className='btn-primary'
												icon={<IconEye />}
												onClick={() => this.setState({ modalTransaccionDetalle: true, transaccion_id: item._id })}
											/>
											<Button
												type="primary"
												className='btn-secondary'
												icon={<EditOutlined style={{ color: "white" }} />}
												disabled={!this.props.editarTransaccion}
												style={{ opacity: !this.props.editarTransaccion ? 0.5 : 1 }}
												onClick={() => this.setState({ modalTransaccion: true, transaccion_id: item._id })}
											/>

											<Popconfirm
												placement="topRight"
												title="¿Deseas eliminar esta transacción?"
												onConfirm={() => axios.delete('/transacciones/delete', { params: { id: item._id } }).then((response) => {
													message.success(response?.data?.message)
													this.getTransacciones()
												})
													.catch((error) => {
														message.error(error?.response?.data?.message);
														this.getTransacciones();
													})
												}
												okText="Si"
												cancelText="No"
												disabled={!this.props.deleteTransaccion}

											>
												<Button  style={{ opacity: !this.props.deleteTransaccion ? 0.5 : 1 }} type="primary" danger className='btn-delete' title="Eliminar" icon={<IconBtnDelete />} />
											</Popconfirm>
										</Space>
									</Col>
								</Row>
							</Card>
						</List.Item> 
					)}
				/>

				{this.props.createTransaccion ? 				<FloatingButton title="Nueva transacción" onClick={() => this.setState({ modalTransaccion: true, transaccion_id: undefined })} /> : null }

				<DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Transacciones"
                    csv={"transacciones"}
                    updateFilters={filters => this.getTransacciones({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    estatus={false}
					haciendas={true}
                    cuentas={false}
                    clientes={true}
                    ordenes={false}
                    ordenes_compras={false}
                    area_rubro={false}
                    clasificadores={false}
                    razones_sociales={false}
                    negocios={false}
                    proveedores={false}
					vendedores={false}
					vendedores_transaccion={true}
					inversiones={true}
                />

				<ModalConfirmar
					visible={this.state.modalConfirmar}
					onCancel={() => {
						this.setState({ modalConfirmar: false, transaccion: undefined })
						this.getTransacciones(this.state.page)
					}}
					transaccion={this.state.transaccion}
				/>

				<ModalTransaccionDetalle
					visible={this.state.modalTransaccionDetalle}
					onCancel={() => {
						this.setState({ modalTransaccionDetalle: false, transaccion: undefined })
						this.getTransacciones(this.state.page)
					}}
					transaccion_id={this.state.transaccion_id}

				/>

				<ModalTransaccion
					visible={this.state.modalTransaccion}
					onCancel={() => {
						this.setState({ modalTransaccion: false, transaccion_id: undefined })
						this.getTransacciones(this.state.page)
					}}
					transaccion_id={this.state.transaccion_id}
					inversion_id={this.props.params?.inversion_id}
				/>
			</Spin>
		)
	}
}


export default function (props) {

	const user = useContext(User)
	const navigate = useNavigate()

	const [params, setParams] = useSearchParams();

	const permissions = usePermissions(user?.rol_id?.permisos, {
		editarTransaccion: ["transacciones", "edit"],
		deleteTransaccion: ["transacciones", "delete"],
		createTransaccion: ["transacciones", "create"],
	})

	console.log("permissions", permissions)
	return <Transacciones  {...props} params={params} setParams={setParams} navigate={navigate} {...permissions} />
}