import React, { Component } from "react";
import {
  Row,
  Typography,
  Button,
  Switch,
  Layout,
  PageHeader,
  Space,
  message,
  List,
  Card,
  Avatar as AvatarD,
  Modal,
} from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Logged from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";

//componentes
import Avatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";

const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se listan todos los proyectos
 */
class Templates extends Component {
  /**
   *Creates an instance of proyectos.
   * @param {*} props
   * @memberof Proyectos
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible_modal: false,
      templates: {
        data: [],
        limit: 10,
        page: 1,

        total: 0,
        pages: 0,
      },
    };
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = (
    {
      page = this.state.templates.page,
      limit = this.state.templates.limit,
      search = this.state.templates.search,
    } = this.state.templates
  ) => {
    this.setState({ loading: true });
    return axios
      .get("/email-templates", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        this.setState({ templates: data });
      })
      .catch(({ response, ...args }) => {
        console.error({ ...response, ...args });
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  deleteTemplate = (_id) => {
    return Modal.confirm({
      title: "¿Desea eliminar el modal?",
      content: "Se eliminará el template completamente",
      onOk: () =>
        axios
          .delete("/email-templates/" + _id)
          .then((response) => {
            this.getTemplates();
          })
          .catch((error) => {
            message.error(error?.response?.data ?? "No fue posible eliminar");
          }),
    });
  };

  updateStatus = (_id, activo) => {
    this.setState({ loading: true });
    axios
      .put("/email-templates/" + _id, { activo })
      .then((e) => {
        this.getTemplates();
      })
      .catch(({ response, ...args }) => {
        console.error({ ...response, ...args });
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <>
        <PageHeader className="admin-page-header" title="Templates" />
        <Content className="admin-content content-bg pd-1">
          <List
            grid={{ gutter: 16, column: 4 }}
            className="component-list "
            itemLayout="horizontal"
            locale={{ emptyText: "Sin Templates" }}
            dataSource={this.state.templates.data}
            loading={this.state.loading}
            pagination={{
              current: this.state.templates.page,
              pageSize: this.state.templates.limit,
              total: this.state.templates.total,

              hideOnSinglePage: false,
              position: "bottom",
              showSizeChanger: true,
              pageSizeOptions: [12, 24, 64, 128, 512],

              onChange: (page, limit) => this.getTemplates({ page, limit }),
            }}
            renderItem={({
              nombre,
              descripcion,
              asunto,
              _id,
              activo,
              hacienda_id,
            }) => (
              <List.Item className="component-list-item" key={_id}>
                <Card className="card-list">
                  <Row
                    gutter={[16, 16]}
                    justify={"space-between"}
                    className="width-100"
                  >
                    <Space
                    //style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <Space>
                        <Avatar
                          shape="square"
                          name={hacienda_id.nombre}
                          color={hacienda_id.color}
                          url={
                            hacienda_id.logo
                              ? {
                                  url:
                                    axios.defaults.baseURL +
                                    "/haciendas/" +
                                    _id,
                                  logo: hacienda_id.logo?.name,
                                }
                              : null
                          }
                          style={{
                            backgroundColor:
                            hacienda_id.color === undefined ? "#9E2E9D" : hacienda_id.color,
                          }}
                        />
                      </Space>
                      <Switch
                        checked={activo}
                        onChange={(value) => this.updateStatus(_id, value)}
                      />
                    </Space>
                  </Row>
                  <Row gutter={[16, 16]} align="center">
                    <Text style={{ fontSize: 20 }}>{nombre}</Text>
                  </Row>
                  <Row gutter={[16, 16]} align="center">
                    <Text>{descripcion || <br />}</Text>
                  </Row>
                  <Row align="center" justify={"end"}>
                    <Space>
                      <Link to={"/admin/templates/editar/" + _id}>
                        <Button type="primary" size="small" icon={<EditOutlined />} />
                      </Link>
                      <Button
                        onClick={() => this.deleteTemplate(_id)}
                        danger
                        size="small"
                        type="primary"
                        icon={<DeleteOutlined />}
                      />
                    </Space>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </Content>
        <FloatingButton
          onClick={() => this.props.navigate("/admin/templates/crear")}
        />
      </>
    );
  }
}

export default function TemplatesWrapper(props) {
  let user = React.useContext(Logged);

  let permissions = usePermissions(user?.rol_id?.permisos, {
    createProyectos: ["templates", "create"],
    editProyectos: ["templates", "edit"],
    readProyectos: ["templates", "read"],
    deleteProyectos: ["templates", "delete"],
  });

  return <Templates {...props} {...permissions} navigate={useNavigate()} />;
}
