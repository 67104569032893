import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Card, message, Spin} from 'antd';
import axios from 'axios';
import { IconPDFColor, IconAgabeColor } from '../../Widgets/Iconos'

import '../../../Styles/Global/modales.css'
import { User } from '../../../Hooks/Logged'


const { Title, Text } = Typography;
const moment = require("moment")


/**
 *
 *
 * @class ModalEstadoCuenta
 * @extends {Component}
 */
class ModalEstadoCuenta extends Component {

    static contextType = User

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            estados: {
                data: [],
                limit: 12,
                page: 1,
                total: 0,
                pages: 0,
            }
            
        }
    }
    
    componentDidMount = ( ) => {
        this.getEstadosCuenta()   
    }

    /**
     *
     *
     * @memberof ModalEstadoCuenta
     * 
     * @method getPDF
     * @description Generamos el PDF del Estado de Cuenta
     */
    getPDF = (estado_cuenta_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/customer/estado-cuenta/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('estado_cuenta_id', estado_cuenta_id)
        window.open(urlPDF.href, '_blank');
    }


    /**
    * @memberof ModalEstadoCuenta
    * @description Obtiene todas todas las EstadosCuenta relacionadas al cliente y a sus inversiones
    */
    getEstadosCuenta = ({
        page = this.state.estados.page,
        limit = this.state.estados.limit,
    } = this.state.estados) => {

        this.setState({loading: true})
        axios.get('/customer/estados-cuentas', {
            params: {
                page,
                limit,
                cliente: true,
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({
                    estados:{
                        ...this.state.estados,
                        data: data.data
                    }
                })
            })
            .catch(err => {
                message.error('No se pudieron cargar los estado de cuenta.')
            })
            .finally(() => this.setState({ loading: false }))

    }


    render() {

        return (
            <Form
                layout="vertical"
            >
            	<Card className="card-hacienda-modal">
            		<Row>
            			<Col span={24}>
            				<Title className="text-center m-0">Estados de Cuenta</Title>
            			</Col>
            		</Row>
            	</Card>
                <Spin spinning={this.state.loading}>
                	<Row className="mb-3">
                        {this.state.estados.data.map(item => {
                            return <>
                                <Col span={20} className="flex-left">
                                    <IconAgabeColor/> <Text className="ml-1 mr-1" style={{width: 120, textTransform: 'uppercase'}}> {moment(item.createdAt).format("MMMM - YYYY")} </Text> 
                                </Col>
                                <Col span={4} className="flex-right">
                                    <a onClick={()=>this.getPDF(item._id)} target="_blank"><IconPDFColor/></a>
                                </Col>
                            </>
                        })}
            		</Row>
                </Spin>  		
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={false}
        destroyOnClose={true}
        zIndex={1000}
        className="modal-zeus"
    >
        <div className="center">
            <img src="/img/zeus_logo.svg" alt="logo Zeus" width="120"/>
        </div>
        < ModalEstadoCuenta {...props} />
    </Modal>

}