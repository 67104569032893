import React, { Component, useContext } from "react";
import { Row, Col, Button, Modal, Typography, Card, message, Spin, Alert } from "antd";
import { User } from "../../../Hooks/Logged";
import "../../../Styles/Global/modales.css";
const { Title, Paragraph } = Typography;

const moment = require("moment")

/**
 *
 *
 * @class ModalTerminos
 * @extends {Component}
 */
class ModalTerminos extends Component {

  static contextType = User


  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  compraRef = React.createRef()

  render() {

    const { hacienda, contrato } = this.props

    const {
      ano,
      ano_text,
      dias,
      folio,
      folio_number,
      mes,
      moneda,
      moneda_text,
      plantas,
      plantas_texto,
      precio_planta,
      precio_planta_decimales,
      precio_planta_text,
      tipo_cambio,
      tipo_cambio_decimales,
      tipo_cambio_text,
      total,
      total_decimales,
      total_text } = contrato

    const user = this.context
    // console.log("USER", contrato)

    return (
      <>
        <Typography>
          <Paragraph>FOLIO {folio}</Paragraph>
          <Title level={3} align="center">CONTRATO DE INVERSIÓN EN PLANTAS DE AGAVE AZUL TEQUILANA WEBER.</Title>
          <Paragraph>En la ciudad de San Miguel el Alto, Jalisco, a los días <strong>{dias}</strong> del mes de <strong>{mes}</strong> del <strong>{ano} {ano_text}</strong></Paragraph>

          <Title level={5} align="center">DECLARACIONES:</Title>
          <ol>
            <li>
              <Paragraph align="justify">
                Declara y manifiesta el señor JOSE DE JESUS HERMOSILLO
                REYNOSO, que es legalmente el Administrador General Unico de la
                empresa denominada ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de
                CV. Dicha empresa se constituyó mediante escritura pública número
                26,230, de fecha 10 de agosto del 2022, ante la fe del Licenciado
                César Luis Ramírez Casillas, Notario Público número 1 de San
                Miguel el Alto, Jalisco. La cual se encuentra en Tramite para su
                incorporacion en el Registro Público de la Propiedad y de Comercio
                de Tepatitlán de Morelos, Jalisco.
              </Paragraph>
            </li>
            <li>
              Así mismo expresa ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de
              CV, en su calidad de “EL PRODUCTOR Y PROPIETARIO”, que se dedica a
              la plantación, cultivo, jima y venta de AGAVE AZUL TEQUILANA WEBER
              de primera calidad.
            </li>
            <li>
              Domicilio en Revolución Mexicana # 75 San Miguel el Alto,
              Jalisco México Tel: 347-688-12 91.
            </li>

            <li>
              Declara el señor (a) {user?.nombres} {user?.apellido_paterno} {user?.apellido_materno}, a quien por razón del mismo se le denominará como "EL INVERSIONISTA”,
              ser de nacionalidad mexicana, mayor de edad, con fecha de nacimiento {moment(user?.cliente?.fecha_nacimiento).format("LLL")}, y que tiene plena capacidad legal para
              contratar y obligarse.
            </li>
            <li>
              Expuesto lo anterior, ambas partes declaran estar de acuerdo
              de celebrar el presente contrato de INVERSIÓN EN PLANTA DE AGAVE
              AZUL TEQUILANA WEBER, sujetando a su realización y cumplimiento a
              la observancia de lo pactado en las siguientes:
            </li>
          </ol>

          <Title level={5} align="center" className="mt-1">CLAUSULAS:</Title>

          <ol>
            <li>
              <Paragraph align="justify">
                PRIMERA.- INTERVINIENTES. Por una parte la empresa denominada ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV, a quien se le denomina como El Productor y Propietario y por la otra el señor (a)
                {user?.nombres} {user?.apellido_paterno} {user?.apellido_materno}, a quien por razón del mismo se le denominará como "EL INVERSIONISTA", que en este momento adquiere para si, la cantidad de {plantas} {plantas_texto} PLANTAS DE AGAVE AZUL TEQUILANA WEBER, cada una de ellas por la cantidad de 

              {/* precio_planta,
              precio_planta_decimales,
              precio_planta_text, */}
                <strong>${precio_planta?.toMoney(true)} 
                ({(precio_planta_text ?? "").toUpperCase()}
                {precio_planta_decimales}/100 {moneda_text?.toUpperCase()})</strong>,
                dando un total en su INVERSION la suma de
                <strong>$ {(parseFloat(total))?.toMoney(true)} ({(total_text ?? "").toUpperCase()} {total_decimales.toUpperCase()} / {moneda_text.toUpperCase()})</strong>, teniendo un tipo de cambio el dólar de <strong>${tipo_cambio?.toMoney(true)} {tipo_cambio_text.toUpperCase()} 
                ({tipo_cambio_decimales}/100 PESOS MEXICANOS)</strong>, cantidad en dinero que se entrega en este momento mediante transferencia interbancaria. Esta cantidad no será reembolsable en
                ningún momento al “CLIENTE” ya que se utilizará para que las plantan lleven acabo su proceso desde ser plantada, fertilizada, fumigada, jimada y
                llegue a su acarreo final.
              </Paragraph>
            </li>
            <li>
              = = SEGUNDA.- Así mismo convienen los contratantes tanto la persona moral ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV,
              en su calidad de “EL PRODUCTOR Y PROPIETARIO”, como el señor LUIS MIGUEL ALDANA SOTO a quien por razón del mismo se le denominará
              como “EL INVERSIONISTA”, que en este instante adquiere la cantidad de {plantas} MIL PLANTAS DE AGAVE AZUL TEQUILANA WEBER, de primera calidad,
              las que ya han sido plantadas en el <strong>“{hacienda?.nombre}”</strong>, ubicado en <strong>“{hacienda?.localizacion?.place_name}”</strong>, el cual se encuentra
              perfectamente identificado, delimitado y en posesión legal de "EL PRODUCTOR Y PROPIETARIO”, sin conflicto ante terceras personas, dichas plantas
              se registran ante el Consejo Regulador del Tequila, expresando que todos los gastos que se generen por la plantación, fertilización, fumigación,
              mantenimiento, jima y el acarreo de las plantas de AGAVE AZUL TEQUILANA WEBER, correrán por cuenta del primero, por lo que todos los hijuelos que
              resulten de las plantas sembradas serán propiedad del productor.
            </li>
            <li>
              = = TERCERA.- Así mismo el valor y la cantidad en dinero que recibirá el "INVERSIONISTA”, respecto de la aportación inicial que realizó como inversión, por la adquisición de cada planta de agave, será de acuerdo al precio estipulado por kilo de agave se encuentre en el mercado, de acuerdo a la oferta y demanda al momento de la venta real y definitiva. Además se establece que en su defecto, únicamente procederá el reembolso en beneficio del "INVERSIONISTA" en el momento de que se acredite la venta a un tercero, con la constancia de cesión de derechos.
            </li>
            <li>
              = = CUARTA.- Al llegar a la venta final de las plantas, corresponderá el 65% (sesenta y cinco por ciento) a favor del señor (a) {user?.nombres} {user?.apellido_paterno} {user?.apellido_materno}, a quien por razón del mismo se le denominará como "EL INVERSIONISTA”, y el 35% (TREINTA Y CINCO POR CIENTO) a ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV, en su calidad de "EL PRODUCTOR Y PROPIETARIO", así mismo convienen ambas partes, que si fuere el caso de que en el momento de la venta del agave en su rendimiento ya jimado a un tercero, existan leyes que establezcan el pago de algún impuesto fiscal, dicho impuesto será cubierto por cada parte, según su ganancia obtenida.
            </li>
            <li>
              = = QUINTA.- Convienen tanto ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV, en su calidad de "EL PRODUCTOR Y PROPIETARIO”, como el "INVERSIONISTA”, que si fuere el caso de que ocurriese la pérdida total o parcial de la plantación, sea esta por siniestros naturales, por causas imputables al mismo, o por caso fortuito o de fuerza mayor (no aplica nevada extrema), el primero responderá en favor del segundo por la reposición de cantidad de la planta adquirida.
            </li>
            <li>
              = = SEXTA.- Convienen ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV, en su calidad de “EL PRODUCTOR Y PROPIETARIO”, como el señor (a) {user?.nombres} {user?.apellido_paterno} {user?.apellido_materno}, "EL INVERSIONISTA”, que para que las plantas de agave materia de la presente inversión lleguen a su madurez para la jima y el acarreo final se estipula como plazo el tiempo de 4 cuatro a 7 siete años a partir de que sean plantadas.
            </li>
            <li>
              = = SEPTIMA.- Así mismo estipulan tanto ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV, en su calidad de “EL PRODUCTOR Y PROPIETARIO”, como el “INVERSIONISTA” que en caso de que este último decidiere ceder en su totalidad o en parte sus derechos sobre las plantas a terceras personas, tendrá que dar a conocer dicha cesión a "EL PRODUCTOR Y PROPIETARIO”, por los medios electrónicos, con el propósito de tener un control exacto de la cesión y actualizacion de las plantas inventariadas en el sistema.
            </li>
            <li>
              = = OCTAVA.- Convienen las partes que en el presente contrato están de acuerdo con los derechos y obligaciones en él adquiridos. Así mismo convienen en que cualquier modificación, anexo, o adición al presente contrato solamente surtirá plenos efectos cuando se establezcan por escrito y de mutuo acuerdo, y para el cumplimiento del presente instrumento las partes se sujetan a las leyes mexicanas en la materia, y para la interpretación, ejecución y cumplimiento del presente contrato, las partes se sujetan y se someten expresamente a las Leyes y Tribunales del Partido Judicial de la ciudad de Jalostotitlán, Jalisco, que por razón de fuero y competencia le corresponde, renunciando expresamente al fuero que tengan o que llegaren a tener por razón de su domicilio presente o futuro o por cualquier otra causa.
            </li>
            <li>
              = = NOVENA.- Así mismo también estipula el “INVERSIONISTA” que si fuere el caso de que durante el tiempo y vigencia del presente contrato de compra llegase a fallecer, todas y cada una de las obligaciones y derechos adquiridos en el presente contrato pasarán en favor de su beneficiario señor {user?.nombres} {user?.apellido_paterno} {user?.apellido_materno}, quien adquirirá en su persona todos los derechos y obligaciones que en este contrato adquiere el “INVERSIONISTA”.
            </li>
            <li>
              = = LEIDO que fue por las partes el presente contrato, y enteradas debidamente de su contenido y alcance, considerando que no existe error, dolo o violencia, y por encontrarse satisfechos sus elementos de consentimiento, objeto, y forma, reconociendo su validez en todo tiempo y lugar, lo firman ante la presencia de los testigos que firman también el presente contrato, el día de su fecha.
            </li>
          </ol>
        </Typography>

        <Title level={5} align="center">"EL PRODUCTOR Y PROPIETARIO” ZEUS ORO AZUL DE LOS ALTOS, S de PR de RL de CV </Title>

        <Row justify="center" className="mt-1">
          <Col>
            <Button
              type="primary"
              className="btn-submit large"
              onClick={() => this.props.onConfirm(true)}
            >
              Acepto los Terminos y Condiciones
            </Button>
          </Col>
        </Row></>
    );
  }
}

export default function (props) {
  const { visible = false, onClose = () => { } } = props;

  let user = useContext(User);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={null}
      footer={null}
      closable={false}
      destroyOnClose={true}
      zIndex={1000}
      width={600}
      className="modal-zeus"
    >
      <div className="center">
        <img src="/img/zeus_logo.svg" alt="logo Zeus" width="120" />
      </div>
      <ModalTerminos {...props} usuario={user} />
    </Modal>
  );
}
