import React, { Component } from 'react';
import { Button, Popconfirm, Spin, PageHeader, Layout, message, Space, Row, Col, Typography, List, Card, Switch, } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'

//compoanentes
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";

//modales
import ModalEmpleado from './ModalEmpleado'

const { Content } = Layout;
const { Text, } = Typography

/**
 * @export
 * @class Haciendas
 * @extends {Component}
 * @description Vista de Haciendas
 */
export default class Haciendas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            drawerHacienda: false,
            empleados: {
                data: [],
                page: 1,
                limit: 20,
                pages: 0,
                total: 0,
                search: null,
            }
        }
    }

    /**
     * 
     * @method componentDidMount
     * @description Obtenemos las empleados en cuestión
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEmpleados();
    }


    getEmpleados = ({
        page = this.state.empleados.page,
        limit = this.state.empleados.limit,
        search = this.state.empleados.search 
    } = this.state.empleados) => {

        axios.get('/empleados',{
            params:{
                page,
                limit,
                search
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                empleados:{
                    ...response.data
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los empleados')
        })
        
    }

    renderModalidad = (modalidad) => {
        switch (modalidad) {
            case 1:
                return 'Salario'
            case 2:
                return 'Destajo'
            default:
                return 'Por hora'
        }
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Empleados"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.empleados.loading}
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.empleados.data}
                            header={<Row className=" width-100 pr-1 pl-1 ">
                                <Col xs={12} md={6} className="center">
                                    Nombre
                                </Col>
                                <Col xs={12} md={6} className="center">
                                    Modalidad
                                </Col>
                                <Col xs={12} md={4} className="center">
                                    Tipo Pago
                                </Col>
                                <Col xs={12} md={4} className="center">
                                    Activo
                                </Col>
                                <Col xs={12} md={4} className="center">
                                    Acciones
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">

                                        <Row className="width-100" gutter={[0, 12]}>
                                            <Col xs={12} md={6} className="center">
                                                <Text ellipsis strong>{item.nombre} {item.apellido}</Text>
                                            </Col>
                                            <Col xs={12} md={6} className="center">
                                                <Text ellipsis strong>{this.renderModalidad(item.modalidad_pago)}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Text ellipsis strong>{item.periodo_pago === 1 ? 'Semanal' : 'Quincenal'}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Switch
                                                    disabled
                                                    checked={item.activo}
                                                />
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <Space direction="horizontal" wrap>
                                                    <Link to={`/admin/empleados/detalle/${item._id}`}>
                                                        <Button
                                                            icon={<IconEye />}
                                                            type="primary"
                                                            className='btn-primary'
                                                            title="Ver Detalle">
                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        icon={<IconBtnEdit />}
                                                        type="primary"
                                                        className='btn-secondary'
                                                        title="Editar"
                                                        onClick={() => this.setState({ modal_visible: true, empleado_id: item._id })}>
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        className='btn-delete'
                                                        title="¿Deseas eliminar este Empleado?"
                                                        onConfirm={() => axios.delete('/empleado/delete', { params: { id: item._id } }).then((response) => {
                                                            message.success('Empleado eliminado')
                                                            this.getEmpleados()
                                                        }).catch((error) => {
                                                            let msj = "Error al eliminar el empleado"
                                                            message.error(msj)
                                                            this.getEmpleados();
                                                        })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            danger
                                                            icon={<IconBtnDelete style={{ color: "currentcolor" }} />} title="Eliminar"

                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                            pagination={{
                                onChange: (page, limit) => this.getEmpleados({ page, limit }),
                                current: this.state.empleados.page,
                                pageSize: this.state.empleados.limit,
                                total: this.state.empleados.total,
                            }}

                        />
                    </Content>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true})} />
                </Spin>
                <ModalEmpleado
                    visible={this.state.modal_visible}
                    onCancel={()=>{
                        this.setState({
                            modal_visible:false,
                            empleado_id: undefined
                        })
                        this.getEmpleados()
                    }}
                    empleado_id={this.state.empleado_id}
                />
            </>
        )
    }
}