import React, { Component } from 'react';
import { Button, Popconfirm, Space, Card, Row, List, Col, Spin, PageHeader, Layout, message, Typography, Tooltip, Switch } from 'antd'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { CodeSandboxOutlined } from '@ant-design/icons'
import { IconBtnDelete, IconBtnEdit, IconArrowBack } from '../../../Widgets/Iconos';
import { WarningOutlined } from '@ant-design/icons'

import FloatingButton from "../../../Widgets/FloatingButton/FloatingButton";
import ModalVariante from "./ModalVariante"

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Variantes
 * @extends {Component}
 * @description Vista de Variantes
 */
class Variantes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            data: [],
            producto: {
                nombre: 'producto'
            },
            page: 1,
            limit: 10,
            total: 0,
            pages: 0,

            filtroSearch: '',
            searching: true,


        }
    }


    /**
     *
     *
     * @memberof Variantes
     */
    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProducto();
        this.getVariantes();
    }



    /**
     *
     *
     * @param {*} prevProps
     * @memberof Variantes
     */
    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.setState({ search: this.props.search }, () => this.getProductos())
        }
    }

    /**
   * @method getProductos
   * @description Obtiene ls lista de Productos
   */
    getProducto = () => {
        this.setState({ loading: true })
        axios.get('/productos/get', {
            params: {
                id: this.props.params.producto_id,
            }
        }).then(response => {
            this.setState({
                producto: response.data.data
            })
        }).catch(error => {
            
            message.error('Error al obtener el Producto')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     *
     *
     * @memberof Variantes
     * @description Obtiene ls lista de Variantes
     * @param {Number} [page] Página actual de la lista
     * @param {String} [search] Busqueda por texto
     * @param {ObjectId} [producto_id] Id del producto a cargar variantes
     */
    getVariantes = (page = this.state.page) => {
        this.setState({ loading: true })
        axios.get('/variantes', {
            params: {
                page: page,
                search: this.props.search,
                producto_id: this.props.params.producto_id,
            }
        }).then(({ data }) => {
            
            this.setState(state => {
                if (page === 1)
                    state.data = data.data.itemsList
                else
                    state.data = [...state.data, ...data.data.itemsList]

                state.total = data.data.paginator.itemCount
                state.pages = data.data.paginator.pageCount
                state.loading = false
                return state;
            })
        })
            .catch(error => {
                message.error('Error al traer la información')
            }).finally(() => this.setState({ loading: false }))
    }



    /**
     *
     *
     * @memberof Variantes
     * @description Muestra el modal de edicion o creacion 
     * @param {ObjectId} variante_id id a cargar si se esta editando un registro
     */
    showModal = (variante_id = null) => this.setState({ modal_visible: true, variante_id })


    back = () => {
        this.props.navigate(-1);
    }

    render() {

        const { showModal } = this
        const { producto } = this.state;
        return (
            <Spin spinning={this.state.loading}>
                <PageHeader className="admin-page-header"
                    title="Variantes"
                    subTitle={producto.nombre}
                    onBack={this.back}
                    backIcon={<IconArrowBack />} />
                <Content className="admin-content content-bg pd-1">
                    <List
                        locale={{ emptyText: "Sin Variantes" }}
                        dataSource={this.state.data}
                        itemLayout="horizontal"
                        className="component-list"
                        pagination={{
                            current: this.state.page,
                            pageSize: this.state.limit,
                            total: this.state.total,
                            hideOnSinglePage: true,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: (page) => this.getVariantes(page)
                        }}
                        loading={this.state.loading}
                        header={
                            <Row className=" width-100" >
                                <Col span={6} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col span={6} className="center">
                                    <Text strong>SKU</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Tamaño</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text strong>Precio</Text>
                                </Col>
                                <Col span={2} className="center">
                                    <Text strong>Activo</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>
                        }

                        renderItem={item => (
                            <List.Item className={"component-list-item"}>
                                <Card className="card-list">
                                    <Row className="width-100">
                                        <Col xs={24} md={6} className="center">
                                            <Text>{item.nombre}</Text>
                                        </Col>
                                        <Col xs={12} md={6} className="center">
                                            <Text>{item.sku_variante}</Text>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <Space size={10}>
                                                <Text >{item.size_id?.size}</Text>
                                            </Space>
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            <Text>$ {item.precio.toMoney && item.precio.toMoney(true)}</Text>
                                        </Col>
                                        <Col xs={12} md={2} className="center">
                                            <Switch checked={item.activo} disabled />
                                        </Col>
                                        <Col xs={24} md={4} className="center">
                                            <Space wrap direction='horizontal'>
                                                <Link to={`/admin/productos/${this.props.params.producto_id}/variantes/${item._id}/inventario`} >
                                                    <Button type='primary' className="btn-primary" icon={<CodeSandboxOutlined className="text-white" />} />
                                                </Link>
                                                <Button type='primary' className='btn-secondary' icon={<IconBtnEdit />} onClick={() => showModal(item._id)} />
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar esta Variante?"
                                                    onConfirm={() => axios.delete('/variantes/delete', { params: { id: item._id } })
                                                        .then((response) => {
                                                            message.success("Eliminado Correctamente")
                                                            this.getVariantes()
                                                        })
                                                        .catch((error) => {
                                                            message.error("No es posible eliminar")
                                                            this.getVariantes(this.state.page);
                                                        })
                                                    }
                                                    okText="Sí"
                                                    cancelText="No"
                                                >
                                                    <Button type='primary' className='btn-delete' danger icon={<IconBtnDelete />} title="Eliminar" />
                                                </Popconfirm>
                                                {item.escaso ? <Tooltip title="Pocas Existencias">
                                                        <WarningOutlined style={{ color: '#ffd500', fontSize: 20 }} />
                                                    </Tooltip> : null}

                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                    <FloatingButton title="Nuevo registro" onClick={() => showModal()} />
                </Content>
                <ModalVariante
                    id={this.state.variante_id}
                    producto_id={this.props.params.producto_id}
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getVariantes(this.state.page)
                    }}
                />
            </Spin>
        )
    }
}


export default function (props){

    const params = useParams()
    const navigate = useNavigate()

    return <Variantes  {...props} params={params} navigate={navigate}/>
}