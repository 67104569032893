import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Select, Checkbox, Upload } from 'antd';

import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import { SelectRol } from '../../Widgets/Inputs/Inputs'
import '../../../Styles/Global/modales.css'
import FormatedInput from "../../Widgets/Inputs/FormatedInput";
import { AvatarLoader } from "../../Widgets/Inputs/Uploaders";
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;
const axios = require('axios').default;
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            admin: false,
            vendedor: false,
            usuario: {},
            option: [
                {
                    name: "General",
                    value: "general",
                },
                {
                    name: "Dashboard",
                    value: "dashboard"
                },
                {
                    name: "Usuarios",
                    value: "usuarios"
                },
                {
                    name: "Clientes",
                    value: "clientes"
                },
                {
                    name: "Inversiones",
                    value: "inversiones",
                },
                {
                    name: "Haciendas",
                    value: "haciendas"
                },
                {
                    name: "Transacciones",
                    value: "transacciones"
                },
                {
                    name: "Ventas",
                    value: "ventas"
                },
                {
                    name: "POS",
                    value: "pos",
                },
                {
                    name: "Movimientos",
                    value: "pos_movimientos",
                },
                {
                    name: "Reportes",
                    value: "pos_reportes",
                },
                {
                    name: "POS Dashboard",
                    value: "pos_dashboard"
                },
                {
                    name: "Productos",
                    value: "pos_productos"
                },
                {
                    name: "Tamaños",
                    value: "pos_tamanos"
                },
                {
                    name: "Categorias",
                    value: "pos_categorias"
                },
                {
                    name: "Nóminas",
                    value: "nominas"
                }
            ],
            avatar: []
        }
    }
    formRef = React.createRef();
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        if (this.props.usuario != undefined) {
            this.getUser();
        }
    }

    /**
     *
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        this.setState({ loading: true })

        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }
        }


        if (file.status === "done") {

            let avatar = this.state.avatar

            avatar.push({
                name: file.response.name,
                filename: file.response.filename,
            })

            this.setState({ avatar })

            e.file.filename = file.response.filename

        }
        this.setState({ loading: false })
        return e.fileList;
    };

    /**
     *
     * @param {string} file - Nombre del archivo
     * @method deleteImage
     * @description Quita el archivo del state de comprobantes para ponerlos en files_to_delete, asi cuando el usuario guarde, se eliminan, y no se borren automaticamente
     */
    deleteImage = (file) => {
        let imagenes = this.state.avatar
        let index = imagenes.findIndex(img => img.filename === file.filename)
        if (index != -1) {
            imagenes.splice(index, 1)
        }
    }

    /**
    * @method onFinish
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {


        console.log("V", values)

        if (Array.isArray(values.imagenes))
            values.imagenes = values.imagenes[0]

        if (this.props.usuario) {
            this.updateUser(values)
        } else {
            this.addUser(values)
        }
    }

    /**
    * @method addUser
    * @param {object} values - Objecto que contiene los valores modificados del formulario
    * @description Añade un nuevo registro de usuario
    */
    addUser = (values) => {
        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/usuarios/add', {
                vendedor: this.state.vendedor,
                ...values
            }).then(response => {
                message.success('¡Usuario Creado!')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error('Error al crear el Usuario')
            }).finally(() => this.setState({ loading: false }))
        })
    }

    /**
    * @method updateUser
    * @param {object} values - Objecto que contiene los valores modificados del formulario
    * @description Actualiza un usuario
    */
    updateUser = (values) => {
        this.setState({ loading: true })
        axios.put('/usuarios/update', {
            ...values,
            id: this.props.usuario,
            vendedor: this.state.vendedor,

        }).then(response => {
            message.success('¡Usuario Actualizado!')
            this.props.onClose()
        }).catch(error => {

            message.error('Error al actualizar el Usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
        * @method getUser
        * @description Trae la informacion del usuario
        */
    getUser = () => {
        this.setState({ loading: true })
        axios.get('/usuarios/get', {
            params: {
                id: this.props.usuario
            }
        }).then(response => {
            console.log("response", response.data);


            let roles = response.data.data.roles;

            if (roles != undefined) {
                let permisos = [];
                for (var [key, value] of Object.entries(roles)) {
                    if (value === true) {
                        permisos.push(key)
                    }
                }


                response.data.data.permisos = permisos;
            }
            this.setState({
                usuario: response.data.data,
                vendedor: response.data.data.vendedor,
                avatar: response.data.data?.avatar
            })
            this.formRef.current?.setFieldsValue({
                ...response.data.data,
                avatar: response.data.data?.avatar?.map(({ name, filename }, index) => ({
                    uid: index,
                    name: name,
                    filename: filename,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + filename
                })),
            })
        }).catch(error => {
            console.log("error", error);
            if (error.response.status === 403)
                message.error(error?.response?.data?.message)
            else
                message.error('Error al obtener la información del usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    render() {




        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout={"vertical"}
                    style={{ minHeight: '480px' }}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">
                        <Col xs={24} lg={24} >
                            <Form.Item
                                className="center"
                                name="avatar"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    maxCount={1}
                                    listType="picture-card"
                                    accept="image/*"
                                    onRemove={this.deleteImage}
                                >
                                    {this.state.avatar.length > 0 ? null : <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Subir Archivo</div>
                                    </div>}

                                </Upload>
                            </Form.Item>

                        </Col>
                        <Col span={18}>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input maxLength={50} />
                            </Form.Item>
                            <Form.Item
                                name="apellido_paterno"
                                label="Apellido Paterno"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellido paterno',
                                    },
                                ]}
                            >
                                <Input maxLength={60} />
                            </Form.Item>
                            <Form.Item
                                name="apellido_materno"
                                label="Apellido Materno"
                            >
                                <Input maxLength={60} />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Email',
                                    },
                                ]}
                            >
                                <FormatedInput trim maxLength={60} />
                            </Form.Item>
                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el Teléfono',
                                    },
                                ]}
                            >
                                <PhoneInput />
                            </Form.Item>
                            {this.props.clientes === false ? <Form.Item
                                name="rol_id"
                                label="Rol"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el Rol',
                                    },
                                ]}
                            >
                                <SelectRol />
                            </Form.Item> : null}

                            {/*<Form.Item
                                name="tipo"
                                label="Tipo Usuario"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccione el Tipo de Usuario',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        if (value === 2) {
                                            this.setState({ admin: true })
                                        } else {
                                            this.setState({ admin: false })
                                        }
                                    }}
                                >
                                    <Option value={1}> Dueño </Option>
                                    <Option value={2}> Vendedor/Cajero </Option>
                                    <Option value={3}> Cliente </Option>
                                </Select>
                            </Form.Item>
                            {
                                this.state.admin || this.state.usuario.tipo === 2 ? (
                                    <Form.Item
                                        label="Permisos"
                                        name="permisos"
                                    >
                                        <Select mode="multiple" placeholder="Permisos">
                                            {this.state.option.map((option, index) => (
                                                <Option key={index} value={option.value} >
                                                    {option.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }

                            {
                                this.state.admin || this.state.usuario.tipo === 2 ? (
                                    <Form.Item
                                        name="vendedor"
                                        valuePropName={'checked'}
                                        onChange={() => this.setState({ vendedor: true })}
                                        checked={this.state.vendedor}
                                    >
                                        <Checkbox >Vendedor</Checkbox>
                                    </Form.Item>
                                ) : null
                            }
                            {
                                this.state.vendedor || this.state.usuario.codigo_promocional ? (
                                    <Form.Item
                                        name="codigo_promocional"
                                        label="Codigo"
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                ) : null
                            }*/}
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center mt-1">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function (props) {

    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        className="modal-zeus"
    >
        <Row className="mb-1">
            <Col span={24} className="center">
                <Title level={2}>Usuario</Title>
            </Col>
        </Row>
        <ModalUsuarios  {...props} />
    </Modal>

}