import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, } from 'antd';

const { Title, Text } = Typography;
const moment = require('moment');
const axios = require('axios').default;






/**
 *
 *
 * @class ModalTransaccionDetalle
 * @extends {Component}
 */
class ModalTransaccionDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            transaccion: {}
        }
    }

    ModalClasificadores = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.transaccion_id) {
            this.getTransaccion()
        }
    }

    /**
    * @method getTransaccion
    * @description Obtiene la informacion del transaccion
    */
    getTransaccion = () => {
        this.setState({ loading: true })
        axios.get('/transaccion', {
            params: {
                id: this.props.transaccion_id,
                movimiento: true
            }
        }).then(response => {
            console.log("response", response);
            this.setState({ transaccion: response.data.data })
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Cierra la transaccion
    */
    onFinish = () => {
        this.props.onCancel()
    }


    render() {

        const { transaccion } = this.state

        
        return (
            <Form
                layout="vertical"
                ref={this.ModalClasificadores}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Concepto"
                            >
                                <Text>{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Descripción"
                            >
                                <Text>{transaccion.descripcion}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Fecha"
                            >
                                <Text>{moment(transaccion.createdAt).format('DD-MM-YYYY')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Tipo"
                            >
                                <Text>{transaccion.tipo === 1 ? 'Compra' : 'Venta'}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Estatus"
                            >
                                {transaccion.estatus === 1 ? <Text type="warning">No Confirmada</Text> : <Text type="success">Confirmada</Text>}
                            </Form.Item>
                        </Col>
                        {!transaccion.venta_id ?
                            <>
                                <Col xs={24} md={12} >
                                    <Form.Item
                                        label="Hacienda"
                                    >
                                        <Text>{transaccion.hacienda_id?.nombre}</Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} >
                                    <Form.Item
                                        label="Plantas"
                                    >
                                        <Text>{transaccion.inversion_id?.cantidad}</Text>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12} >
                                    <Form.Item
                                        label="Referencia"
                                    >
                                        <Text>{transaccion.inversion_id?.folio}</Text>
                                    </Form.Item>
                                </Col>
                            </>
                            : null}
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Monto"
                            >
                                <Text>$ {transaccion.monto?.toMoney && transaccion.monto?.toMoney(true)} {transaccion.moneda}</Text>
                            </Form.Item>
                        </Col>
                        {
                            transaccion.cliente_id ?
                                <Col xs={24} lg={12} >
                                    <Form.Item
                                        label="Comprador"
                                    >
                                        <Text>{transaccion.cliente_id?.nombre} {transaccion.cliente_id?.apellido_paterno}  {transaccion.cliente_id?.apellido_materno}</Text>
                                    </Form.Item>
                                </Col> 
                            : null
                        }
                        {transaccion.pos ?
                            <Col xs={24} lg={12} >
                                <Form.Item
                                    label="Usuario"
                                >
                                    <Text>{transaccion.usuario_id?.nombres} {transaccion.usuario_id?.apellido_paterno} {transaccion.usuario_id?.apellido_materno}</Text>
                                </Form.Item>
                            </Col> : null}
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Comprobantes"
                            >
                                {
                                    transaccion.comprobantes?.map(doc => <a className="mr-1" target="_blank" href={`${axios.defaults.baseURL}/upload/${doc.filename_server}`}>{doc.filename}</a>)
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                   
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Transacción</Title>
        </div>
        <ModalTransaccionDetalle {...props} />
    </Modal>

}