import React from 'react'
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Transacciones from '../../Components/Admin/Transacciones/Transacciones'
import Inversiones from '../../Components/Admin/Inversiones/Inversiones';


/**
 * @export
 * @function RouterInversiones
 * @extends {Component}
 */
export default function RouterInversiones(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Inversiones {...props}  />} />
            <Route path=":inversion_id/transacciones" element={<Transacciones   pdf={true}  {...props}  />} />
        </Routes>
    )
}

