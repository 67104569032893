import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, message, Space, Popconfirm, Popover } from 'antd';
import axios from 'axios';
import { IconCategorias, IconBtnEdit, IconBtnDelete } from "../../Widgets/Iconos";
import { QuestionCircleOutlined } from "@ant-design/icons";
import ModalReventa from "./ModalReventa";


const { Content } = Layout;
const { Title } = Typography;
const moment = require('moment')



/**
 *
 *
 * @export
 * @class Reventa
 * @extends {React.Component}
 */
export default class Reventa extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			reventas: [],
			reventa: undefined,
			page: 1,
			total: 0,
			search: '',
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getReventas()
	}



	/**
	* @method getInversiones
	* @description Obtiene la lista de inversiones del cliente
	*/
	getReventas = (page = this.state.page) => {
		this.setState({ loading: true })
		axios.get('/customer/reventas', {
			params: {
				page: page,
				limit: 10,
				estatus: 1
			}
		}).then(response => {

			console.log("response reventas", response)

			this.setState({
				reventas: response.data.data,
				page: response.data.page,
				total: response.data.total,
			})

		}).catch(error => {


		}).finally(() => this.setState({ loading: false }))
	}

	render() {


		return (
			<Content className="hm-layout-content layout-dashboard" >
				<PageHeader className="site-page-header" ghost={false} title="Revender">
				</PageHeader>
				<Row gutter={[18, 18]} style={{ marginTop: '2rem', marginLeft: '1rem' }}>
					<Title className="title-cyan zeus-title" level={3}>Mis Reventas</Title>
				</Row>
				<List
					loading={this.state.loading}
					className="component-list"
					itemLayout="horizontal"
					dataSource={this.state.reventas}
					locale={{ emptyText: "Sin Inversiones " }}
					pagination={{
						current: this.state.page,
						pageSize: 10,
						total: this.state.total,
						position: 'bottom',
						className: "flex-left",
						onChange: (page) => this.getReventas(page),
						hideOnSinglePage: true
					}}
					renderItem={({ _id, precio_reventa, fecha, hacienda_id, cantidad, estatus, moneda, cantidad_vendida, cantidad_restante }) => (
						<List.Item className="component-list-item">
							<Card className="card-list hover">
								<Row className="width-100 ">
									<Col className="center" xs={12} md={4}>
										Venta de {cantidad || 0} Planta(s)
									</Col>
									<Col className="center" xs={12} md={4}>
										<Popover
											overlayStyle={{
												//boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
											}}
											content={
												<>
													{cantidad ?? 0} plantas en total. <br />
													{cantidad_restante ?? 0} para reventa. <br />
													{cantidad_vendida ?? 0} plantas vendidas. <br />
												</>
											}>
											{cantidad_vendida} / {cantidad} planta{cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
										</Popover>
									</Col>
									<Col className="center" xs={12} md={4}>
										{hacienda_id.nombre || 'N/A'}
									</Col>
									<Col className="center" xs={10} md={4}>
										{moment(fecha).format('DD-MM-YYYY')}
									</Col>
									<Col className="center" xs={10} md={4}>
										$ {precio_reventa?.toMoney && precio_reventa?.toMoney(true)} {moneda}
									</Col>
									<Col xs={24} md={4} className="center">
										<Space wrap>
											<Button
												type="primary"
												disabled={estatus !== 3}
												className='btn-secondary center'
												icon={<IconBtnEdit />}
												onClick={() => {
													this.setState({ modal_revender: true, reventa: _id })
												}}
											/>

											<Popconfirm
												placement="topRight"
												title="¿Deseas eliminar esta venta?"
												disabled={cantidad_vendida > 0 ? true : false}
												onConfirm={() => axios.delete('/reventa', { params: { id: _id } }).then((response) => {
													message.success("Reventa eliminada correctamente")
													this.getReventas()
												})
													.catch((error) => {
														message.error("Hubo un error al eliminar la venta");
														this.getReventas();
													})
												}
												okText="Si"
												cancelText="No"
											>
												<Button type="primary" disabled={cantidad_vendida > 0 ? true : false} className='btn-delete center' danger title="Eliminar" icon={<IconBtnDelete />} />
											</Popconfirm>
										</Space>
									</Col>

								</Row>
							</Card>
						</List.Item>
					)}
				/>
				<ModalReventa
					visible={this.state.modal_revender}
					reventa={this.state.reventa}
					onClose={
						() => {
							this.setState({ modal_revender: false, reventa: undefined })
							this.getReventas(this.state.page)
						}
					}
				/>
			</Content>
		)
	}
}