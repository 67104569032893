import React, {createRef} from 'react'

import '../../Styles/Modules/video.scss'
import { Modal, Skeleton } from 'antd'
import { VideoCameraOutlined } from '@ant-design/icons'

class Video extends React.Component{

    constructor(props){
        super(props)

        this.state={
            is_playing: false
        }
    }

    videoRef = createRef()

    componentDidMount(){
        if(this.props.autoplay){
            this.setState({is_playing: true})
            this.videoRef.current?.play()
        }
    }

    toggle = () => {
        if(this.state.is_playing){
            this.videoRef.current?.pause()
        }else{
            this.videoRef.current?.play()
        }

        this.setState({is_playing: !this.state.is_playing})
    }

    render(){

        if(this.props.is_mobile) return (
            <>
                <div className='video-mobile-cover' onClick={() => this.toggle()}>
                    <img src="/img/zeus-logo-white-all.svg" alt="zeus logo y boton" className='video-mobile-button'/>
                </div>
                <Modal
                    open={true}
                    footer={null}
                >
                    <section className='video'>
                        <video className='video-player' onPause={() => this.toggle()} ref={this.videoRef} width={'100%'} controls={this.state.is_playing}> {this.props.children} </video>
                        { !this.state.is_playing && (
                                <div className='video-cover' onClick={() => this.toggle()}>
                                    <img src="/img/zeus-logo-white-all.svg" alt="zeus logo y boton" className='video-button'/>
                                </div>
                            )}
                    </section>
                </Modal>
            </>
        )

        return(
            <section className='video'>
                <video className='video-player' onPause={() => this.toggle()} ref={this.videoRef} width={'100%'} controls={this.state.is_playing}> {this.props.children} </video>
                { !this.state.is_playing && (
                        <div className='video-cover' onClick={() => this.toggle()}>
                            <img src="/img/zeus-logo-white-all.svg" alt="zeus logo y boton" className='video-button'/>
                        </div>
                    )}
            </section>
        )
    }
}

class VideoPlayer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            isMobile: undefined,
            isOpen: false
        }
    }

    componentDidMount() {
        const isMobile = (window.innerWidth <= 768)
        this.setState({isMobile})
    }

    render(){
        if(this.state.isMobile){
            return (
                <>
                    <div className='video-mobile' style={{backgroundImage: `url(${this.props.cover})`}} onClick={() => this.setState({isOpen: true})}>
                        <img src="/img/zeus-logo-white-all.svg" alt="zeus logo y boton" className='video-button'/>
                    </div>
                    <Modal
                        className='modal-video'
                        wrapClassName='wrap-modal'
                        width={600}
                        open={this.state.isOpen}
                        onCancel={() => this.setState({isOpen: false})}
                        footer={null}
                        closable={false}
                        destroyOnClose
                    >
                        <Video autoplay>
                            {this.props.children}
                        </Video>
                    </Modal>
                </>
            )
        }

        if(!this.state.isMobile) return <Video>{this.props.children}</Video>

        return <Skeleton.Node active={true}>
        <VideoCameraOutlined
          style={{
            fontSize: 40,
            color: '#bfbfbf',
          }}
        />
      </Skeleton.Node>
    }
}

export default VideoPlayer