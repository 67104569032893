import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Modal, Card, Switch } from 'antd'
import axios from 'axios';
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import User from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
//Componentes
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import { IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos';
import { WarningFilled } from '@ant-design/icons';

//Modales
import ModalCliente from './ModalCliente';
import ModalDetalle from './ModalClienteDetalle';
//import ModalUsuarios from './ModalUsuarios';

const { Content } = Layout;
const { Text } = Typography
const { confirm } = Modal
/**
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Usuarios extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        let { page, limit } = this.props.params
        this.state = {
            clientes: [],
            page: page ?? 1,
            limit: limit ?? 10,
            total: 0,
            usuario: undefined,
            search: this.props.search,

        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientesReventas();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.setState({ search: this.props.search }, () => this.getClientesReventas())
        }
    }

    /**
    * @method getUsuarios
    * @param {number} page Numero de la pagina listada
    * @description Obtiene ls lista de usuarios
    */
    getClientesReventas = ({
        page = this.state.page,
        limit = this.state.limit,
        search = this.state.search,
    } = this.state) => {

        let urlParams = { page, limit }
        // if (search)
        //     urlParams["search"] = search        
        // this.props.navigate(`${this.props.location.pathname}?${(new URLSearchParams(urlParams)).toString()}`)  

        this.setState({ loading: true, page, limit })
        axios.get('/crm/clientes-reventa', {
            params: {
                page,
                limit,
                search,
            }
        }).then(response => {
            console.log("RESPONSE CLIENTES REVENTA", response)
            this.setState({
                clientes: response.data.data,
                page: response.data.page,
                total: response.data.total,
                limit: response.data.limit,
            })
        }).catch(error => {
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    toggleClientes = (e) => {
        this.setState({ clientes: e }, () => this.getUsuarios())
    }




    render() {


        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Clientes con Reventas Activas"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Clientes" }}
                            dataSource={this.state.clientes}
                            pagination={{
                                onChange: (page, limit) => this.getClientesReventas({ page, limit }),
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                //showSizeChanger: true,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            header={<Row className="width-100 " >
                                <Col xs={24} md={5} className="center">
                                    <Text strong>Nombres</Text>
                                </Col>
                                <Col xs={24} md={6} className="center">
                                    <Text strong>Apellidos</Text>
                                </Col>
                                <Col xs={24} md={5} className="center">
                                    <Text strong>Email</Text>
                                </Col>
                                <Col xs={24} md={4} className="center">
                                    <Text strong>Teléfono</Text>
                                </Col>


                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col xs={24} md={5} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} md={6} className="center">
                                                <Text >{item.apellido_paterno} {item.apellido_materno}</Text>
                                            </Col>
                                            <Col xs={24} md={5} className="center">
                                                <Text >{item.email}</Text>
                                            </Col>
                                            <Col xs={24} md={4} className="center">
                                                <Text >{item.telefono}</Text>
                                            </Col>

                                            <Col xs={24} md={4} className="center">
                                                <Space wrap>
                                                    <Link to={`/admin/crm/clientes/detalle/${item._id}`} >
                                                        <Button type='primary' disabled={!this.props.verClienteDetalles} className='btn-primary' icon={<IconEye />} />
                                                    </Link>
                                                    <Button
                                                        type='primary'
                                                        disabled={!this.props.editarClientes}
                                                        className='btn-secondary'
                                                        icon={<IconBtnEdit />} onClick={() => this.setState({ modalDetalle: true, cliente_id: item._id })} />
                                                    <Button type="primary" disabled={!this.props.eliminarClientes} danger
                                                        className='btn-delete' title="Eliminar" icon={<IconBtnDelete />}
                                                        onClick={() => {
                                                            confirm({
                                                                title: "¿Quieres eliminar este cliente?",
                                                                icon: <WarningFilled />,
                                                                content: "Se eliminará todo lo relacionado al cliente (inversiones, transacciones, etc.)",
                                                                okText: "Eliminar",
                                                                cancelText: "Cancelar",
                                                                onOk: () => axios.delete('/clientes/delete', { params: { cliente_id: item._id } })
                                                                    .then((response) => {
                                                                        message.success(response?.data?.message)
                                                                        this.getClientesReventas()
                                                                    })
                                                                    .catch((error) => {
                                                                        message.error(error?.response?.data?.message);
                                                                        this.getClientesReventas()
                                                                    }),
                                                                onCancel: () => { }
                                                            })
                                                        }}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                </Spin>
                <ModalCliente
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        this.getClientesReventas()
                    }}

                />

                <ModalDetalle
                    visible={this.state.modalDetalle}
                    cliente_id={this.state.cliente_id}
                    onCancel={() => {
                        this.setState({ modalDetalle: false, cliente_id: undefined })
                        this.getClientesReventas()
                    }}
                />
                <FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modalUsuarios: true, usuario: undefined })} />

                {/* <ModalUsuarios
                    visible={this.state.modalUsuarios}
                    usuario={this.state.usuario}
                    onClose={() => {
                        this.setState({ modalUsuarios: false, usuario: undefined })
                        this.getUsuarios()
                    }}
                    clientes={this.state.clientes}
                /> */}

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();
    const location = useLocation()
    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearClientes: ["clientes", "create"],
        editarClientes: ["clientes", "edit"],
        eliminarClientes: ["clientes", "delete"],
        verClienteDetalles: ["clientes", "detalles"],
        asignarVendedor: ["clientes", "assign-vendedor"]
    })

    return <Usuarios {...props} {...permisos} navigate={navigate} params={params} location={location} />
}