
import React, { Component } from "react";

import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber, DatePicker, Upload, Checkbox, Select } from 'antd';

import { PlusOutlined } from '@ant-design/icons';


// import ColorPicker from '../../../Widgets/ColorPicker';
// import Mapa from '../../../Widgets/Mapa/Mapa';
// import Busqueda from '../../../Widgets/Busqueda';
// import * as turf from "@turf/turf";

const { Title } = Typography
const { Option } = Select

const { RangePicker } = DatePicker


const moment = require('moment');
const axios = require('axios').default;


/**
 *
 *
 * @class ModalNomina
 * @extends {Component}
 */
class ModalNomina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            imagenes: [],
            usuarios: [],
            usuario_id: undefined,
        }
    }

    modalRef = React.createRef()

    componentDidMount() {
    }


    /**
     *
     *
     * @param {*} values
     * @memberof ModalNomina
     * @method add
     * @description Agregamos una nueva nómina 
     */
    add = (values) => {
        axios.post("/nominas", {
            fecha: values.fechas[0],
            fecha_fin: values.fechas[1]
        })
            .then(e => {
                message.success("Nómina guardada")
                this.props.onClose()
            })
            .catch(e => {
                message.error("Nómina guardada")
            })

    }

    /**
     *
     *
     * @param {*} values
     * @memberof ModalNomina
     * @method finish
     * @description Se eejcuta cuando se concluye el formulario 
     */
    finish = (values) => { 
        this.add(values)
    }

    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.finish}
                className="pd-1"
                initialValues={{
                    fechas: [
                        moment(),
                        moment().add(7, "day")
                    ]
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[14, 24]}>
                        <Col span={24} >
                            <Form.Item
                                label="Fechas"
                                name="fechas"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccion el plazo"
                                }, ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                        if(value){
                                            let [start, end] = value
                                            
                                            console.log("Plazo", end.diff(start, 'days'));

                                            if(end.diff(start, 'days') !== 7  && end.diff(start, 'days') !== 15){
                                                return Promise.reject(new Error("El plazo tiene que ser semanal o quincenal"))
                                            }
                                        }
                                        return Promise.resolve()
                                    }
                                })]}
                                className="w-100"
                            >
                                <RangePicker className="w-100" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
        
    >
        <div className="center">
            <Title level={3}>{'Registar'} Nómina-</Title>
        </div>
        <ModalNomina {...props} />
    </Modal>

}