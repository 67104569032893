import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Modal, Card, Switch } from 'antd'
import axios from 'axios';
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

//Componentes
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import { IconBtnDelete, IconBtnEdit, IconEye, IconMail } from '../../Widgets/Iconos';

//Modales
import ModalUsuarios from './ModalUsuarios';

const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Usuarios extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        let { page, limit } = this.props.params
        this.state = {
            usuarios: [],
            page: page ?? 1,
            limit: limit ?? 10,
            total: 0,
            usuario: undefined,
            search: this.props.search,
            clientes: false
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getUsuarios();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.setState({ search: this.props.search }, () => this.getUsuarios())
        }
    }

    /**
    * @method getUsuarios
    * @param {number} page Numero de la pagina listada
    * @description Obtiene ls lista de usuarios
    */
    getUsuarios = ({
        page = this.state.page, 
        limit = this.state.limit, 
        search = this.state.search,
    } = this.state) => {
        
        let urlParams = { page, limit }
        if (search)
            urlParams["search"] = search        
        this.props.navigate(`${this.props.location.pathname}?${(new URLSearchParams(urlParams)).toString()}`)  
        
        this.setState({ loading: true, page, limit })
        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
                clientes: this.state.clientes
            }
        }).then(response => {
            this.setState({
                usuarios: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
                limit: response.data.data.perPage,
            })
        }).catch(error => {
            message.error(error.response.data.message ?? 'Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    toggleClientes = (e) => {
        this.setState({ clientes: e }, () => this.getUsuarios())
    }

    /**
     *
     *
     * @param {string} email correo de destino
     * @memberof Usuarios
     * @method sendEmail
     * @description Envia un correo con el link para cambiar su contraseña
     */
    sendEmail = (email) => {
        this.setState({loading: true})
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }


    render() {


        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Usuarios"
                        extra={[<Switch unCheckedChildren="Usuarios Administradores / Gestores" checkedChildren="Usuarios de Clientes" onChange={(e) => this.toggleClientes(e)}/> ]}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Usuarios" }}
                            dataSource={this.state.usuarios}
                            pagination={{
                                onChange:(page, limit) => this.getUsuarios({page, limit}),
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            header={<Row className="width-100 " >
                                <Col xs={24} md={5} className="center">
                                    <Text strong>Nombres</Text>
                                </Col>
                                <Col xs={24} md={6} className="center">
                                    <Text strong>Apellidos</Text>
                                </Col>
                                <Col xs={24} md={5} className="center">
                                    <Text strong>Email</Text>
                                </Col>
                                <Col xs={24} md={4} className="center">
                                    <Text strong>Teléfono</Text>
                                </Col>
                               

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col xs={24} md={5} className="center">
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} md={6} className="center">
                                                <Text >{item.apellido_paterno} {item.apellido_materno}</Text>
                                            </Col>
                                            <Col xs={24} md={5} className="center">
                                                <Text >{item.email}</Text>
                                            </Col>
                                            <Col xs={24} md={4} className="center">
                                                <Text >{item.telefono}</Text>
                                            </Col>
                                           
                                            <Col xs={24} md={4} className="center">
                                                <Space wrap>
                                                    <Popconfirm 
                                                        placement="topRight"
                                                        title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Sí" cancelText="No"
                                                        onConfirm={() => this.sendEmail(item.email)}
                                                    >
                                                        <Button type="primary" className="center" title="Recuperar Contraseña" icon={<IconMail />} />
                                                    </Popconfirm> 

                                                    

                                                    <Button 
                                                        type="primary" 
                                                        className='btn-secondary center' 
                                                        icon={<IconBtnEdit />} 
                                                        onClick={() => {
                                                            this.setState({ modalUsuarios: true, usuario: item._id })
                                                        }}
                                                    />
                                                
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este usuario?"
                                                        onConfirm={() => axios.delete('/usuarios/delete', { params: { id: item._id } }).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getUsuarios()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);
                                                                this.getUsuarios();
                                                            })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" className='btn-delete center' danger title="Eliminar" icon={<IconBtnDelete />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                </Spin>
                <FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modalUsuarios: true,usuario:undefined })} />

                <ModalUsuarios
                    visible={this.state.modalUsuarios}
                    usuario={this.state.usuario}
                    onClose={() => {
                        this.setState({ modalUsuarios: false, usuario: undefined })
                        this.getUsuarios()
                    }}
                    clientes={this.state.clientes}
                />

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();
    const location = useLocation()

    return <Usuarios {...props} navigate={navigate} params={params} location={location}/>
}