import React, { Component, useState } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin,  } from 'antd';

//css
import './../../Styles/Global/modales.css'
import FormatedInput from "../Widgets/Inputs/FormatedInput";

const { Title, Text } = Typography;
const axios = require('axios').default;





export default function (props) {
    const { visible = false, onClose = () => { } } = props

    const [registro, setRegistro] = useState(false)
    const [loading, setLoading] = useState(false)


    /**
     * @memberof Registro
     *
     * @method onFinish
     * @description Envia un correo para recuperar la contraseña
     **/
    const onFinish = (value) => {
        setLoading(true)
        axios.put("/password/recovery", { email: value.email })
            .then(res => {
                Modal.success({
                    title: 'Restablece tu contraseña.',
                    content: '¡El correo ha sido enviado! Revisa tu bandeja de entrada.'
                })
                props.onClose()
            })
            .catch(res => {
                
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que sea el mismo con el que te registraste.'
                });
            }).finally(()=>setLoading(false))
    } 

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={450}
        className="modal-border"
    >
        <Row className="mb-1">
            <Col span={12} className="flex-left">
                <img  src="./img/zeus_logo.svg" width="150" />
            </Col>
        </Row>
        <Spin spinning={loading}>
            <Form
                layout={"vertical"}
                onFinish={onFinish}
                style={{ minHeight: '480px'}}
            >
                <Row justify="center mb-3">
                    <Col span={24} className="flex-column mb-3 mt-3">
                        <Title level={3} className="modal-title">Recuperación de contraseña</Title>
                        <Text className="modal-subtitle">Ingresa tu correo electrónico, para recuperar tu contraseña</Text>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                              {
                                type: 'email',
                                message: 'No es un email valido',
                              },
                              {
                                required: true,
                                message: 'Ingresa un email',
                              },
                            ]}
                        >
                            <FormatedInput trim />
                        </Form.Item>

                    </Col>
                    <Col span={18}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="mt-2" block >Recuperar contraseña </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    </Modal>

}