import React, { Component, useContext } from "react";
import {
  Button,
  Avatar,
  Popconfirm,
  Spin,
  PageHeader,
  Layout,
  message,
  Space,
  Row,
  Col,
  Typography,
  List,
  Card,
  Image,
} from "antd";
import axios from "axios";
import { Link } from "react-router-dom";
import { IconBtnDelete, IconBtnEdit, IconEye } from "../../Widgets/Iconos";
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import ModalHacienda from "./Modales/ModalHacienda";
import { EditOutlined } from "@ant-design/icons";

import { User } from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class ListaHaciendas
 * @extends {Component}
 * @description Vista de ListaHaciendas
 */
class ListaHaciendas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      drawerHacienda: false,

      haciendas: {
        data: [],
        page: 1,
        limit: 20,

        pages: 0,
        total: 0,

        search: null,
      },
    };
  }

  /**
   *
   * @method componentDidMount
   * @description Obtenemos las haciendas en cuestión
   */
  componentDidMount() {
    axios.defaults.headers.common["Authorization"] =
      sessionStorage.getItem("token");
    this.getHaciendas();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      this.setState({ search: this.props.search }, () =>
        this.getHaciendas({ search: this.props.search })
      );
    }
  }

  /**
   *
   * @param {*} {page, limit, search} Son los filtros y condicionales de la paginación
   * @param {*} { haciendas } Obtiene el objeto haciendas sin destructurar, ademas de cualquier otro elemento del state.
   * @returns Promise Axios de resultados
   */
  getHaciendas = (
    {
      page = this.state.haciendas.page,
      limit = this.state.haciendas.limit,
      search = this.state.haciendas.search,
    } = this.state.haciendas,
    { haciendas } = this.state
  ) => {
    haciendas.page = page;
    haciendas.limit = limit;
    haciendas.search = search;
    this.setState({ haciendas, loading: true });
    return axios
      .get("/haciendas", {
        params: { page, limit, search },
      })
      .then(({ data }) => {
        data = data.data;
        haciendas.data = data.data;
        haciendas.total = data.total;
        haciendas.pages = data.pages;
        this.setState({ haciendas });
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    return (
      <>
        <Spin spinning={false}>
          <PageHeader className="admin-page-header" title="Haciendas" />
          <Content className="admin-content content-bg pd-1">
            <List
              loading={this.state.haciendas.loading}
              className="component-list "
              size="large"
              itemLayout="horizontal"
              dataSource={this.state.haciendas.data}
              header={
                <Row className=" width-100 pr-1 pl-1 ">
                  <Col xs={2} md={1} className="center">
                    Color
                  </Col>
                  <Col xs={10} md={3} className="center">
                    Imagén
                  </Col>
                  <Col xs={12} md={3} className="center">
                    Nombre
                  </Col>
                  <Col xs={24} md={4} className="center">
                    Descripción
                  </Col>
                  <Col xs={8} md={3} className="center">
                    Plantas
                  </Col>
                  <Col xs={8} md={3} className="center">
                    Plantas Disponibles
                  </Col>
                  <Col xs={8} md={3} className="center">
                    Venta
                  </Col>
                  <Col xs={24} md={4} className="center">
                    Acciones
                  </Col>
                </Row>
              }
              renderItem={(item) => (
                <List.Item className="component-list-item">
                  <Card className="card-list">
                    <Row className="width-100" gutter={[0, 12]}>
                      <Col xs={2} md={1} className="center">
                        <Avatar style={{ backgroundColor: item.color }} />
                      </Col>
                      <Col
                        xs={10}
                        md={3}
                        className="center"
                        style={{ padding: "0 1rem" }}
                      >
                        {item.imagenes[0]?.filename ? (
                          <Image
                            preview
                            src={
                              axios.defaults.baseURL +
                              "/upload/" +
                              (item.imagenes[0]?.filename
                                ? item.imagenes[0]?.filename
                                : "default.png")
                            }
                            style={{ height: 60, objectFit: "cover" }}
                          />
                        ) : (
                          <Text>Sin imagen Disponible</Text>
                        )}
                      </Col>
                      <Col xs={12} md={3} className="center">
                        <Text ellipsis strong>
                          {item.nombre}
                        </Text>
                      </Col>
                      <Col xs={24} md={4} className="center">
                        <Text ellipsis strong>
                          {item.descripcion}
                        </Text>
                      </Col>
                      <Col xs={8} md={3} className="center">
                        <Text ellipsis strong>
                          {item.plantas && item.plantas.toMoney(true)}
                        </Text>
                      </Col>
                      <Col xs={8} md={3} className="center">
                        <Text ellipsis strong>
                          {item.disponible?.toMoney &&
                            item.disponible.toMoney(true)}
                        </Text>
                      </Col>
                      <Col xs={8} md={3} className="center">
                        <Text className="text-cyan">
                          $ {item.venta?.toMoney && item.venta.toMoney(true)}{" "}
                        </Text>
                      </Col>
                      <Col xs={24} md={4} className="center">
                        <Space direction="horizontal" wrap>
                          <Link
                            to={`/admin/haciendas/${
                              item.activo ? "detalle" : "completa"
                            }/${item._id}`}
                          >
                            <Button
                              disabled={!this.props.transacciones}
                              icon={<IconEye />}
                              type="primary"
                              className="btn-primary"
                              title="Ver Detalle"
                            ></Button>
                          </Link>
                          <Button
                            icon={<EditOutlined />}
                            type="primary"
                            className="btn-secondary"
                            disabled={!this.props.editar}
                            onClick={(e) => {
                              this.setState({
                                drawerHacienda: true,
                                hacienda_id: item._id,
                              });
                            }}
                          />
                          <Popconfirm
                            placement="topRight"
                            className="btn-delete"
                            title="¿Deseas eliminar esta hacienda?"
                            onConfirm={(e) => {
                              axios
                                .delete("/hacienda/delete", {
                                  params: { _id: item._id },
                                })
                                .then((response) => {
                                  message.success("Hacienda Eliminada");
                                  this.getHaciendas();
                                })
                                .catch((error) => {
                                  let msj = "Error al eliminar la hacienda";
                                  if (error.response.status === 403) {
                                    msj = error.response.data.message;
                                  }
                                  message.error(msj);
                                  this.getHaciendas();
                                });
                            }}
                            onCancel={(e) => {
                              e.stopPropagation();
                            }}
                            okText="Sí"
                            cancelText="No"
                            disabled={!this.props.delete}
                          >
                            <Button
                              type="primary"
                              danger
                              icon={
                                <IconBtnDelete
                                  style={{ color: "currentcolor" }}
                                />
                              }
                              title="Eliminar"
                            />
                          </Popconfirm>
                        </Space>
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              )}
              pagination={{
                onChange: (page, limit) => this.getHaciendas({ page, limit }),
                current: this.state.haciendas.page,
                pageSize: this.state.haciendas.limit,
                total: this.state.haciendas.total,
              }}
            />
          </Content>
          {this.props.create && (
            <FloatingButton
              title="Nuevo registro"
              onClick={() =>
                this.setState({ drawerHacienda: true, hacienda_id: undefined })
              }
            />
          )}

          <ModalHacienda
            visible={this.state.drawerHacienda}
            onCancel={() => {
              this.setState({ drawerHacienda: false, hacienda_id: null });
              this.getHaciendas();
            }}
            onSuccess={() => {
              this.setState({ drawerHacienda: false });
              this.getHaciendas();
            }}
            id={this.state.hacienda_id}
          />
        </Spin>
      </>
    );
  }
}

export default function (props) {
  const user = useContext(User);

  const permissions = usePermissions(user?.rol_id?.permisos, {
    editar: ["haciendas", "edit"],
    delete: ["haciendas", "delete"],
    create: ["haciendas", "create"],
    transacciones: ["haciendas", "transacciones", "access"],
  });

  return <ListaHaciendas {...props} {...permissions} />;
}
