import React, { useContext } from "react";
import { PageHeader, Row, Col, Button, Card, Avatar, List, Spin, Space, Typography, Popover, message, Popconfirm, Tag } from 'antd';
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, EditOutlined, FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
//componentes
import { IconArrowBack, IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import useSearchParams from '../../../Hooks/SearchParams';
//modales

import { User } from "../../../Hooks/Logged";


const moment = require('moment')
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Transacciones
 * @extends {React.Component}
 */
class Transacciones extends React.Component {

	static contextType = User

	constructor(props) {
		super(props)
		let { page, limit, filters, search, sort } = this.props.params
		this.state = {

			loading: false,

			transacciones: {
				loading: false,
                data: [],
                page: page ?? 1,
                limit: limit ?? 10,
                filters: filters || [],
                total: 0,
                sort: sort || {},
            },
		}
	}

	drawerFiltros = React.createRef()

	componentDidMount() {

		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getHistorialAuthorize();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.search !== this.props.search) {
			this.setState({ search: this.props.search }, () => this.getHistorialAuthorize())
		}
	}

	/**
	* @method getHistorialAuthorize
	* @param {number} page - Numero de la pagina listada
	* @description Obtiene las transacciones realizadas
	*/
	getHistorialAuthorize = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.state.search,
        filters = this.state.transacciones.filters,
        sort = this.state.transacciones.sort,
    } = this.state.transacciones, { transacciones } = this.state
    ) => {

		const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)

		this.props.setParams(params)

		let clearCheckbox = (page === 1)

		this.setState({ transacciones: { ...this.state.transacciones, page, limit, search, filters, sort }, clearCheckbox, loading: true })

		axios.get('/authotizenet/historial', {
			params: {
				page: transacciones.data.length < 1 && page !== 1 ? 1 : page,
                limit: transacciones.data.length < 1 && page !== 1 ? limit * page : limit,
                search: this.props.search,
                filters,
                sort
			}
		}).then(({ data }) => {

			console.log("Authorize Historial",data)
			this.setState({
				transacciones: {
					...this.state.transacciones,
					data: data.data,
					total: data.total,
					search
				}
			})

		}).catch(error => {
			console.log(error)
			message.error('Error al obtener la Información')

		}).finally(() => this.setState({ loading: false, clearCheckbox: false }))
	}


	/**
	 *
	 *
	 * @memberof Transacciones
	 * @method back
	 * @description Realiza la accion de volver a la anterior pagina
	 */
	back = () => {
		this.props.navigate(-1);
	}
	render() {

		const { transacciones } = this.state

		return (
			<Spin spinning={false}>
				<PageHeader
					className="admin-page-header"
					title="Transacciones de Authorize"

				/>
				
				<List
					loading={transacciones.loading}
					className="component-list pd-1"
					itemLayout="horizontal"
					dataSource={transacciones.data}
					locale={{ emptyText: "Sin Transacciones" }}
					pagination={{
						current: transacciones.page,
						pageSize: transacciones.limit,
						total: transacciones.total,
						pageSizeOptions: [10, 20, 50, 100, 500, 1000],
						position: 'bottom',
						className: "flex-left ml-1",
						onChange: (page,limit) => this.getHistorialAuthorize({page,limit})
					}}
					header={<Row className=" width-100 pl-1 pr-1 ">
						<Col xs={12} md={5} className="center">
							Cliente
						</Col>
						<Col xs={12} md={5} className="center">
							Inversion
						</Col>
						<Col xs={12} md={5} className="center">
							Fecha
						</Col>
						<Col xs={12} md={5} className="center">
							Concepto / Respuesta
						</Col>
						<Col xs={24} md={4} className="center">
							Monto
						</Col>
					</Row>
					}
					renderItem={item => (
						<List.Item className="component-list-item">
							<Card className="card-list hover">
								<Row className="width-100 " gutter={[0, 12]}>
									<Col className="center" xs={12} md={5}>
										{item.cliente_id?.nombre} {item.cliente_id?.apellido_paterno} {item.cliente_id?.apellido_materno}  {item.cliente_id?.email}
									</Col>
									<Col className="center" xs={12} md={5}>
										
										{item.inversion_id?.folio ? item.inversion_id.folio: "N/A"}
									</Col>

									<Col className="center" xs={12} md={5}>
										{moment(item.createdAt).format('DD-MM-YYYY')}
									</Col>
									<Col className="center" xs={12} md={5}>
										<p>{item.transaccion_id?.concepto}</p>
										{item.response?.messages.message?.map(e => <p>{e.code} {e.text}</p>)}
									</Col>
									<Col xs={24} md={4} className="center">
										{item.transaccion_id?.monto} {item.transaccion_id?.moneda}
									</Col>

								</Row>
							</Card>
						</List.Item>
					)}
				/>

				

				

				
			</Spin>
		)
	}
}


export default function (props) {

	const user = useContext(User)
	const navigate = useNavigate()

	const [params, setParams] = useSearchParams();

	

	
	return <Transacciones  {...props} params={params} setParams={setParams} navigate={navigate} />
}