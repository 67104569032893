import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Spin, Typography,  Select, Row, Col, Divider, InputNumber, Upload, Empty, DatePicker} from 'antd';
import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import FormatedInput from "../../Widgets/Inputs/FormatedInput";

const axios = require('axios').default;
const moment = require('moment');


const { Title, } = Typography;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};


/**
 *
 *
 * @export
 * @class FormCliente 
 * @extends {Component}
 */
class FormCliente extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],

            paises: [],
            estados: [],
            vendedores: [],
            pais_id: null
        }
    }

    formRef = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.getCliente()
        
        this.getPaises()
    }

    /**
    * @memberOf FormCliente
    * @method getCliente
    * @param {string} cliente_id Identificador del cliente
    * @description Obtiene la informacion de un cliente
    */
    getCliente = (cliente_id = this.props.cliente_id) => {
        this.setState({loading: true})
        axios.get('/customer/cliente', { params: { cliente_id } })
            .then(({ data }) => {
                console.log("data", data);
                
                const cliente = data.cliente
                const usuario = data.usuario

                cliente.fecha_nacimiento = moment(cliente.fecha_nacimiento)

                this.formRef.current.setFieldsValue({
                    ...usuario,
                    ...cliente
                })
                this.getEstados(cliente.pais_id)
               

            })
            .catch(error => {
                console.log("error", error);
                
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberOf FormCliente
    * @method getPaises
    * @description obtiene una lista de los paises
    */
    getPaises = () => {
        axios.get('/paises')
            .then(response => {
                this.setState({ paises: response.data.data })
            })
            .catch(error => {
                console.log("error", error);
                message.error('Ha ocurrido un error al obtener los paises.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf FormCliente
    * @method getEstados
    * @description obtiene una lista de los estados
    */
    getEstados = (pais_id = this.state.pais_id, clean = false) => {

        if (clean)
            this.formRef.current.setFieldsValue({ estado_id: null })

        axios.get('/estados', {
            params: { pais_id }
        })
            .then(response => {
                this.setState({ estados: response.data.data })
            })
            .catch(error => {
                console.log("error", error);
                message.error('Ha ocurrido un error al obtener los estados.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf FormCliente
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = values => {

        this.updateCliente(values)

    }




    /**
    * @memberOf FormCliente
    * @method updateCliente
    * @param {object} cliente El objeto cliente
    * @description Actualiza la informacion de un cliente
    */
    updateCliente = cliente => {
        this.setState({loading: true})
        axios.post('/customer/cliente', {
            ...cliente,
        }).then(response => {
            message.success('Se ha guardado exitosamente.')
            this.props.onClose()
        })
        .catch(error => {
            console.log("error", error);
            message.error(error.response?.data?.message ?? "Ha ocurrido un error al actualizar la información.")
        })
        .finally(() => this.setState({ loading: false }))
    }


    render() {
        const {  formRef, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Editar Perfil</Title>
                <Form
                    {...formItemLayout}
                    layout="vertical"
                    ref={formRef}
                    initialValues={form}
                    onFinish={this.onFinish}
                    scrollToFirstError={true}
                >
                    <Row className="w-100" gutter={[16, 16]}>
                        <Col span={24}>
                            <Divider orientation="left">Información Personal</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Nombres"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese los nombres del cliente" }
                                ]} >
                                <Input placeholder="Luis Gerardo" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Apellido Paterno"
                                name="apellido_paterno"
                                rules={[
                                    { required: true, message: "Ingrese el apellido paterno del cliente" }
                                ]} >
                                <Input placeholder="Mendez" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Apellido Materno"
                                name="apellido_materno">
                                <Input placeholder="Rodriguez" />
                            </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                            <Divider orientation="left">Información de Contacto</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Teléfono"
                                name="telefono">
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Email',
                                    },
                                ]}
                            >
                                <FormatedInput trim controls={false} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">Dirección</Divider>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Calles"
                                name="calles"
                                rules={[
                                    { required: true, message: "Ingrese las calles" }
                                ]}>
                                <Input placeholder="Calles" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Número"
                                name="numero"
                                rules={[
                                    { required: true, message: "Ingrese el número" }
                                ]}>
                                <Input placeholder="Numero" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Código Postal"
                                name="codigo_postal"
                                rules={[
                                    { required: true, message: "Ingrese el código postal" }
                                ]}>
                                <InputNumber className="w-100" placeholder="Numero" />
                            </Form.Item>
                        </Col>
                        <Form.Item
                            name="fecha_nacimiento"
                            label="Fecha de Nacimiento"
                            className="width-100 "
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu Fecha de Nacimiento',
                                },
                            ]}
                        >
                            <DatePicker className=" width-100 " />
                        </Form.Item>
                        {/* <Form.Item
                            name="identificacion"
                            label="identificacion"
                            className=" width-100 "
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu número identificador',
                                },
                            ]}
                        >
                            <Input  />
                        </Form.Item> */}
                        <Col span={24}>
                            <Form.Item
                                label="Ciudad"
                                name="ciudad"
                                rules={[
                                    { required: true, message: "Ingrese la ciudad" }
                                ]}>
                                <Input placeholder="Numero" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="País"
                                name="pais_id"

                                rules={[
                                    { required: true, message: "Seleccione el pais" }
                                ]}>
                                <Select onSelect={estado_id => this.getEstados(estado_id, true)}>
                                    {this.state.paises.map(({ _id, nombre }) => <Option value={_id}>{nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Estado"
                                name="estado_id"
                                rules={[
                                    { required: true, message: "Seleccione el estado" }
                                ]}>
                                <Select>
                                    {this.state.estados.map(({ _id, nombre }) => <Option value={_id}>{nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col> 
                    </Row>
                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading} >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={600}
            className="modal-zeus"
        >
            <FormCliente {...props} />
        </Modal>
    )
}
