import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, message, Tag, Space } from 'antd';
import axios from 'axios';
import { Link } from "react-router-dom";
import ReactECharts from 'echarts-for-react';
import { RightOutlined, DollarCircleOutlined } from '@ant-design/icons';

//componentes
import { renderEstatusInversion } from '../../Utils';

//modales
import ModalEstadoCuenta from "./ModalEstadoCuenta"
import ModalPagoInversion from "./ModalPagoInversion"


const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')

/**
 * @export
 * @class MiCuenta
 * @extends {React.Component}
 */
export default class MiCuenta extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			inversiones: {
				page: 1,
				total: 0,
				limit: 10,
				data: []
			},
			inversion_id: undefined,
			modalEstadoCuenta: false,
			modalHacienda: false,
			total_invertido: 0,
			total_pagado: 0,
			monto_pendiente: 0,


		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getAsesor()
		this.getInversiones()
		this.getClenteDetalles()
	}


	/**
	* @method getInversiones
	* @description Obtiene la lista de inversiones del cliente
	*/
	getInversiones = ({
		page = this.state.inversiones.page,
		limit = this.state.inversiones.limit,
	} = this.state.inversiones) => {

		this.setState({ loading: true })

		axios.get('/customer/inversiones', {
			params: {
				page: page,
				limit: limit,
			}
		}).then(response => {
			console.log("response", response.data.data);
			this.setState({
				inversiones: {
					...this.state.inversiones,
					data: response.data.data.data,
					page: response.data.data.page,
					limit: response.data.data.limit,
					total: response.data.data.total
				},
			})



		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	* @method getClenteDetalles
	* @description Obtiene la informacion general extra de una cliente
	*/
	getClenteDetalles = () => {

		this.setState({ loading: true })

		axios.get('/customer/detalles', {
			params: {

			}
		}).then(response => {
			console.log("response", response.data);
			this.setState({
				monto_total: response.data.monto_total,
				monto_total_dolar: response.data.monto_total_dolar,
				monto_pesos: response.data.monto_pesos,
				monto_dolar: response.data.monto_dolar,
				monto_pendiente_pesos: response.data.monto_pendiente_pesos,
				monto_pendiente_dolar: response.data.monto_pendient_dolar,
				plantas_vendidas: response.data.plantas_vendidas,
				monto_pagado_pesos: response.data.monto_pagado_pesos,
				monto_pagado_dolar: response.data.monto_pagado_dolar,
			})


		}).catch(error => {
			console.log("error", error);
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	* @memberof MatrizCostos
	*
	* @method renderMontoVendido
	* @description renderiza los montos 
	*/
	renderMontoVendido = () => {
		const { monto_total, monto_total_dolar, monto_pesos, monto_dolar } = this.state;
		console.log("monto_total", monto_total);

		let amountInPesos, amountInDollars, size = 25;
		if (monto_pesos > 0) {
			amountInPesos = <>{`$ ${monto_pesos.toMoney(true)}`} <small>MXN</small></>;
		}
		if (monto_dolar > 0) {
			amountInDollars = <>{`$ ${monto_dolar.toMoney(true)}`} <small>USD</small></>;
		}

		if (monto_dolar > 0 && monto_pesos > 0) {
			amountInPesos = <>{`$ ${monto_total.toMoney(true)}`} <small>MXN</small></>;
			amountInDollars = <>{`$ ${monto_total_dolar.toMoney(true)}`} <small>USD</small></>;
			size = 20
		}

		if (!amountInPesos && !amountInDollars) return <Text ellipsis> $ 0 <small>MXN</small> </Text>;

		return <Text ellipsis style={{ fontSize: size }}> {amountInPesos} {amountInPesos && amountInDollars ? " / " : ""} {amountInDollars}</Text>;
	}

	/**
	* @memberof DetalleCliente
	*
	* @method renderMontos
	* @description renderiza los montos 
	*/
	renderMontos = ({ monto_pesos, monto_dolar, color = "info" }) => {

		let amountInPesos, amountInDollars, size = 25;
		if (monto_pesos > 0) {
			amountInPesos = <>{`$ ${monto_pesos.toMoney(true)}`} <small>MXN</small></>;
		}
		if (monto_dolar > 0) {
			amountInDollars = <>{`$ ${monto_dolar.toMoney(true)}`} <small>USD</small></>;
		}

		if (monto_pesos > 0 && monto_dolar > 0) {
			size = 20
		}

		if (!amountInPesos && !amountInDollars) return <Text ellipsis className={`text-${color}`}> $ 0 MXN </Text>;

		return <Text ellipsis className={`text-${color}`} style={{ fontSize: size }}> {amountInPesos} {amountInPesos && amountInDollars ? " / " : ""} {amountInDollars}</Text>;

	}

	getAsesor = () => {
		axios.get('/customer/cliente', {

		}).then(response => {
			console.log("response", response.data);
			this.setState({
				asesor: response.data.asesor
			})
		}).catch(error => {
			message.error('Error al cargar la informacion del asesor')
		})
	}


	renderEstatusInversion = ({ estatus = 0 }, item) => {

		let steps = {
			0: <Tag className="tag-inversion-cancelada">CANCELADA</Tag>,
			1: <Button
				size="small"
				title="Pagar Inversión"
				type="primary"
				className='center'
				icon={<DollarCircleOutlined />}
				onClick={() => this.setState({ modal_visible_pago: true, inversion_id: item._id })}
				disabled={item.moneda !== "USD" || item.estatus === 2}
			>Pagar Ahora</Button>,
			2: <Tag className="tag-inversion-pagada">PAGADA</Tag>,
			3: <Tag className="tag-inversion-ejecutada">EJECUTADA</Tag>,
			4: <Tag className="tag-inversion-reenvieda">RENVIADA</Tag>,
		}
		return estatus != undefined ? steps[estatus] : 'N/A'

	}

	render() {

		return (
			<Content className="hm-layout-content layout-dashboard" >
				<PageHeader className="site-page-header" ghost={false} title="Mi Cuenta" />
				<Row gutter={[24, 24]}>
					<Col xs={24} md={8}>
						<Card
							className="card-cyan"
							bordered={false}
							size="small"
							title={"Total Invertido"}
						>
							{this.renderMontoVendido()}
						</Card>
					</Col>

					<Col xs={24} md={8}>
						<Card
							className="card-green"
							bordered={false}
							size="small"
							title={"Total Pagado"}
						>
							{this.renderMontos({
								monto_pesos: this.state.monto_pagado_pesos,
								monto_dolar: this.state.monto_pagado_dolar,

							})}
							<Button onClick={() => this.setState({ modal_visible: true })}>
								Estados de Cuenta
							</Button>
						</Card>
					</Col>

					<Col xs={24} md={8}>
						<Card
							className="card-purple"
							bordered={false}
							size="small"
							title={"Total Pendiente"}
						>
							{this.renderMontos({
								monto_pesos: this.state.monto_pendiente_pesos,
								monto_dolar: this.state.monto_pendiente_dolar,

							})}
						</Card>
					</Col>

				</Row>
				{this.state.asesor ? <>
					<Row gutter={[18, 18]} style={{ marginTop: '2rem', marginLeft: '1rem' }} justify="space-between" align="middle">
						<Title className="zeus-title" level={3}>Mi asesor</Title>
					</Row>
					<Card className="w-100">
						<Row gutter={[18, 18]} justify="space-between" align="middle">
							{this.state.asesor?.avatar[0] ? <Col xs={24} md={6}>
								<Avatar src={`${axios.defaults.baseURL}/upload/${this.state.asesor?.avatar[0]?.filename}`} size={100} />
							</Col> : null}
							
							<Col xs={24} md={6}>
								<Text> {this.state.asesor?.nombres} {this.state.asesor?.apellido_paterno} {this.state.asesor?.apellido_materno}</Text>
							</Col>
							<Col xs={24} md={6}>
								<Text> {this.state.asesor?.email} </Text>
							</Col>
							<Col xs={24} md={6}>
								<Text> {this.state.asesor?.telefono} </Text>
							</Col>
						</Row>
					</Card>
				</> : null}

				<Row gutter={[18, 18]} style={{ marginTop: '2rem', marginLeft: '1rem' }} justify="space-between" align="middle">
					<Title className="zeus-title" level={3}>Mis inversiones Realizadas</Title>
				</Row>
				<List
					loading={this.state.loading}
					className="component-list"
					itemLayout="horizontal"
					dataSource={this.state.inversiones.data}
					locale={{ emptyText: "Sin inversiones " }}
					pagination={{
						current: this.state.inversiones.page,
						pageSize: this.state.inversiones.limit,
						total: this.state.inversiones.total,
						position: 'bottom',
						className: "flex-left",
						onChange: (page) => this.getInversiones({ page })
					}}
					renderItem={item => (
						<List.Item className="component-list-item">
							<Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
								<Row className="width-100 ">
									<Col span={1} className="center">
										<Avatar src="/img/zeus_logo_sin_texto.svg" />
									</Col>
									<Col className="center" xs={{ span: 18 }} md={{ span: 5 }}>
										<Space>
											<Text>Compra {item?.cantidad ? item?.cantidad : 0} Planta(s)</Text>
											{item.reventa_id && <Tag color="error">Reventa</Tag>}
										</Space>
									</Col>
									<Col className="center" xs={{ span: 20 }} md={{ span: 5 }}>
										<Text>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 3 }}>
										<Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 4 }}>
										{this.renderEstatusInversion({ estatus: item.estatus }, item)}
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 4 }}>
										<Text>$ {item.monto.toMoney(true)} {item.moneda}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 2 }}>
										<Space>
											<Link to={`/customer/mi-cuenta/detalle/${item._id}`}>
												<Button
													type="primary"
													className='center'
													icon={<RightOutlined />}
												/>
											</Link>
										</Space>
									</Col>
								</Row>
							</Card>
						</List.Item>
					)}
				/>
				<ModalEstadoCuenta
					visible={this.state.modal_visible}
					onClose={() => this.setState({
						modal_visible: false
					})}
				/>
				<ModalPagoInversion
					visible={this.state.modal_visible_pago}
					onClose={() => {
						this.setState({ modal_visible_pago: false, inversion_id: undefined })
						this.getInversiones()
						this.getClenteDetalles()
					}}
					ficha={{ inversion: { _id: this.state.inversion_id } }}
				/>
			</Content>
		)
	}
}