import React, { Component } from 'react';
import { Drawer, Button, Space, Form, Select, message, Divider, Empty, } from 'antd';
import axios from 'axios';

import { CardCliente } from '../../Widgets/Cards';
const { Option } = Select;



/**
 *
 *
 * @export
 * @class DrawerClientes
 * @extends {React.Component}
 */
export default class DrawerClientes extends React.Component {


    /**
     *Creates an instance of DrawerClientes.
     * @param {*} propsw
     * @memberof DrawerClientes
     */
    constructor(props) {
        super(props)
        this.state = {
            cliente: null,
            update: false,
            clientes: {
                data: [],
                loading: false,
                total: 0,
                page: 1,
                search: null,
            }
        }
    }
    formRef = React.createRef();


    /**
     *
     *
     * @memberof DrawerClientes
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.state.cliente = this.props.cliente;
        this.setState({ cliente: this.props.cliente });
        this.getClientes();

    }


    /**
     *
     *
     * @memberof DrawerClientes
     * @description Asigna al state el cliente asignado a la venta
     * @param {Object} cliente Objeto cliente asignado a la venta 
     */
    setCliente = (cliente) => {
        
        this.setState({ cliente })
    }

    /**
   * @method getClientes
   * @description Obtiene ls lista de Clientes
   * @param {object} {page, limit, search} - Datos de paginado y busqueda
   */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
    } = this.state.clientes,
        { clientes } = this.state
    ) => {

        clientes.page = page;
        clientes.limit = limit;
        clientes.search = search;
        clientes.loading = true;
        this.setState({ clientes })
        clientes.loading = false;
        axios.get('/clientes', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({data}) => {
                
                clientes.data = data.data
                clientes.total = data.total;
                clientes.page = data.page;

                this.state.cliente = this.props.cliente;
                this.setState({ clientes });
                let current = this.state.cliente;
                this.formRef.current?.setFieldsValue({
                    cliente: current?._id
                })
            })
            .catch(error => {
                
                message.error('Error al obtener Clientes')
            })

    }

    /**
  * @method getClientes
  * @description Asigna al state el Objeto cliente seleccionado 
  */
    handleChangeCliente = (value) => {
        let clientes = this.state.clientes.data;
        let encontrado = clientes.find(cliente => cliente._id == value);
        this.setState({ cliente: encontrado })
    }


    /**
     *
     *
     * @memberof DrawerClientes
     * @method onClose
     * @description Cierra el drawer
     */
    onClose = () => {
        this.setState({ cliente: null });
        this.props.onClose()
    }
    render() {
        const { visible, addCliente, newCliente, editCliente } = this.props;
        const cliente = (this.props.cliente != undefined || this.props.cliente !=null) ? this.props.cliente : this.state.cliente;
        return <Drawer
            title="Cliente"
            placement={"right"}
            height="70vh"
            width="450px"
            onClose={this.onClose}
            visible={visible}
            destroyOnClose={true}
            extra={
                <Space>
                    <Button size="small" type="primary" className='btn-primary' onClick={newCliente}>
                        Nuevo Cliente
                    </Button>
                </Space>

            }
        >
            <Form
                layout='vertical'
                onFinish={addCliente}
                ref={this.formRef} >
                <Form.Item
                    className='mb-2'
                    name="cliente"
                    rules={[{
                        required: true,
                        message: "Por favor, ingrese el cliente a asignar"
                    }]} >
                    <Select
                        showSearch
                        placeholder="Buscar Cliente"
                        defaultActiveFirstOption={false}
                        showArrow={true}
                        filterOption={false}
                        allowClear={true}
                        onSearch={(value) => this.getClientes({ search: value })}
                        onDeselect={() => this.setState({ cliente: null })}
                        onChange={(e) => this.handleChangeCliente(e)}
                        notFoundContent={<Empty
                            description="No se encontró Cliente"
                        />}
                    >
                        {this.state.clientes.data.map(({ _id, nombre, apellido_materno, apellido_paterno }) => {
                            return <Option key={_id} value={_id}>{nombre} {apellido_materno} {apellido_paterno}</Option>
                        })
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button block htmlType='submit' className='btn-secondary' >Asignar Cliente a la Venta</Button>
                </Form.Item>
            </Form>
            {(cliente != undefined) ?
                <>
                    <Divider></Divider>
                    <CardCliente
                        loading={this.state.loading}
                        onClick={editCliente}
                        nombre={`${cliente.usuario_id?.nombres}      ${cliente.usuario_id?.apellido_paterno}    ${cliente.usuario_id?.apellido_materno}`}
                        id={cliente._id}
                        telefono={cliente.usuario_id?.telefono}
                        direccion={`${cliente?.calles} # ${cliente?.numero}, ${(cliente?.codigo_postal) ? `CP. ${cliente?.codigo_postal}` : ``}`}
                        localidad={`${cliente?.ciudad}, ${cliente?.estado_id?.nombre}, ${cliente?.pais_id?.nombre}`}
                        email={cliente.usuario_id?.email}
                        fecha={cliente.createdAt}
                    />
                </>
                : null
            }
        </Drawer>
    }
}