import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FilePdfOutlined, QuestionCircleOutlined, SwapOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, List, Typography, Card, message, Tooltip, Popconfirm, Popover, Spin } from "antd";

//componentes
import { renderEstatusInversion } from '../../../Utils';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import FloatingButton from "../../../Widgets/FloatingButton/FloatingButton";
import { IconCategorias, IconMail, IconEye, IconListon, IconBtnEdit, IconChain, IconBtnDelete, IconMailOpen } from '../../../Widgets/Iconos';
//modales
import ModalInversion from "../../Inversiones/ModalInversion"
import usePermissions from "../../../../Hooks/usePermissions";


const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class Inversiones
 * @extends {Component}
 * @description Vista del listado de Inversiones realizadaspor el cliente
 */
class Inversiones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inversiones: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                cliente_id: this.props.cliente_id
            },
            inversion_id: undefined,
            modal_visible: false,
            tempCantidades: {
                pendientes : 0,
                vendidas : 0,
                total : 0,
                revendidas : 0
            },
            loadingCant: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.cliente_id) {
            this.getInversiones()
        }
    }

    /**
    * @memberof getInversiones
    * @description Obtiene todas todas las inversiones del cliente
    * @param {object} {page, limit} Datos de paginado
    * @param {object} {inversiones} Array de inversiones
    */
    getInversiones = ({
        page = this.state.inversiones.page,
        limit = this.state.inversiones.limit,
    } = this.state.inversiones) => {
        this.setState({ loading: true })
        axios.get('/inversiones', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id,
            }
        })
            .then(({ data }) => {

                this.setState({
                    inversiones: {
                        ...this.state.inversiones,
                        ...data
                    }
                })

            })
            .catch(err => {

                message.error('No se pudieron cargar las inversiones.')
            })
            .finally(() => this.setState({ loading: false }))

    }

    /**
    * @memberof getInversiones
    * @description Obtiene todas todas las inversiones del cliente
    * @param {object} {page, limit} Datos de paginado
    * @param {object} {inversiones} Array de inversiones
    */
    getCantidades = (inversion_id) => {
        this.setState({ loadingCant: true })
        axios.get('/inversion/cantidades', {
            params: {
                inversion_id
            }
        })
            .then(({ data }) => {
                console.log(data)
                this.setState({tempCantidades: data.data})
            })
            .catch(err => {

                message.error('No se pudieron cargar las cantidades.')
            })
            .finally(() => this.setState({ loadingCant: false }))

    }

    /**
    *
    * @memberof DetalleInversion
    * 
    * @method getPDF
    * @description Generamos el PDF de la referencia de pago
    */
    getPDF = (inversion_id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/crm/ficha-pago');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('inversion_id', inversion_id)
        window.open(urlPDF.href, '_blank');
    }

    /**
     *
     *
     * @memberof MatrizCostos
     *
     * @method sendPDF
     * @description Enviamos la cotizacion al cliente
     */
    sendPDF = (inversion_id) => {
        this.setState({ loading: true });
        return axios
            .get("/crm/ficha-pago", {
                params: {
                    inversion_id: inversion_id,
                    enviar: true,
                },
            })
            .then((response) => {
                message.success("Recibo Enviado");
                this.getInversiones();
            })
            .catch((error) => {
                console.log(error);
                message.error("Error al enviar el recibo");
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };


    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    dataSource={this.state.inversiones.data}
                    locale={{ emptyText: "Sin Inversiones" }}
                    pagination={{
                        current: this.state.inversiones.page,
                        pageSize: this.state.inversiones.limit,
                        total: this.state.inversiones.total,
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page) => this.getInversiones({ page }),
                        hideOnSinglePage: true
                    }}
                    header={<Row className=" width-100 pl-1 pr-1 ">

                        <Col xs={6} xl={3} className="center">
                            <Text className="text-gray">Folio</Text>
                        </Col>
                        <Col xs={6} xl={3} className="center">
                            <Text className="text-gray">Plantas</Text>
                        </Col>
                        <Col xs={6} xl={3} className="center">
                            <Text className="text-gray">Fecha</Text>
                        </Col>
                        <Col xs={6} xl={3} className="center">
                            <Text className="text-gray">Monto</Text>
                        </Col>
                        <Col xs={6} xl={3} className="center">
                            <Text className="text-gray">Monto Pendiente</Text>
                        </Col>
                        <Col xs={6} xl={4} className="center">
                            <Text className="text-gray">Estatus</Text>
                        </Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list hover" >
                                <Row className="width-100 " gutter={[0, 8]}>
                                    <Col className="center" xs={6} xl={3}>
                                        <Text>{item.folio}</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={3}>
                                        {(item.cantidad_revendidas > 0) ? <Popover
                                            onOpenChange={(e) => e ? this.getCantidades(item._id): this.setState({tempCantidades:{total: 0, revendidas: 0, vendidas: 0, pendientes: 0}})}
                                            overlayStyle={{
                                                //boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                                            }}
                                            content={
                                                <Spin spinning={this.state.loadingCant}>
                                                    {this.state.tempCantidades.total || 0} plantas en total. <br />
                                                    {this.state.tempCantidades.revendidas || 0} para reventa. <br />
                                                    {this.state.tempCantidades.vendidas|| 0} plantas vendidas. <br />
                                                    {this.state.tempCantidades.pendientes || 0} plantas pendientes <br />
                                                </Spin>
                                            }>
                                            {item.cantidad - item.cantidad_revendidas} / {item.cantidad} planta{item.cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
                                        </Popover> : <Text>
                                            {item.cantidad} planta{item.cantidad > 1 && 's'}
                                        </Text>}
                                        {/* <Text>{item.cantidad} {item.cantidad_revendidas ? <>({item.cantidad - item.cantidad_revendidas} en venta, {item.cantidad_revendidas} vendidas)</> : null}</Text> */}
                                    </Col>
                                    <Col className="center" xs={6} xl={3}>
                                        <Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={3}>
                                        <Text>$ {item.monto?.toMoney ? item.monto.toMoney(true) : 0} {item.moneda}</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={3}>
                                        <Text>$ {item.monto_pendiente?.toMoney ? item.monto_pendiente.toMoney(true) : 0} {item.moneda}</Text>
                                    </Col>
                                    <Col className="center" xs={6} xl={4}>
                                        {renderEstatusInversion({ estatus: item.estatus })}
                                    </Col>
                                    <Col className="flex-right" xs={8} xl={5}>
                                        <Space direction="horizontal">
                                            {
                                                item.fecha_ficha_envio ? (
                                                    <Tooltip
                                                        title={`Enviado hace ${moment(item.fecha_ficha_envio).toNow(true)}`}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            className='ant-btn-primary-purple'
                                                            icon={<IconMailOpen />}
                                                            onClick={() => this.sendPDF(item._id)}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        className='ant-btn-primary-purple'
                                                        icon={<IconMail />}
                                                        onClick={() => this.sendPDF(item._id)}
                                                    />
                                                )
                                            }
                                            <Button
                                                type="primary"
                                                className='center ant-btn-primary-blue'
                                                icon={<FilePdfOutlined />}
                                                onClick={() => this.getPDF(item._id)}
                                            />
                                            <Link to={item.estatus === 0 ? '#' : `/admin/crm/clientes/detalle/inversion/${item._id}`}>
                                                <Button
                                                    type="primary"
                                                    disabled={item.estatus === 0 ? true : false}
                                                    className='center ant-btn-primary-green'
                                                    title="Ver Detalle"
                                                    icon={<IconEye />}
                                                />
                                            </Link>
                                            {/*<Button
                                                type="primary"
                                                className='center ant-btn-primary-yellow'
                                                icon={<IconChain />}
                                            />*/}
                                            <Button
                                                type="primary"
                                                className='center ant-btn-primary-blue'
                                                icon={<IconBtnEdit />}
                                                disabled={!this.props.editar}
                                                onClick={() => this.setState({ modal_visible: true, inversion_id: item._id })}
                                            />
                                            <Tooltip
                                                title="Eliminar"
                                                placement="topRight"
                                            >
                                                <Popconfirm
                                                    placement="topRight"
                                                    title="¿Deseas eliminar esta inversión?"
                                                    onConfirm={() => axios.delete('/inversion', {
                                                        params: { id: item._id }
                                                    }).then((response) => {
                                                        message.success("Inversión eliminada")
                                                        this.props.getCliente()
                                                    }).catch((error) => {
                                                        message.error("Error al eliminar la inversión");
                                                    }).finally(() => {
                                                        this.getInversiones()
                                                    })
                                                    }
                                                    okText="Sí"
                                                    cancelText="No"
                                                    disabled={!this.props.delete}
                                                >
                                                    <Button type="primary" className='center' danger title="Eliminar" icon={<IconBtnDelete />} />
                                                </Popconfirm>
                                            </Tooltip>
                                        </Space>
                                    </Col>

                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />

                {this.props.create && <FloatingButton title="Nueva Inversión" onClick={() => this.setState({ modal_visible: true, inversion_id: undefined })} />}


                <ModalInversion
                    visible={this.state.modal_visible}
                    onClose={(flag) => {
                        this.setState({ modal_visible: false, inversion_id: undefined })
                        if (flag === true) {
                            this.getInversiones()
                            this.props.getCliente()
                        }

                    }}
                    inversion_id={this.state.inversion_id}
                    cliente={this.props.cliente}
                />

            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Inversiones {...props}

        {...usePermissions(null, {
            editar: ["inversiones", "edit"],
            delete: ["inversiones", "delete"],
            create: ["inversiones", "create"],
        })}
    />)
}