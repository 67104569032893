import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd'

const { Option } = Select
const { Title, Text } = Typography;
const moment = require("moment");
const axios = require("axios");

/**
 * @const SelectInversion
 * @description Select para las inversiones relacionadas a un cliente
 */
const SelectInversion = (props) => {

    const {
        value,
        onChange,
        placeholder = "Seleccione la inversión del cliente",
        onSelect = () => { },
        disabled = false,
        className = "",
        params = {},
        bordered,
        allowClear,
        onClear = () => { },
        cliente_id,
        hacienda_id

    } = props

    const [inversiones, setInversiones] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
        cliente_id: null
    })

    const [loading, setLoading] = useState(false)


    /**
     * @const getInversiones
     * @description Obitiene las inversiones
     */
    const getInversiones = ({ page, limit, search } = inversiones) => {

        console.log('INVERSIONES', cliente_id);
        setLoading(true)
        axios.get('/inversiones', {
            params: {
                page,
                limit,
                search,
                cliente_id,
                hacienda_id,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {
                // console.log("response", response.data);
                setInversiones({
                    ...inversiones,
                    ...data
                })
            })
            .catch(error => {
                console.log("error", error);
                message('Error al obtener las inversiones')
            })
            .finally(e => setLoading(false))
    }

    //componentDidUpdate
    useEffect(() => {
        console.log("CLIENTE");

        if (cliente_id || hacienda_id)
            getInversiones()

    }, [cliente_id, hacienda_id])


    useEffect(() => {
        console.log("FIRST");
        getInversiones()
    }, [])




    return (
        <Select
            loading={loading}
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={true}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search) => getInversiones({ search })}
            onSelect={(inversion, { data }) => {
                onChange(inversion.value)
                onSelect(inversion.value, data)
            }}
            value={value}
            onClear={() => {
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                inversiones.data.map((inversion) => {

                    const { folio, _id, hacienda_id, moneda, cliente_id, fecha, monto_pendiente, monto } = inversion

                    return <Option key={_id} value={_id} data={inversion}>
                        <Title level={5}>{folio} <small style={{ float: "right" }}>{moment(fecha).format("DD-MM-YYYY")}</small></Title>
                        <Text>$ {monto_pendiente?.toMoney(true) ?? "0.00"} {moneda}</Text>
                        &nbsp; <Text strong>{hacienda_id.nombre}</Text>
                    </Option>
                })
            }
        </Select>
    );
}



export default SelectInversion;