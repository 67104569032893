import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: require("./en.json")
        // {
        //   "To get started, edit <1>src/App.js</1> and save to reload.": "To get started, edit <1>src/App.js</1> and save to reload.",
        //   "Welcome to React": "Welcome to React and react-i18next",
        //   welcome: "Hello <br/> <strong>World</strong>"
        // }
      },
      es: {
        translations: require("./es.json")
        // {
        //   "To get started, edit <1>src/App.js</1> and save to reload.":
        //     "Starte in dem du, <1>src/App.js</1> editierst und speicherst.",
        //   "Welcome to React": "Willkommen bei React und react-i18next"
        // }
      }
    },
    fallbackLng: "es",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys}
    returnObjects: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
