import React, { Component } from 'react';
import { Button, Form, Input, message, Modal, Spin, Typography } from 'antd'
import axios from 'axios';
import PropTypes from "prop-types";

const { Title } = Typography
const formItemLayout = { labelCol: { span: 24, }, wrapperCol: { span: 24, } };

/**
 *
 *
 * @export
 * @class FormSizes
 * @extends {Component}
 * @description Vista donde se listan todos los FormSizes
 */
class FormSizes extends Component {

     /**
     *
     *
     * @static
     * @memberof FormSizes
     * 
     * @var propTypes Son los valores por defecto
     */
      static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    /**
     *Creates an instance of FormSizes.
     * @param {*} props
     * @memberof FormSizes
     */
    constructor(props) {
        super(props)
        this.state = {
            loading:false,
        }
    }

    formRef = React.createRef();



    /**
     *
     *
     * @memberof FormSizes
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        if (this.props.id != null)
            this.getSize();
    }


    /**
    * @method getSize
    * @description Obtiene ls lista de Tamaños
    */
    getSize = () => {
        this.setState({ loading: true })
        axios.get('/sizes/get', {
            params: {
                id: this.props.id,
            }
        }).then(response => {
            console.log('response',response)
            this.formRef.current.setFieldsValue({
                ...response.data.data.size,
            })
           
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof FormSizes
     * 
     * @method onFinish
     * @param {object} values - Objecto que contiene los valores del formulario
     * @description Cuando se guarda eltamaño
     */
    onFinish = values => (this.props.id !== null) ? this.update(values) : this.save(values)

    /**
     *
     *
     * @memberof FormSizes
     * 
     * @method save
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     * @description Guardamos el tamano
     */
    save = (values) => {
        this.setState({ loading: true })
        axios.post('/sizes/add', {
            ...values,
        })
            .then(() => {
                message.success("¡Se ha guardado correctamente el tamaño!")
                this.props.onClose();
            })
            .catch((e) => {
                message.error('No se pudo guardar el tamaño')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }

    /**
     *
     *
     * @memberof FormSizes
     * 
     * @method update
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     * @description Actualizamos eltamano.
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/sizes/update', {
            ...values,
            id: this.props.id,
        })
            .then(() => {
                message.success("¡Se ha guardado correctamente el tamaño!")
                this.props.onClose();
            })
            .catch((e) => {
                message.error('No se pudo guardar el tamaño')
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }




    render() {
        const { formRef, } = this;
        const { form, loading, } = this.state;
        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">  {`${this.props.id ? 'Editar' : 'Nuevo'}  Tamaño`}</Title>
                <Form
                    {...formItemLayout}
                    layout="vertical"
                    ref={formRef}
                    initialValues={form}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        label="Nombre"
                        name="size"
                        rules={[
                            { required: true, message: "Ingrese el nombre del tamaño" }
                        ]} >
                        <Input placeholder="Nombre" />
                    </Form.Item>

                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading} >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin >

        )
    }
}
/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            className="modal-zeus"
        >
            <FormSizes {...props} />
        </Modal>
    )
}
