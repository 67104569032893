import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Dropdown, Menu, } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { EllipsisOutlined, FileSyncOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';

//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { CardInfoHacienda, CardPlantasHacienda } from '../../Widgets/Cards';
import { IconBtnDelete, IconBtnEdit, IconEye, IconArrowBack } from '../../Widgets/Iconos'

//Modales
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import ModalHacienda from './Modales/ModalHacienda';
import ModalTransaccion from "../Transacciones/ModalTransaccion";
import ModalTransaccionDetalle from '../Transacciones/ModalTransaccionDetalle';

const { Content } = Layout;
const { Title, Text } = Typography

const moment = require("moment");
/**
 *
 *
 * @export
 * @class HaciendaDetalles
 * @extends {Component}
 * @description Vista donde se listan todos los HaciendaDetalles
 */
class HaciendaDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }


    /**
     *Creates an instance of HaciendaDetalles.
     * @param {*} props
     * @memberof HaciendaDetalles
     */
    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            hacienda: {},
            loading: false,
            transaccion_id: undefined,
            movimientos: {
				loading: false,
                data: [],
                page: page ?? 1,
                limit: limit ?? 10,
                filters: filters || [],
                total: 0,
                sort: sort || {},
            },
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getHacienda()

        this.getTransacciones()
    }

    /**
     * @memberof HaciendaDetalles
    * @method getHacienda
    * @description Obtiene la informacion de la hacienda
    */
    getHacienda = () => {
        axios.get('/hacienda', {
            params: {
                _id: this.props.params.hacienda_id,
            }
        }).then(response => {
            this.setState({ hacienda: response.data.data })

        }).catch(error => {

            message.error('Error al cargar la Hacienda')
        })
    }

    drawerFiltros = React.createRef()


    /**
     *
     *
     * @memberof HaciendaDetalles
     * @method getTransacciones
     * @param {object} {page, limit, search} - Datos de paginado y busqueda
     * @description Obtiene las transacciones de la hacienda
     */
    getTransacciones = ({
        page = this.state.movimientos.page,
        limit = this.state.movimientos.limit,
        search = this.state.search,
        filters = this.state.movimientos.filters,
        sort = this.state.movimientos.sort,
    } = this.state.movimientos, {movimientos} = this.state) => {

        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)

       
        this.setState({ movimientos: { ...this.state.movimientos, page, limit, search, filters, sort }, loading: true })

        console.log(this.drawerFiltros)
        
        axios.get('/transacciones', {
            params: {
                movimiento: true,
                hacienda_id: this.props.params.hacienda_id,
                tipo: 1,//ingreso
                page,
                limit,
                search,
                filters,
            }
        }).then(response => {
            this.setState(state => {
                if (page === 1)
                    state.movimientos.data = response.data.data.itemsList
                else
                    state.movimientos.data = [...state.movimientos.data, ...response.data.data.itemsList]

                state.movimientos.total = response.data.data.itemCount
                state.movimientos.pages = response.data.data.pageCount
                state.loading = false
                return state;
            })

        }).catch(error => {

            message.error('Error al cargar Movimientos')
        })
    }

    /**
     *
     *
     * @memberof HaciendaDetalles
     * @method completeHacienda
     * @description Desactiva la hacienda y genera los reportes finales para luego enviarte a la vista de HaciendaCompleta
     */
    completeHacienda = () => {
        if (this.state.loading) return

        if (!this.state.hacienda.activo) {
            message.error("La Hacienda esta completa")
            return
        }

        this.setState({ loading: true }, () => {
            axios.post('/resumenes/add-hacienda', {
                hacienda_id: this.props.params.hacienda_id,
                tipo: 2
            }).then(response => {
                message.success("Hacienda completada")
                this.props.navigate(`/admin/haciendas/completa/${this.props.params.hacienda_id}`)
            }).catch(error => {

                message.error('Error al completar la Hacienda')
            }).finally(() => {
                this.setState({ loading: false })
            })
        })
    }

    /**
     *
     *
     * @memberof HaciendaDetalles
     * @method mensualHacienda
     * @description Genera los reportes mensuales y te envia a la vista de HaciendaMensual
     */
    mensualHacienda = () => {
        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.put('/resumenes/update-hacienda', {
                hacienda_id: this.props.params.hacienda_id,
                tipo: 1
            }).then(response => {
                message.success("Reportes generados")
                this.props.navigate(`/admin/haciendas/mensual/${this.props.params.hacienda_id}`)
            }).catch(error => {

                message.error('Error al generar el reporte mensual')
            }).finally(() => {
                this.setState({ loading: false })
            })
        })
    }

    render() {

        const { hacienda } = this.state

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Detalle Hacienda"
                        backIcon={<IconArrowBack />}
                        onBack={this.back}
                        extra={
                            <Space>
                                <Button
                                    type="primary"
                                    className='btn-secondary'
                                    icon={<FileSyncOutlined style={{ color: '#FFFFFF' }} />}
                                    onClick={() => this.mensualHacienda()}
                                >
                                    Reporte Mensual
                                </Button>
                                <Button
                                    type="primary"
                                    className='btn-secondary'
                                    icon={<CheckOutlined style={{ color: '#FFFFFF' }} />}
                                    onClick={() => this.completeHacienda()}
                                >
                                    Reporte Final
                                </Button>
                                <Button
							onClick={() => this.setState({ visibleFilters: true })}
							className="button-plus"
							icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                            </Space>
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={12} lg={8}>
                                <CardInfoHacienda
                                    extra={<Dropdown overlay={<Menu>
                                        <Menu.Item onClick={() => this.setState({ modalHacienda: true })}>
                                            Editar
                                        </Menu.Item>
                                    </Menu>}>
                                        <EllipsisOutlined />
                                    </Dropdown>}
                                    nombre={hacienda?.nombre}
                                    descripcion={hacienda?.descripcion}
                                    fecha_creacion={hacienda?.fecha_creacion}
                                    hectareas={hacienda?.hectareas}
                                    localization={hacienda?.localizacion}
                                    rendimiento={0}
                                />
                            </Col>
                            <Col xs={24} md={12} lg={8} style={{ display: 'flex' }}>
                                <CardPlantasHacienda
                                    plantas={hacienda?.plantas}
                                    disponible={hacienda?.disponible}
                                    precio={hacienda?.precio}

                                />
                            </Col>
                        </Row>

                        <Title level={3} className="mt-1 text-cyan">Transacciones</Title>

                        <List
                            loading={this.state.movimientos.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Compras" }}
                            dataSource={this.state.movimientos.data}
                            pagination={{
                                onChange: (page) => this.getTransacciones({ page }),
                                current: this.state.movimientos.page,
                                pageSize: 10,
                                total: this.state.movimientos.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            header={<Row className=" width-100 pl-1 pr-1 ">
                                <Col xs={12} md={5} className="center">
                                    Fecha
                                </Col>
                                <Col xs={12} md={5} className="center">
                                    Cliente
                                </Col>
                                <Col xs={12} md={5} className="center">
                                    Concepto
                                </Col>
                                <Col xs={24} md={5} className="center">
                                    Monto
                                </Col>
                                <Col xs={24} md={4}></Col>
                            </Row>
                            }
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col xs={12} md={5} className="center">
                                                <Text strong>{moment(item.fecha).format("DD-MM-YYYY HH:mm")}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="center">
                                                <CustomAvatar
                                                    name={item.cliente_id?.nombre + " " + item.cliente_id?.apellido_paterno + " " + item.cliente_id.apellido_materno}
                                                    style={{ marginRight: '4px' }}
                                                />
                                                <Text>{item.cliente_id ? `${item.cliente_id?.nombre ?? ""} ${item.cliente_id?.apellido_paterno ?? ""}` : 'N/A'}</Text>
                                            </Col>
                                            <Col xs={8} md={5} className="center">
                                                <Text className="text-gray"> {item?.concepto}</Text>
                                            </Col>
                                            <Col xs={8} md={5} className="center">
                                                <Text className="text-cyan">$ {item.monto.toMoney ? item.monto?.toMoney(true) : 0} {item?.moneda} </Text>
                                            </Col>
                                            <Col xs={24} md={4} className="center">
                                                <Space wrap>
                                                    <Button
                                                        type="primary"
                                                        loading={this.state.loading}
                                                        className='btn-primary'
                                                        icon={<IconEye />}
                                                        onClick={() => this.setState({ modalTransaccionDetalle: true, transaccion_id: item._id })} />
                                                    <Button type="primary" loading={this.state.loading} className='btn-secondary' icon={<IconBtnEdit />} onClick={() => this.setState({ modalTransaccionEditar: true, transaccion_id: item._id })} />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta transacción?"
                                                        onConfirm={() => axios.delete('/transacciones/delete', { params: { id: item._id } }).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getTransacciones()
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);
                                                                this.getTransacciones();
                                                            })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" className='btn-delete' danger title="Eliminar" icon={<IconBtnDelete />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        <DrawerFiltros
                            ref={this.drawerFiltros}
                            title="Filtrar Transacciones"
                            csv={"transacciones"}
                            updateFilters={filters => this.getTransacciones({
                                filters
                            })}
                            onClose={() => this.setState({ visibleFilters: false })}
                            visible={this.state.visibleFilters}
                            estatus={false}
                            haciendas={false}
                            hacienda_id={this.props.params.hacienda_id}
                            cuentas={false}
                            clientes={false}
                            ordenes={false}
                            ordenes_compras={false}
                            area_rubro={false}
                            clasificadores={false}
                            razones_sociales={false}
                            negocios={false}
                            proveedores={false}
                            vendedores={false}
                        />
                        <ModalTransaccionDetalle
                            visible={this.state.modalTransaccionDetalle}
                            onCancel={() => {
                                this.setState({ modalTransaccionDetalle: false, transaccion_id: undefined, })
                            }}
                            transaccion_id={this.state.transaccion_id}
                        />
                        <ModalTransaccion
                            visible={this.state.modalTransaccionEditar}
                            onCancel={() => {
                                this.setState({ modalTransaccionEditar: false, transaccion_id: undefined })
                                this.getTransacciones()
                            }}
                            transaccion_id={this.state.transaccion_id}
                        />
                        <ModalHacienda
                            visible={this.state.modalHacienda}
                            id={this.state.hacienda._id}
                            onCancel={() => this.setState({ modalHacienda: false })}
                            onSuccess={() => {
                                this.setState({ modalHacienda: false })
                                this.getHacienda()
                            }}
                        />
                    </Content>
                </Spin>


            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()

    return <HaciendaDetalles  {...props} params={params} navigate={navigate} />
}