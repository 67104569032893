import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber, Select, Switch } from 'antd';


const { Title } = Typography;
const { Option } = Select;

const axios = require('axios').default;


/**
 *
 *
 * @class ModalEmpleado
 * @extends {Component}
 */
class ModalEmpleado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            imagenes: [],
            usuarios: [],
            usuario_id: undefined,
        }
    }

    modalRef = React.createRef()

    componentDidMount() {
        this.getUsuarios()

        if (this.props.empleado_id) {
            this.getEmpleado()
        }
    }

    /**
     * @memberOf ModalEmpleado
    * @method getUsuarios
    * @description Obtiene la lista de usuarios que son emplados y no estan registrados 
    */
    getUsuarios = (search) => {
        this.setState({ loading: true })
        axios.get('/usuarios', {
            params: {
                search,
                empleados_new: true,
            }
        }).then(response => {
            this.setState({
                usuarios: response.data.data.itemsList,
            })
        }).catch(error => {

            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberOf ModalEmpleado
    * @method getEmpleado
    * @description Obtiene la informaciond el empleado
    */
    getEmpleado = () => {
        this.setState({ loading: true })
        axios.get('/empleado', {
            params: {
                empleado_id: this.props.empleado_id,
            }
        }).then(response => {
            console.log("response", response.data);
            this.modalRef.current.setFieldsValue({
                ...response.data,
                usuario_id: response.data.usuario_id ? {
                    value: response.data.usuario_id?._id,
                    key: response.data.usuario_id?._id,
                    label: `${response.data.usuario_id?.nombres} ${response.data.usuario_id?.apellido_paterno}`
                } : null,
            })

            this.setState({
                usuario_id: response.data.usuario_id?._id
            })

        }).catch(error => {
            console.log("error", error);

            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberOf ModalEmpleados
     * @method onFinish
     * @descripcion Se ejecuta al ahcer submit al formulario
     * 
     * */
    onFinish = (values) => {

        values.usuario_id = values.usuario_id?.value

        if (this.props.empleado_id) {
            this.updateEmpleado(values)
        } else {
            this.addEmpleado(values)
        }
    }

    /**
    * @memberOf ModalEmpleados
    * @method addEmpleado
    * @descripcion Añade un emplado y lo relaciona con usuario si hay
    * 
    * */
    addEmpleado = (values) => {

        this.setState({ loading: true })
        axios.post('/empleado/add', {
            ...values
        }).then(response => {
            this.props.onCancel()
            message.success('Empleado agregado con exito')

        }).catch(error => {
            message.error('Error al registrar el usuario')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberOf ModalEmpleados
     * @method updateEmpleado
     * @descripcion Añade un emplado y lo relaciona con usuario si hay
     * 
     * */
    updateEmpleado = (values) => {

        this.setState({ loading: true })
        axios.post('/empleado/update', {
            ...values,
            empleado_id: this.props.empleado_id
        }).then(response => {
            this.props.onCancel()
            message.success('Empleado actualizado con exito')
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar el empleado')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.onFinish}
                className="pd-1"
                onValuesChange={(values, allvalues) => {
                    if (values.modalidad_pago) {
                        this.setState({modalidad_pago: values.modalidad_pago})
                    }


                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[14, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Empleado"
                                name="usuario_id"

                            >
                                <Select
                                    showSearch
                                    placeholder="Buscar Usuario"
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(value) => this.getUsuarios(value)}
                                    onSelect={(value) => {
                                        this.setState({ usuario_id: value })
                                    }}
                                    onDeselect={() => this.setState({ usuario_id: undefined })}
                                    labelInValue={true}
                                >
                                    {
                                        this.state.usuarios.map(usuario => <Option value={usuario._id}>{usuario.nombres} {usuario.apellido_paterno}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {this.state.usuario_id !== undefined ? null : <> <Col xs={24} lg={12} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" maxLength={50}></Input>
                            </Form.Item>
                        </Col>
                            <Col xs={24} lg={12} >
                                <Form.Item
                                    label="Apellidos"
                                    name="apellido"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese los apellidos"
                                    }]}
                                >
                                    <Input placeholder="Apellidos" maxLength={50}></Input>
                                </Form.Item>
                            </Col> </>}
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                // rules={[{
                                //     required: true,
                                //     message: "Por favor, ingrese el RFC del empleado"
                                // }]}
                                rules={[
                                    {
                                        pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                        message: 'RFC invalido'
                                    }
                                ]}
                            >
                                <Input placeholder="Nombre" maxLength={50}></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="NSS"
                                name="nss"
                                // rules={[{
                                //     required: true,
                                //     message: "Por favor, ingrese el NSS del empleado"
                                // }]}
                                rules={[
                                    {
                                        pattern: /^(\d{2})(\d{2})(\d{2})\d{5}$/,
                                        message: 'NSS invalido'
                                    }]}
                            >
                                <Input placeholder="Número de Seguro Social" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="CURP"
                                name="curp"
                                // rules={[{
                                //     required: true,
                                //     message: "Por favor, ingrese el CURO del empleado"
                                // }]}
                                rules={[
                                    {
                                        pattern: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
                                        message: 'CURP invalido'
                                    }
                                ]}
                            >
                                <Input placeholder="CURP" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Modalidad Pago"
                                name="modalidad_pago"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la modalidad del pago"
                                }]}
                            >
                                <Select>
                                    <Option value={1}> Salario </Option>
                                    {/* <Option value={2}> Destajo </Option> */}
                                    <Option value={3}> Por hora </Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Frecuencia Pago"
                                name="periodo_pago"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la frecuencia de pago"
                                }]}
                            >
                                <Select>
                                    <Option value={1}> Semanal </Option>
                                    <Option value={2}> Quincenal </Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label={(this.state.modalidad_pago === 1) ? "Salario" : "Costo por hora"}
                                name="salario"
                                rules={[{
                                    required: true,
                                    message: "Por favor,ingrese el salario del empleado"
                                }]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Activo"
                                name="activo"
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren="Activo"
                                    unCheckedChildren="Inactivo"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Vista(props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>{props.empleado_id ? 'Editar' : 'Registar'} Empleado</Title>
        </div>
        <ModalEmpleado {...props} />
    </Modal>

}