
import React from 'react';
import { Drawer, Form, Select, Cascader, message, Badge, Button, TreeSelect, DatePicker, InputNumber } from 'antd';
import axios from 'axios';
import CAvatar from '../Avatar/Avatar';
import { DownloadOutlined } from '@ant-design/icons';
import useSearchParams from '../../../Hooks/SearchParams';
import moment from 'moment';
import { add } from 'lodash';

const { Option } = Select
const { RangePicker } = DatePicker

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state razones_sociales Objeto para paginar y buscar en el select de Razones Sociales
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {


        cuentas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        vendedores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        ordenes_compras: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },


        areas: [],
        areas_dictionary: {},
        rubros_dictionary: {},

        razones_sociales: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        inversiones: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        clasificadores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        negocios: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        proveedores: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        haciendas: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },
        filtros: null,
        estatus: {
            data: [],
            dictionary: {}
        }
    }

    formFiltros = React.createRef()

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.cuentas === undefined || this.props.cuentas !== false)
            this.getCuentas()

        if (this.props.clientes === undefined || this.props.clientes !== false)
            this.getClientes()

        if (this.props.vendedores === undefined || this.props.vendedores !== false)
            this.getVendedores()

        if (this.props.vendedores_transaccion === undefined || this.props.vendedores_transaccion !== false)
            this.getVendedores()

        if (this.props.ordenes === undefined || this.props.ordenes !== false)
            this.getOrdenes()

        if (this.props.ordenes_compras === undefined || this.props.ordenes_compras !== false)
            this.getOrdenesCompras()

        if (this.props.area_rubro === undefined || this.props.area_rubro !== false)
            this.getAreas();

        if (this.props.inversiones === undefined || this.props.inversiones !== false)
            this.getInversiones();


        if (this.props.razones_sociales === undefined || this.props.razones_sociales !== false)
            this.getRazonesSociales()

        if (this.props.clasificadores === undefined || this.props.clasificadores !== false)
            this.getClasificadores()

        if (this.props.negocios === undefined || this.props.negocios !== false)
            this.getNegocios()


        if (this.props.proveedores === undefined || this.props.proveedores !== false)
            this.getProveedores()

        if (this.props.haciendas === undefined || this.props.haciendas !== false)
            this.getHaciendas()

        if (this.props.estatus) {
            this.loadEstatus()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Obtener los filtros del url y setear los valores en el formulario
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            if (this.props.params.filters) {
                const filters = {}
                for (let filter of this.props.params.filters) {
                    if (filter.name === 'fecha') {
                        filters['fechas'] = filter.filter?.range?.map(f => moment(f))
                        continue
                    }


                    if (filters[filter.name]) {
                        if(filter.filter?._id)
                            filters[filter.name].push(filter.filter?._id)
                        else
                            filters[filter.name].push(filter?._id)
                    } else {
                        if(filter.filter?._id)
                            filters[filter.name] = [filter.filter?._id]
                        else
                            filters[filter.name] = [filter?._id]
                    }
                }

                this.formFiltros.current?.setFieldsValue(filters)
            } else {

            }
        }
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * 
     * @description Para obtener las cuentas en la lista
     */
    getCuentas = ({ page, limit, search } = this.state.cuentas, { cuentas } = this.state) => {

        cuentas.loading = true;
        cuentas.page = page;
        cuentas.limit = limit;

        this.setState({ cuentas })

        axios.get('/cuentas/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                cuentas.data = (page === 1) ? response.data.data.itemsList : [...cuentas.data, ...response.data.data.itemsList];

                cuentas.dictionary = {}
                cuentas.data.map(d => cuentas.dictionary[d._id] = d)

                cuentas.total = response.data.data.paginator.itemCount
                cuentas.pages = response.data.data.paginator.pageCount
                cuentas.loading = false;

                this.setState({ cuentas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.cuentas.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clientes]
     * @param {*} [{ clientes }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los clientes en la lista
     */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/clientes', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                clientes.data = (page === 1) ? response.data.data : [...clientes.data, ...response.data.data];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = response.data.total
                clientes.pages = response.data.pages
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clientes]
     * @param {*} [{ clientes }=this.state]
     * @memberof FiltrosDrawer
     * @method getClientes
     * 
     * @description Para obtener los vendedores en la lista
     */
    getVendedores = ({ page, limit, search } = this.state.vendedores, { vendedores } = this.state) => {

        vendedores.loading = true;
        vendedores.page = page;
        vendedores.limit = limit;

        this.setState({ vendedores })

        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
                // tipos: [2]
            }
        })
            .then(response => {
                vendedores.data = (page === 1) ? response.data.data.itemsList : [...vendedores.data, ...response.data.data.itemsList];

                vendedores.dictionary = {}
                vendedores.data.map(d => vendedores.dictionary[d._id] = d)

                vendedores.total = response.data.data.itemCount
                vendedores.pages = response.data.data.pageCount
                vendedores.loading = false;

                this.setState({ vendedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.vendedores.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.ordenes]
     * @param {*} [{ ordenes }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenes
     * 
     * @description Para obtener las ordenes en la lista
     */
    getOrdenes = ({ page, limit, search, clientes_ids } = this.state.ordenes, { ordenes } = this.state) => {

        ordenes.loading = true;
        ordenes.page = page;
        ordenes.limit = limit;
        ordenes.clientes_ids = clientes_ids

        this.setState({ ordenes })

        axios.get('/ordenes', {
            params: {
                page, limit, search, cliente_id: clientes_ids
            }
        })
            .then(response => {

                ordenes.data = (page === 1) ? response.data.data.itemsList : [...ordenes.data, ...response.data.data.itemsList];

                ordenes.dictionary = {}
                ordenes.data.map(d => ordenes.dictionary[d._id] = d)

                ordenes.total = response.data.data.paginator.itemCount
                ordenes.pages = response.data.data.paginator.pageCount
                ordenes.loading = false;

                this.setState({ ordenes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.ordenes_compras]
     * @param {*} [{ ordenes_compras }=this.state]
     * @memberof FiltrosDrawer
     * @method getOrdenesCompras
     * 
     * @description Para obtener las ordenes de compra en la lista
     */
    getOrdenesCompras = ({ page, limit, search, orden_id } = this.state.ordenes_compras, { ordenes_compras } = this.state) => {

        ordenes_compras.loading = true;
        ordenes_compras.page = page;
        ordenes_compras.limit = limit;

        this.setState({ ordenes_compras })

        axios.get('/ordenesCompra/list', {
            params: {
                page, limit, search, orden_id
            }
        })
            .then(response => {
                ordenes_compras.data = (page === 1) ? response.data.data.data : [...ordenes_compras.data, ...response.data.data.data];

                ordenes_compras.dictionary = {}
                ordenes_compras.data.map(d => ordenes_compras.dictionary[d._id] = d)

                ordenes_compras.total = response.data.data.total
                ordenes_compras.pages = response.data.data.pages
                ordenes_compras.loading = false;

                this.setState({ ordenes_compras })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.ordenes_compras.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.areas]
     * @param {*} [{ areas }=this.state]
     * @memberof FiltrosDrawer
     * @method getAreas
     * 
     * @description Para obtener las areas en la lista
     */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {

            let areas_dictionary = {}
            let areas = response.data.data.map(area => {

                areas_dictionary[area._id] = area
                return ({
                    value: area._id, label: area.nombre, isLeaf: false
                })
            })

            this.setState({
                areas,
                areas_dictionary
            })
        }).catch(error => {
            console.log('error al traer areas', error)
        })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.rubros]
     * @param {*} [{ rubros }=this.state]
     * @memberof FiltrosDrawer
     * @method loadRubros
     * 
     * @description Cargamos los rubros
     */
    loadRubros = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        let areas = this.state.areas

        return axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: targetOption.value
            }
        })
            .then(response => {
                targetOption.loading = false;

                let index = areas.findIndex(area => area.value.toString() === targetOption.value.toString())
                let rubros = response.data.data;

                if (index !== -1 && rubros.length > 0) {


                    let rubros_dictionary = {}
                    let areas_temp = rubros.map(rubro => {
                        rubros_dictionary[rubro._id] = rubro
                        return ({ label: rubro.nombre, value: rubro._id })
                    })

                    areas[index].children = areas_temp
                    this.setState({ areas: [...areas], rubros_dictionary: { ...this.state.rubros_dictionary, ...rubros_dictionary } });

                } else {
                    message.error('Rubros no encontrados')
                }


            })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar los rubros')
            })

    };

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.razones_sociales]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getRazonesSociales
     * 
     * @description Obtenemos las razones sociales
     */
    getRazonesSociales = ({ page, limit, search } = this.state.razones_sociales, { razones_sociales } = this.state) => {

        razones_sociales.loading = true;
        razones_sociales.page = page;
        razones_sociales.limit = limit;

        this.setState({ razones_sociales })

        axios.get('/razones-sociales', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                // console.log('response', response.data.data)
                razones_sociales.data = (page === 1) ? response.data.data.itemsList : [...razones_sociales.data, ...response.data.data.itemsList];

                razones_sociales.dictionary = {}
                razones_sociales.data.map(d => razones_sociales.dictionary[d._id] = d)

                razones_sociales.total = response.data.data.paginator.itemCount
                razones_sociales.pages = response.data.data.paginator.pageCount
                razones_sociales.loading = false;

                this.setState({ razones_sociales })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.razones_sociales.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.clasificadores]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method getClasificadores
     * 
     * @description Obtenemos los clasificadores
     */
    getClasificadores = ({ page, limit, search } = this.state.clasificadores, { clasificadores } = this.state) => {

        clasificadores.loading = true;
        clasificadores.page = page;
        clasificadores.limit = limit;
        this.setState({ clasificadores })

        axios.get('/clasificadores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                clasificadores.data = (page === 1) ? response.data.data.itemsList : [...clasificadores.data, ...response.data.data.itemsList];


                clasificadores.dictionary = {}
                clasificadores.data.map(d => clasificadores.dictionary[d._id] = d)

                clasificadores.total = response.data.data.paginator.itemCount
                clasificadores.pages = response.data.data.paginator.pageCount
                clasificadores.loading = false;

                this.setState({ clasificadores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clasificadores.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.negocios]
     * @param {*} [{ negocios }=this.state]
     * @memberof FiltrosDrawer
     * @method getNegocios
     * 
     * @description Obtenemos los Negocios
     */
    getNegocios = ({ page, limit, search } = this.state.negocios, { negocios } = this.state) => {

        negocios.loading = true;
        negocios.page = page;
        negocios.limit = limit;
        this.setState({ negocios })

        axios.get('/negocios/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                negocios.data = (page === 1) ? response.data.data.data : [...negocios.data, ...response.data.data.data];


                negocios.dictionary = {}
                negocios.data.map(d => negocios.dictionary[d._id] = d)

                negocios.total = response.data.data.total
                negocios.pages = response.data.data.pages
                negocios.loading = false;

                this.setState({ negocios })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.negocios.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getProveedores = ({ page, limit, search } = this.state.proveedores, { proveedores } = this.state) => {

        proveedores.loading = true;
        proveedores.page = page;
        proveedores.limit = limit;
        this.setState({ proveedores })

        axios.get('/proveedores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                proveedores.data = (page === 1) ? response.data.data.itemsList : [...proveedores.data, ...response.data.data.itemsList];


                proveedores.dictionary = {}
                proveedores.data.map(d => proveedores.dictionary[d._id] = d)

                proveedores.total = response.data.data.paginator.itemCount
                proveedores.pages = response.data.data.paginator.pageCount
                proveedores.loading = false;

                this.setState({ proveedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.proveedores.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getHaciendas = ({ page, limit, search } = this.state.haciendas, { haciendas } = this.state) => {

        haciendas.loading = true;
        haciendas.page = page;
        haciendas.limit = limit;
        this.setState({ haciendas })

        axios.get('/haciendas', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                haciendas.data = (page === 1) ? response.data.data.data : [...haciendas.data, ...response.data.data.data];


                haciendas.dictionary = {}
                haciendas.data.map(d => haciendas.dictionary[d._id] = d)

                haciendas.total = response.data.data.total
                haciendas.pages = response.data.data.page
                haciendas.loading = false;

                this.setState({ haciendas })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.haciendas.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getProveedores
    * 
    * @description Obtenemos los proveedores
    */
    getInversiones = ({ page, limit, search } = this.state.inversiones, { inversiones } = this.state) => {

        inversiones.loading = true;
        inversiones.page = page;
        inversiones.limit = limit;
        this.setState({ inversiones })

        axios.get('/inversiones', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                inversiones.data = (page === 1) ? response.data.data : [...inversiones.data, ...response.data.data];


                inversiones.dictionary = {}
                inversiones.data.map(d => inversiones.dictionary[d._id] = d)

                inversiones.total = response.data.total
                inversiones.pages = response.data.page
                inversiones.loading = false;

                this.setState({ inversiones })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.inversiones.loading = false
                    return state
                })
            })
    }

    loadEstatus = () => {
        const estatus = {}

        if (this.props.estatus.type !== 'tree') {
            estatus.data = this.props.estatus.data
            estatus.dictionary = {}
            estatus.data.map(d => estatus.dictionary[d._id] = d)
        }

        if (this.props.estatus.type === 'tree') {
            estatus.data = this.props.estatus.data
            estatus.dictionary = {}
            estatus.data.map(d => estatus.dictionary[d.value] = d)
        }


        this.setState({ estatus })
    }

    getParent = (name, element) => {

        let dictionary = this.state[name].dictionary
        let parent = null

        for (let key in dictionary) {
            let { children } = dictionary[key]
            if (!children) continue

            let child = children.find(child => child.value === element)
            if (!child) continue

            parent = {
                ...dictionary[key],
                children: [child]
            }

            break
        }

        return parent
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ area_rubro, clasificadores, clientes, haciendas, cuentas, ordenes, ordenes_compras, razones_sociales, estatus, fechas, vendedores, inversiones, vendedores_transaccion, monto }) => {

        let filtros = []

        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,//clasificador,
            name: name,
            objectName,

        }))

        if (clasificadores) addToFilter("clasificadores", "clasificadores", clasificadores)
        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (vendedores) addToFilter("vendedor_id", "vendedores", vendedores)
        if (cuentas) addToFilter("cuenta_id", "cuentas", cuentas)
        if (ordenes) addToFilter(["ordenes.orden_id", "ordenes.orden_id"], "ordenes", ordenes)
        if (ordenes_compras) addToFilter("ordenes_compra.orden_compra_id", "ordenes_compras", ordenes_compras)
        if (razones_sociales) addToFilter("razon_social_id", "razones_sociales", razones_sociales)
        if (estatus) addToFilter("estatus", "estatus", estatus)
        if (haciendas) addToFilter("hacienda_id", "haciendas", haciendas)
        if (inversiones) addToFilter("inversion_id._id", "inversiones", inversiones)
        if (vendedores_transaccion) addToFilter("responsable_id", "vendedores", vendedores_transaccion)
        if (monto) filtros.push({
            name: "monto",
            objectName: "monto",
            filter: monto
        })

        if (fechas) filtros.push({
            name: "fecha",
            objectName: "fechas",
            filter: {
                range: fechas.map(f => moment(f).format("YYYY-MM-DD"))
            }
        })

        if (area_rubro)
            for (const ar of area_rubro) {

                if (ar.length === 1) {
                    filtros.push({
                        _id: ar[0],//clasificador,
                        name: "area_id",
                        objectName: "areas",
                        filter: this.state.areas_dictionary[ar[0]]
                    })
                } else {
                    for (let index = 0; index < ar.length; index++) {
                        if (index === 0) continue;
                        const element = ar[index];
                        filtros.push({
                            _id: element,//clasificador,
                            name: "rubro_id",
                            objectName: "rubros",
                            filter: this.state.rubros_dictionary[element]
                        })
                    }
                }
            }

        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
        // this.setState({ filtros })
    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method downloadCSV
     */
    downloadCSV = () => {
        const { filters } = this.props.params
        let url = new URL(axios.defaults.baseURL + '/' + this.props.csv)
        if (filters)
            url.searchParams.set('filters', JSON.stringify(filters))
        url.searchParams.set('csv', true)
        url.searchParams.set('Authorization', sessionStorage.getItem('token'))

        if (this.props.hacienda_id)
            url.searchParams.set('hacienda_id', this.props.hacienda_id)

        window.open(url, '_blank');
    }

    render() {

        const { onClose, visible } = this.props
        const { clientes, vendedores, cuentas, razones_sociales, clasificadores, ordenes, ordenes_compras, negocios, proveedores, estatus, haciendas, inversiones } = this.state

        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
                (this.props.csv !== undefined) ?
                    <Button
                        onClick={this.downloadCSV}
                        icon={<DownloadOutlined color='#' />}
                    >CSV</Button>
                    : null
            }>


            <Form
                ref={this.formFiltros}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >
                {(this.props.fechas === undefined || this.props.fechas !== false) && (
                    <Form.Item label="Rango de Fechas de Creación/Registro" name="fechas" style={{ width: "100%" }} >
                        <RangePicker placeholder={["Fecha Inicial", "Fecha Final"]}  style={{ width: "100%" }} />
                    </Form.Item>
                )}
                {(this.props.cuentas === undefined || this.props.cuentas !== false) ?
                    <Form.Item label="Cuentas" name="cuentas">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !cuentas?.loading && cuentas.page < clientes.pages) ? this.getCuentas({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getCuentas({ search, page: 1 })}
                        >
                            {cuentas.data.map(cuenta => <Option key={cuenta._id} value={cuenta._id}>
                                <CAvatar
                                    size='small'
                                    image={cuenta.logo}
                                    name={cuenta.nombre}
                                    color={cuenta.color}
                                /> &nbsp;{cuenta.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}

                {(this.props.clientes === undefined || this.props.clientes !== false) ?
                    <Form.Item label="Clientes" name="clientes">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}

                            onSelect={() => this.getOrdenes({ clientes_ids: this.formFiltros.current?.getFieldValue('clientes'), page: 1 })}
                        >
                            {clientes.data.map(cliente => <Option key={cliente._id} value={cliente._id}>{cliente.razon_social || cliente.nombre ? `${cliente.nombre} ${cliente.apellido_paterno} ${cliente.apellido_materno}` : null || cliente.email}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.vendedores === undefined || this.props.vendedores != false) ?
                    <Form.Item label="Vendedores" name="vendedores">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !vendedores?.loading && vendedores.page < vendedores.pages) ? this.getVendedores({ page: vendedores.page + 1 }) : null}
                            onSearch={search => this.getVendedores({ search, page: 1 })}
                        >
                            {vendedores.data.map(vendedor => <Option key={vendedor._id} value={vendedor._id}>{vendedor.nombre || vendedor.nombres} {vendedor.email}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.vendedores_transaccion === undefined || this.props.vendedores_transaccion !== false) ?
                    <Form.Item label="Vendedores" name="vendedores_transaccion">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !vendedores?.loading && vendedores.page < vendedores.pages) ? this.getVendedores({ page: vendedores.page + 1 }) : null}
                            onSearch={search => this.getVendedores({ search, page: 1 })}
                        >
                            {vendedores.data.map(vendedor => <Option key={vendedor._id} value={vendedor._id}>{vendedor.nombre || vendedor.nombres} {vendedor.email}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.ordenes === undefined || this.props.ordenes !== false) ?
                    <Form.Item label="Ventas / Ordenes" name="ordenes">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes?.loading && ordenes.page < ordenes.pages) ? this.getOrdenes({ page: ordenes.page + 1 }) : null}
                            onSearch={search => this.getOrdenes({ search, page: 1 })}

                            onSelect={() => this.getOrdenesCompras({ orden_id: this.formFiltros.current?.getFieldValue('ordenes'), page: 1 })}
                        >
                            {ordenes.data.map(orden => <Option key={orden._id} value={orden._id}>{orden.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.ordenes_compras === undefined || this.props.ordenes_compras !== false) ?
                    <Form.Item label="Ordenes de Compra" name="ordenes_compras">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}

                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !ordenes_compras?.loading && ordenes_compras.page < ordenes_compras.pages) ? this.getOrdenesCompras({ page: ordenes_compras.page + 1 }) : null}
                            onSearch={search => this.getOrdenesCompras({ search, page: 1 })}
                        >
                            {ordenes_compras.data.map(orden_compra => <Option key={orden_compra._id} value={orden_compra._id}>{orden_compra.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.area_rubro === undefined || this.props.area_rubro !== false) ?
                    <Form.Item label="Áreas / Rubros" name="area_rubro">
                        <Cascader
                            multiple={true}
                            placeholder="Seleccione el Área y Rubro"
                            options={this.state.areas}
                            loadData={this.loadRubros}
                            changeOnSelect
                        />
                    </Form.Item> : null}
                {(this.props.razones_sociales === undefined || this.props.razones_sociales !== false) ?
                    <Form.Item label="Razones Sociales" name="razones_sociales">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !razones_sociales?.loading && razones_sociales.page < razones_sociales.pages) ? this.getRazonesSociales({ page: razones_sociales.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                        >
                            {razones_sociales.data.map(razon_social => <Option key={razon_social._id} value={razon_social._id}>        <CAvatar
                                size='small'
                                image={razon_social.logo}
                                name={razon_social.razon_social}
                                color={razon_social.color}
                            /> &nbsp; {razon_social.razon_social}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.clasificadores === undefined || this.props.clasificadores !== false) ?
                    <Form.Item label="Clasificadores" name="clasificadores">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                        >
                            {clasificadores.data.map(clasificador => <Option key={clasificador._id} value={clasificador._id}><Badge color={clasificador.color} />{clasificador.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.negocios === undefined || this.props.negocios !== false) ?
                    <Form.Item label="Negocios" name="negocios">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !negocios?.loading && negocios.page < negocios.pages) ? this.getNegocios({ page: negocios.page + 1 }) : null}
                            onSearch={search => this.getNegocios({ search, page: 1 })}
                        >
                            {negocios?.data?.map(negocio => <Option key={negocio._id} value={negocio._id}><Badge color={negocio.color} />{negocio.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.proveedores === undefined || this.props.proveedores !== false) ?
                    <Form.Item label="Proveedores" name="proveedores">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !proveedores?.loading && proveedores.page < proveedores.pages) ? this.getProveedores({ page: proveedores.page + 1 }) : null}
                            onSearch={search => this.getProveedores({ search, page: 1 })}
                        >
                            {proveedores.data.map(proveedor => <Option key={proveedor._id} value={proveedor._id}><Badge color={proveedor.color} />{proveedor.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.haciendas === undefined || this.props.haciendas !== false) ?
                    <Form.Item label="Haciendas" name="haciendas">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !haciendas?.loading && haciendas.page < haciendas.pages) ? this.getHaciendas({ page: haciendas.page + 1 }) : null}
                            onSearch={search => this.getHaciendas({ search, page: 1 })}
                        >
                            {haciendas.data.map(hacienda => <Option key={hacienda._id} value={hacienda._id}>{hacienda.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.inversiones === undefined || this.props.inversiones !== false) ?
                    <Form.Item label="Inversiones" name="inversiones">
                        <Select
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !inversiones?.loading && inversiones.page < inversiones.pages) ? this.getInversiones({ page: inversiones.page + 1 }) : null}
                            onSearch={search => this.getInversiones({ search, page: 1 })}
                        >
                            {inversiones.data.map(inversion => <Option key={inversion._id} value={inversion._id}>{inversion.folio}</Option>)}
                        </Select>
                    </Form.Item> : null}
                {(this.props.estatus) &&
                    (this.props.estatus?.type === "tree" ? (
                        <Form.Item label="Estatus" name="estatus">
                            <TreeSelect
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                treeData={estatus.data}
                                treeDefaultExpandAll
                                multiple
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item label="Estatus" name="estatus">
                            <Select
                                filterOption={false}
                                mode="multiple"
                            >
                                {estatus.data.map(option => <Option key={option._id} value={option._id}>{option.label}</Option>)}
                            </Select>
                        </Form.Item>
                    ))
                }
                {(this.props?.monto !== false) ?
                    <Form.Item label="Monto" name="monto">
                        <InputNumber
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            controls={false}
                            precision={2}
                            className='w-100'
                        />
                        
                    </Form.Item> : null}
            </Form>
        </Drawer >)
    }
};


export default React.forwardRef((props, ref) => {

    const [params, setParams] = useSearchParams()

    return <FiltrosDrawer ref={ref} {...props} params={params} />
})