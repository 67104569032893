import React from 'react'
import { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import Dashboard from '../../Components/Admin/Nominas/Dashboard';
import EmpleadosNomina from '../../Components/Admin/Nominas/EmpleadosNomina';
import EmpleadosFinanzas from '../../Components/Admin/Nominas/EmpleadosFinanzas';
import ListadoNominas from '../../Components/Admin/Nominas/ListadoNominas';


/**
 * @export
 * @function AdminRoutes
 * @extends {Component}
 */
function RouterNominas(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])
    
    return (
        <Routes>
            <Route path="lista" element={<ListadoNominas  {...props}  />} />
            <Route path="nomina/finanzas/:nomina_id" element={<EmpleadosFinanzas   {...props}  />} />
            <Route path="nomina/empleados/:nomina_id" element={<EmpleadosNomina   autorizar={false}{...props}  />} />
            <Route path="nomina/empleados/autorizar/:nomina_id" element={<EmpleadosNomina    autorizar={true}{...props}  />} />
            <Route path="" element={<Dashboard  {...props}  />} />
        </Routes>
    )
}

export default RouterNominas;