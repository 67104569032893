import { DownOutlined } from "@ant-design/icons"
import { Button, Drawer, Dropdown, Menu, Space, Typography, message } from "antd"
import axios from "axios"
import { useContext } from "react"
import { Component } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import User, { SetUser } from "../../Hooks/Logged"

const { Text } = Typography

class MobileMenu extends Component {
    
    constructor(props){
        super(props)
        this.state = {

        }
    }

    render(){
        const { t } = this.props
        const menu = (
			<Menu>
				<Menu.Item onClick={() => {this.props.changeLanguage("en"); this.props.updateText()}}>
					English
				</Menu.Item>
				<Menu.Item onClick={() => {this.props.changeLanguage("es"); this.props.updateText()}}>
					Español
				</Menu.Item>
			</Menu>
		);
        return (
            <Drawer
                className={"header-drawer"}
                theme="dark"
                placement={"left"}
                closable={false}
                onClose={this.props.onClose}
                open={this.props.open}
                key={"left"}
            >
                <Menu 
                    className="header-menu width-100" 
                    theme="dark" 
                    mode="vertical" 
                    defaultSelectedKeys={['1']} 
                    triggerSubMenuAction="click"
                    overflowedIndicator={null}
                    inlineCollapsed={false}
                >
                    <Menu.Item key="1">
                        <Link to="/" className="text-white">
                            {t("header.home")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <a href="#about-us">
                            <Text className="text-white">{t("header.about_us")}</Text>
                        </a>
                    </Menu.Item>
                    {/* <Menu.Item key="3">
                       <a href="#faq">
                            <Text className="text-white">{t("header.faq")}</Text>
                        </a>
                    </Menu.Item> */}
                    <Menu.Item key="4">
                       <a href="#contact-us">
                            <Text className="text-white">{t("header.contact")}</Text>
                        </a>
                    </Menu.Item>

                    <Menu.Item key="5" >
                            <Link to="/tyc#start">
                                <Text className="text-white">{t("header.terms")}</Text>
                            </Link>
                    </Menu.Item>
                    <Menu.Item key="6" >
                        <a href="#haciendas">
                            <Text className="text-white">{t("header.ranchs")}</Text>
                        </a>
                    </Menu.Item>
                    <Menu.Item key="7" disabled>
                        <Space>
                            <Text className="text-cyan-light">{t("header.price")}</Text>
                            <Text strong className="text-white">$ {this.props.tarifa?.toMoney ? this.props.tarifa?.toMoney(true) : this.props.tarifa} MXN kg</Text>
                            <Dropdown overlay={menu}>
                                <Button type="link" ghost className="ant-dropdown-link text-white" onClick={e => e.preventDefault()}>
                                {t("header.lang")} <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Space>
                    </Menu.Item>
                    {
                        this.props.user ? (
                            <Menu.Item key="8">
                                <Button 
                                    className="btn-green w-100"
                                    onClick={() => this.props.navigate(this.props.user?.rol_id ? "/admin/dashboard" : "/customer/resume")}
                                >
                                    {t("header.account")}
                                </Button>
                            </Menu.Item>
                        ) : null
                    }
                    {
                        this.props.user ? (
                            <Menu.Item key="9">
                                <Button 
                                    className="btn-green w-100"
                                    onClick={() => {
                                        axios.get('/logout')
                                            .then(() => {
                                                this.props.setUser(0)
                                                message.success(t("header.closed_session"))
                                            })
                                            .catch(error => {
                                                console.log(error)
                                                message.error(t("header.logout_error"))
                                            })
                                    }}
                                >
                                    {t("header.logout")}
                                </Button>
                            </Menu.Item>
                        ) : null
                    }
                    {
                        !this.props.user && (
                            <Menu.Item key="10">
                                <Button className="btn-green out w-100" onClick={() => this.props.openAuth()}>{t("header.invest")}</Button>
                            </Menu.Item>
                        )
                    }
                    
                </Menu>
            </Drawer>
        )
    }
}

export default (props) => {
    const { t, i18n } = useTranslation();
    const setUser = useContext(SetUser)
    let user = useContext(User)
    const navigate = useNavigate()

	const changeLanguage = lng => i18n.changeLanguage(lng)

    return <MobileMenu changeLanguage={changeLanguage} setUser={setUser} {...props} navigate={navigate} user={user} t={t}/>
}