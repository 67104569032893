 import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import Categorias from '../../Components/Admin/Categorias/Categorias';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterTamanos(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Categorias  {...props}  />} />
        </Routes>
    )
}

export default RouterTamanos;