import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Spin, Typography, Upload, Select,Switch } from 'antd';


import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";

const axios = require('axios').default;


const { Title, } = Typography;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
/**
 *
 *
 * @export
 * @class FormProducto 
 * @extends {Component}
 */
class FormProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fileList: [],
            categorias: {
                data: [],
                loading: false,
                page: 1,
                total: 0,
                limit: 50,
                search: ''
            }

        }
    }

    formRef = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCategorias()
        if (this.props.id !== null) {
            this.getProducto()
        }
    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method get
     * @description Obtenemos el producto
     * @param {string} id Identificador del producto
     */
    getProducto = (id = this.props.id) => {
        this.setState({ loading: true })
        axios.get("/productos/get", {
            params: { id }
        })
            .then(async ({ data }) => {
                data = data.data;
                

                this.formRef.current.setFieldsValue({
                    ...data,
                    categoria_id: data.categoria_id?._id,
                    imagenes: data.imagenes.map((imagen, index) => ({
                        uid: -index,
                        name: imagen.name,
                        filename: imagen.filename,
                        status: 'done',
                        url: axios.defaults.baseURL + '/upload/' + imagen.filename
                    }))
                })
            })
            .catch(res => {
                message.error('No se pudo cargar el Producto')
                
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    *
    *
    * @memberof FormProducto
    * @method getCategorias
    * @description Obtiene el listado de categorias
    */
    getCategorias = ({
        page = this.state.categorias.page,
        limit = this.state.categorias.limit,
        search = this.state.categorias.search,
    } = this.state.categorias) => {
        this.setState(state => {
            state.categorias.loading = true;
            state.categorias.page = page;
            state.categorias.limit = limit;
            return state;

        })
        axios.get('/categorias', {
            params: {
                page, limit, search
            }
        })
            .then(response => {
                
                this.setState(state => {
                    if (page === 1)
                        state.categorias.data = response.data.data.itemsList
                    else
                        state.categorias.data = [...state.categorias.data, ...response.data.data.itemsList]

                    state.categorias.total = response.data.data.paginator.itemCount
                    state.categorias.pages = response.data.data.paginator.pageCount
                    state.categorias.loading = false
                    return state;
                })
            })
            .catch(error => {
                
                message.error('Error al traer la información')
            })

    }


    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method onFinish
     * @description Cuando se guarda el producto
     * @param {object} values - Objecto que contiene los valores del formulario
     */
    onFinish = values => (this.props.id !== null) ? this.update(values) : this.save(values)

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method save
     * @description Guardamos el producto
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     */
    save = (values) => {

        if (this.state.loading) return
        this.setState({ loading: true })

        axios.post('/productos/add', { ...values, id: values._id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el producto!")
                this.props.onClose();
            })
            .catch((e) => {
                let msj = 'No se pudo guardar el producto'
                if (e.response.status === 403) {
                    msj = e.response.data.message
                }
                message.error(msj)
                
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }

    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method update
     * @description Actualizamos el producto.
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/productos/update', { ...values, id: this.props.id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el producto!")
                this.props.onClose();
            })
            .catch((e) => {
                let msj = 'No se pudo actualizar el producto'
                if (e.response.status === 403) {
                    msj = e.response.data.message
                }
                message.error(msj)
                
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    /**
     *
     * @memberof FormProducto
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                
            })
            .catch(res => {
                
            })
    };

    /**
     *
     * @memberof FormProducto
     *
     * @method normFile
     * @description  declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }
        }
        if (file.status === "removed")
            this.removeFile((file?.response?.filename) ? file.response.filename : file.filename);
        return e.fileList;
    };

    render() {
        const { normFile, formRef, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.id ? "Editar Producto" : "Nuevo Producto"}</Title>
                <Form
                    {...formItemLayout}
                    layout="vertical"
                    ref={formRef}
                    initialValues={form}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[
                            { required: true, message: "Ingrese el nombre del producto" }
                        ]} >
                        <Input placeholder="Nombre" />
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                        rules={[
                            { required: true, message: "Ingrese la descripción del producto" }
                        ]} >
                        <Input.TextArea placeholder="Descripción" maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        label="SKU"
                        name="sku"
                        rules={[
                            { required: true, message: "Ingrese el SKU" }
                        ]} >
                        <Input placeholder="SKU" />
                    </Form.Item>
                    <Form.Item
                        label="Categoría"
                        name="categoria_id"
                        rules={[
                            { required: true, message: "Ingrese la categoría" }
                        ]} >
                        <Select >
                            {this.state.categorias.data.map(item => <Option key={item._id} value={item._id}>{item.nombre}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Imagenes del Producto"
                        name="imagenes"
                        getValueFromEvent={normFile}
                        valuePropName="fileList"
                    >
                        <Upload
                            action={axios.defaults.baseURL + "/upload/add"}
                            multiple={true}
                            listType="picture-card"
                        >
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Subir Archivo</div>
                            </div>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label="Activo"
                        name="activo"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading} >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            className="modal-zeus"
        >
            <FormProducto {...props} />
        </Modal>
    )
}
