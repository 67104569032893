import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, } from 'antd';

const { Title, Text } = Typography;
const moment = require('moment');
const axios = require('axios').default;






/**
 *
 *
 * @class FormCompraConfirm
 * @extends {Component}
 */
class FormCompraConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            transaccion: {}
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.transaccion?._id) {
            this.getTransaccion()
        }
    }

    /**
    * @method getTransaccion
    * @description Obtiene la informacion del transaccion
    */
    getTransaccion = () => {
        this.setState({ loading: true })
        axios.get('/transaccion', {
            params: {
                id: this.props.transaccion?._id,
                movimiento: true
            }
        }).then(response => {
            this.setState({ transaccion: response.data.data })
        }).catch(error => {
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Confirma la transaccion
    */
    onFinish = () => {
        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/transaccion/confirmar', {
                id: this.props.transaccion?._id,
            }).then(response => {
                message.success('Transacción Confirmada')
                this.props.onCancel()
            }).catch(error => {
                
                let msg = 'Error al Confirmar la Transacción'
                if (error.response?.status === 403)
                    msg = error.response.data.message
                message.error(msg)
            }).finally(() => this.setState({ loading: false }))
        })
    }


    render() {

        const { transaccion } = this.state

        return (
            <Form
                layout="vertical"
                ref={this.formRef}
                onFinish={this.onFinish}
                style={{ padding: '1em' }}
            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item label="Concepto" >
                                <Text>{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item label="Descripción" >
                                <Text>{transaccion.descripcion}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} >
                            <Form.Item label="Fecha" >
                                <Text>{moment(transaccion.createdAt).format('DD-MM-YYYY')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item label="Tipo" >
                                <Text>{transaccion.tipo === 1 ? 'Compra' : 'Venta'}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item label="Estatus" >
                                {transaccion.estatus === 1 ? <Text type="danger">No Confirmada</Text> : <Text type="success">Confirmada</Text>}
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item label="Hacienda" >
                                <Text>{transaccion.hacienda_id?.nombre}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item label="Plantas" >
                                <Text>{transaccion.movimiento?.cantidad}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item label="Referencia" >
                                <Text>{transaccion.referencia}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item label="Usuario"  >
                                <Text>{transaccion.usuario_id?.nombre} {transaccion.usuario_id?.apellidos}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item label="Comprobantes"  >
                                {
                                    transaccion.comprobantes?.map(doc => <a className="mr-1" target="_blank" href={`${axios.defaults.baseURL}/upload/${doc?.filename_server}`}>{doc?.filename}</a>)
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className="center">
                            <Form.Item className="m-0">
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Confirmar
                                </Button>
                            </Form.Item>
                            <Button danger className="ml-1" onClick={() => this.props.onCancel()}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Hacienda</Title>
        </div>
        <FormCompraConfirm {...props} />
    </Modal>

}