import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card } from 'antd'
import axios from 'axios';
import { IconBtnDelete, IconBtnEdit } from '../../Widgets/Iconos'
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'

import ModalTamano from './ModalTamano';
const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Tamanos
 * @extends {Component}
 * @description Vista donde se listan todos los Tamanos
 */
export default class Tamanos extends Component {



    /**
     *Creates an instance of Tamanos.
     * @param {*} props
     * @memberof Tamanos
     */
    constructor(props) {
        super(props)
        this.state = {
            sizes: [],
            page: 1,
            limit: 10,
            total: 0,

            producto_selected: null,
            search: this.props.search
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getSizes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.setState({ search: this.props.search }, () => this.getSizes())
        }
    }

    /**
    * @method getSizes
    * @param {number} page Numero de pagina listada
    * @description Obtiene ls lista de Tamanos
    */
    getSizes = (page = this.state.page) => {
        this.setState({ loading: true })
        axios.get('/sizes', {
            params: {
                page: page,
                search: this.state.search
            }
        }).then(response => {
            console.log('sizes list', response.data.data)
            this.setState({
                sizes: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
            })
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader className="admin-page-header" title="Tamaños" />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Tamaños" }}
                            dataSource={this.state.sizes}
                            pagination={{
                                onChange:(page)=>this.getSizes(page),
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            header={<Row className="width-100" justify='space-between'>
                                <Col span={4} className="center">
                                    <Text strong>Tamaño</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " justify='space-between'>
                                            <Col span={4} className="center">
                                                <Text strong>{item.size}</Text>
                                            </Col>
                                            <Col span={2} className="center">
                                                <Space>

                                                    <Button type="primary" className='btn-secondary' icon={<IconBtnEdit />} onClick={() => this.setState({ modal_visible: true, tamano_selected: item._id })} />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este tamaño?"
                                                        onConfirm={() => axios.delete('/sizes/delete', { params: { id: item._id } }).then((response) => {
                                                            message.success(response?.data?.message)
                                                            this.getSizes(this.state.page)
                                                        })
                                                            .catch((error) => {
                                                                message.error(error?.response?.data?.message);
                                                                this.getSizes(this.state.page);
                                                            })
                                                        }
                                                        okText="Sí"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" className='btn-delete' danger title="Eliminar" icon={<IconBtnDelete />} />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                </Spin>
                <FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modal_visible: true, tamano_selected: null })} />
                <ModalTamano
                    visible={this.state.modal_visible}
                    id={this.state.tamano_selected}
                    onClose={() => {
                        this.setState({ modal_visible: false, tamano_selected: null })
                        this.getSizes(this.state.page)
                    }}
                />
            </>
        )
    }
}
