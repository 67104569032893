import React from 'react';
import { Card, Row, Col, Typography, Input, InputNumber, Button, Form, Avatar, message, Checkbox, Popconfirm, Select, Empty, Space, Statistic } from 'antd'
import { LightenDarkenColor } from 'lighten-darken-color';
import { IconAgabe, IconDown, IconUp, IconTrashVenta, IconBtnEdit, IconCashier } from './Iconos'
import '../../Styles/Global/cards.css'
import './cards.css'
import { ReloadOutlined } from '@ant-design/icons';

import { FaArrowRight } from 'react-icons/fa'
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi'

const { Text, Title } = Typography;
const axios = require('axios');
const moment = require('moment')


/**
 *
 *
 * @const CardHacienda
 * @extends {React.Component}
 * @description Card de la grafica circular
 */
const CardHacienda = ({ item, color = "#D98F39", onClick = () => {}, content, key }) => {

    if (item.estatus == 3) {
        color = "#FF0000"
        // onClick = null
    }


    return <div 
        className='contenedor hover' 
        key={key} 
        onClick={onClick}
    >
        {/* {console.log(item)} */}
        {item.estatus == 3 ? <Row className='banner center'>
            <svg height='100%' width='100%'>
                <filter id='shadow' color-interpolation-filters="sRGB">
                    <feDropShadow dx="2" dy="-1" stdDeviation="3" flood-opacity="0.15" />
                </filter>
                <g filter='url(#shadow)'>
                    <rect y="175" x="-230" width='600' height='50' style={{ fill: 'red', rotate: '-30deg' }}></rect>
                    <text x='-60' fontSize="30" y='210' style={{ rotate: "-30deg" }} fill='white'>SOLD OUT</text>
                </g>

            </svg>
        </Row> : null}


        <Card
            size="small"
            hoverable
            title={
                <Title level={3} className="card-hacienda-title">
                    {item.nombre}
                </Title>
            }
            className={`card-hacienda  card-debajo`}

            style={{ background: `linear-gradient(204.67deg, ${LightenDarkenColor(color, 50)} 9.43%, ${color} 84.49%)` }}
        >
            {content}
            <IconAgabe className="agabe-svg" />
        </Card>
    </div>
}

/**
 *
 *
 * @const CardInfoHacienda
 * @extends {React.Component}
 * @description Card de la informacion/detalles de la hacienda
 */
const CardInfoHacienda = ({
    nombre = "",
    descripcion = "",
    fecha_creacion = moment().format('DD-MM-YYYY'),
    hectareas = 0,
    rendimiento = 0,
    localization = null,
    extra = null
}) => {

    return <Card
        className="card-info"
        title={<>{nombre}<br /> <small className="text-gray">{descripcion}</small></>}
        extra={extra}
    >
        <Row>
            <Col span={24}><Text className='text-gray'>Creado en {moment(fecha_creacion).year()}</Text></Col>
            <Col span={24}><Text className='text-gray'>{localization?.place_name || 'Sin Ubicación'}</Text></Col>
            <Col span={24}><Text className='text-gray'>Edad: {moment(fecha_creacion).fromNow(true)}</Text></Col>
            <Col span={24}><Text className='text-gray'>Hectareas: {hectareas.toMoney && hectareas.toMoney(true)} </Text></Col>
            <Col span={24}><Text className='text-gray'>Rendimiento : {rendimiento}%</Text></Col>
        </Row>
    </Card>
}

/**
 *
 *
 * @const CardPlantasHacienda
 * @extends {React.Component}
 * @description Card de las plantas de la hacienda
 */
const CardPlantasHacienda = ({
    plantas = 0,
    disponible = 0,
    precio = 0.00,
    extra = null
}) => {
    return <Card
        className="card-info"
        title={<> Plantas</>}
        extra={extra}
    >
        <Row >
            <Col span={12}>
                <Text className="text-cyan">Total de Planta   : </Text>
            </Col>
            <Col span={12} className="flex-right">
                <Text>{plantas.toMoney && plantas.toMoney(true)}</Text>
            </Col>
            <Col span={12}>
                <Text className="text-cyan">Plantas Disponibles: </Text>
            </Col>
            <Col span={12} className="flex-right">
                <Text>{disponible.toMoney && disponible.toMoney(true)}</Text>
            </Col>
            <Col span={12}>
                <Text className="text-cyan">Plantas Vendidas   : </Text>
            </Col>
            <Col span={12} className="flex-right">
                <Text>{(plantas - disponible).toMoney(true)}</Text>
            </Col>
            <Col span={12}>
                <Text className="text-cyan">Precio por planta  : </Text>
            </Col>
            <Col span={12} className="flex-right">
                <Text>$ {precio?.toMoney && precio?.toMoney(true)} MXN</Text>
            </Col>
        </Row>
    </Card >
}

/**
 *
 *
 * @param {*} { saveValues, values }
 * @return {*} 
 * @name CardPlantasValores
 * @description Refleja y modifica los valores de precio por kilo y kilos por planta
 */
const CardPlantasValores = ({ setValues, values, reset }) => {

    const formRef = React.useRef()

    React.useEffect(() => {
        formRef.current?.setFieldsValue(values)
    }, [values])


    return <Card
        title={"Plantas"}
        className="card-info card-info-extra width-100"
        extra={<Button size="small" icon={<ReloadOutlined />} onClick={reset}>Reset</Button>}
    >
        <Form
            onValuesChange={(_, values) => {
                setValues(values)
            }}
            className="width-100"
            layout={'vertical'}
            ref={formRef}
        >
            <Form.Item
                label="Precio por kilogramo"
                name="precio"
                style={{
                    marginBottom: 14,
                    marginTop: -20
                }}
            >
                <InputNumber size="middle" className="width-100" />
            </Form.Item>
            <Form.Item
                label="Kilogramos por Planta"
                name="cantidad"
                style={{
                    marginBottom: 0
                }}
            >
                <InputNumber size="middle" className="width-100" />
            </Form.Item>
        </Form>
    </Card>
}

/**
 *
 *
 * @const CardStatistics
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard principal
 */
const CardStatistics = ({ monto = 0, icon, color = "#0047FF", title, suffix, onClick, percent }) => {

    let aumento = percent !== undefined && !(percent < 0)

    return <Card size="small" className="card-statistics">
        <Row align={"middle"} className="card-header">
            <Col flex="auto">
                <Text className="card-title">{title}</Text>
            </Col>
            <Col flex="none">
                {
                    onClick && (
                        <Button
                            type="primary"
                            className='btn-primary'
                            icon={<FaArrowRight />}
                            onClick={onClick}
                        />
                    )
                }
            </Col>
        </Row>
        <Row align={"middle"} className="card-content">
            <Col flex="auto">
                <Space size={2}>
                    <Text className="card-monto">$ {monto}</Text>
                    <Text className="card-suffix">{suffix}</Text>
                </Space>
            </Col>
            <Col flex="none" className="center">
                {
                    icon && (
                        <div
                            className="icon-container"
                            style={{
                                backgroundColor: color + "2A"
                            }}
                        >
                            {
                                typeof icon === 'function' ? icon(color) : icon
                            }
                        </div>
                    )
                }
            </Col>
        </Row>
        {
            percent !== undefined && (
                <div className={`card-percent ${aumento ? "green" : "red"}`}>
                    <Space align='center'>
                        {
                            aumento ? <FiArrowUpRight className='card-icon' color='#00CD98' /> : <FiArrowDownRight className='card-icon' color='#FF4D4F' />
                        }
                        <Text className="card-monto">{aumento ? "+" : "-"} {Math.abs(percent)}%</Text>
                    </Space>
                </div>
            )
        }
    </Card>
}

/**
 *
 *
 * @const CardStatisticsSmall
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard de POS
 */
const CardStatisticsSmall = ({ monto = 0, icon, title, porcentaje, money = true }) => {


    const renderPorcentaje = (value) => {
        if (value < 0) {
            return <><Text className="ml-1 mr-1" type="danger">{value} %</Text><IconDown /></>
        } else if (value > 0) {
            return <><Text className="ml-1 mr-1" type="success">{value} %</Text><IconUp /></>
        } else {
            return ''
        }
    }


    return <Card size="small" className="card-statistics-small">
        <Row>
            <Col span={6} >
                <div className="icon-container">{icon}</div>
            </Col>
            <Col span={18} className="flex-left">
                {renderPorcentaje(porcentaje)}
            </Col>
            <Col span={24}>
                <Text className="card-monto">{money ? '$' : ''} {(monto.toMoney) ? monto.toMoney(true) : 0}</Text>
            </Col>
            <Col span={24}>
                <Text className="card-title">{title}</Text>
            </Col>
        </Row>
    </Card>
}

/**
 *
 *
 * @const CardProducto
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard de POS
 */
const CardProducto = ({ variante = {}, precio = 0, disponibles = 0, imagenes = [], nombre = "Sin Nombre", sku = "", addProducto }) => {
    return <Card size="small" className="card-product hover" disabled={parseInt(disponibles) === 0}>
        <span onClick={() => parseInt(disponibles) === 0 ? message.info('No hay suficiente productos') : addProducto(variante)}>
            <Row>
                <Col span={24} className="product-container">
                    <Avatar size={100} className="product-image" src={imagenes[0]?.filename ? axios.defaults.baseURL + '/upload/' + imagenes[0]?.filename : 'https://via.placeholder.com/300.png/09f/fff'}></Avatar>
                </Col>
                <Col span={24} className="center">
                    <Text className="product-description">{nombre}</Text>
                </Col>
                <Col span={24} className="center">
                    <Text className="product-description">SKU: {sku}</Text>
                </Col>
                <Col span={24} className="center">
                    <Text className="card-monto">$ {precio ? precio : ''}</Text>
                </Col>
                <Col span={24} className="center">
                    <Text className="card-title">{disponibles} disponibles</Text>
                </Col>
            </Row>
        </span>
    </Card>
}

/**
 *
 *
 * @const CardProductoVenta
 * @extends {React.Component}
 * @description Card para el producto de la lista de orden del POS
 */
const CardProductoVenta = ({
    id = null,
    variante_id = null,
    nombre = 'Agave',
    descripcion = '',
    precio = 0,
    cantidad = 1,
    imagenes = [],
    deleteProducto,
    onChangeCantidad }) => {
    return <Col span={24} className="card-producto-venta">
        <Row >
            <Col span={24} className="">
                <Row gutter={[48, 16]} justify='space-between'>
                    <Col xxl={{ span: 4, order: 1 }} xl={{ span: 8, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }}>
                        <img className='product-image' src={imagenes[0]?.filename ? axios.defaults.baseURL + '/upload/' + imagenes[0]?.filename : 'https://via.placeholder.com/300.png/09f/fff'} alt="producto" width="40" />
                    </Col>
                    <Col xxl={{ span: 11, order: 2 }} xl={{ span: 16, order: 2 }} lg={{ span: 12, order: 2 }} md={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} className="flex-left-column ">
                        <Text ellipsis className="producto-descripcion">{nombre}</Text>
                        <Text ellipsis className="producto-price">$ {precio}</Text>
                    </Col>
                    <Col xxl={{ span: 5, order: 3, pull: 4 }} xl={{ span: 12, order: 4 }} lg={{ span: 12, order: 4 }} md={{ span: 24, order: 3 }} sm={{ span: 24, order: 4 }}>
                        <InputNumber min={1} value={cantidad} className="width-100" onChange={(value) => onChangeCantidad(id, value)} />
                    </Col>
                    <Col xxl={{ span: 20, order: 4 }} xl={{ span: 24, order: 3 }} lg={{ span: 24, order: 3 }} md={{ span: 24, order: 4 }} sm={{ span: 24, order: 3 }} >
                        <Input placeholder="Descripción" value={descripcion} disabled />
                    </Col>
                    <Col xxl={{ span: 4, order: 5 }} xl={{ span: 12, order: 5 }} lg={{ span: 12, order: 5 }} md={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }}>
                        <Button className="producto-delete" onClick={() => deleteProducto(id, variante_id)} icon={<IconTrashVenta />} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Col>
}

/**
 *
 *
 * @const CardCliente
 * @extends {React.Component}
 * @description Card para la informacion cliente
 */
const CardCliente = ({ loading = false,
    nombre = "",
    id = null,
    fecha = new Date(),
    direccion = "",
    localidad = "",
    telefono = "",
    email = "",
    onClick = () => { }, }) => {
    return <Card
        loading={loading}
        className="card-info"
        title={nombre}
        extra={<Button
            type='primary'
            ghost
            icon={<IconBtnEdit color={'currentColor'} />}
            id={id}
            onClick={onClick}
        >
        </Button>}

    >
        <Row gutter={[8, 8]}>
            <Col span={24}><Text>Cliente desde {moment(fecha).fromNow()}</Text></Col>
            <Col span={24}><Text>{direccion}</Text></Col>
            <Col span={24}><Text>{localidad}</Text></Col>
            <Col span={24}><Text><a target="_blank" href={`tel:${telefono}`}>{telefono}</a> / <a target="_blank" href={`mailto:${email}`}>{email}</a></Text></Col>
        </Row>
    </Card>
}

/**
 *
 *
 * @const CardUsuario
 * @extends {React.Component}
 * @description Card para la informacion del usuario
 */
const CardUsuario = ({
    nombre = "",
    extra = null,
    fecha = new Date(),
    direccion = "",
    ubicacion = "",
    telefono = "",
    email = ""

}) => {
    return <Card
        className="card-info"
        title={nombre}
        extra={extra}
    >
        <Row>
            <Col span={24}><Text>Cliente desde {fecha}</Text></Col>
            <Col span={24}><Text>{direccion}</Text></Col>
            <Col span={24}><Text>{ubicacion}</Text></Col>
            <Col span={24}><Text>{telefono} / {email}</Text></Col>
        </Row>
    </Card>
}

function CardNominasContainer({ title, children, extra, className }) {
    return <Card
        size="small"
        className={`card-statistics  ${className}`}
        style={{ width: "100%" }}
        title={title ? <Text className="card-title">{title}</Text> : null}
        extra={extra}
    >
        {children}
    </Card>
}

const CardEmpleadoFinanzas = ({ data, onChange, onCheck, getData, finalizado }) => {

    const cardRef = React.createRef()
    const [areas, setAreas] = React.useState(undefined)
    const [rubros, setRubros] = React.useState(undefined)
    const [cuentas, setCuentas] = React.useState(undefined)
    const [areaId, setAreaId] = React.useState(undefined)
    const [loading, setLoading] = React.useState(false)
    const [checked, setChecked] = React.useState(false)

    React.useEffect(() => {
        cardRef.current?.setFieldsValue(data)
        setAreaId(data.area?._id)
    })

    const getCuentas = ({ search }) => {

    }

    const getAreas = ({ search }) => {

    }

    const getRubros = ({
        area_id = areaId,
        search
    }) => {

    }

    /**
     * @function pagarNomina
     * @description Realiza el pago de una nomina_empleado
     */
    const pagarNomina = () => {
        setLoading(true)

        const empleado_nomina = {
            _id: data._id,
            salario: data.salario,
            monto_autorizado: data.monto_autorizado
        }

        axios.post('/empleado-nomina/pagar', {
            empleados_nominas: [empleado_nomina]
        }).then(response => {
            message.success("Empleado pagado")
        }).catch(error => {
            console.log("error", error);
            message.error('Error al pagar al empleado')
        }).finally(() => {
            getData()
            setLoading(false)
        })
    }

    const disabled = data.monto_pagado >= data.monto_autorizado || finalizado

    return (
        <Row className="width-100 card-empleado-finanzas" align="middle" gutter={[5, 5]}>
            <Col flex="none" className="pl-1 pr-1">
                <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => {
                        setChecked(!checked)
                        onCheck(data._id)
                    }}
                />
            </Col>
            <Col flex="auto">
                <Card className="card-list">
                    <Form
                        ref={cardRef}
                        onValuesChange={(changedValues, values) => {
                            onChange(data._id, values)
                        }}
                    >
                        <Row className="width-100" gutter={[20, 16]}>
                            <Col xs={12} md={10} className="center">
                                <Text ellipsis>{data.empleado_id?.nombre} {data.empleado_id?.apellido ? data.empleado_id?.apellido : ''} </Text>
                            </Col>
                            <Col xs={12} md={6} className="center">
                                <Form.Item
                                    name="salario"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <InputNumber
                                        disabled={disabled}
                                        addonBefore="$"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        className="width-100"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={6} className="center">
                                <Form.Item
                                    name="monto_autorizado"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <InputNumber
                                        disabled={disabled}
                                        addonBefore="$"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        className="width-100"
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={12} md={4} className="center">
                            <Form.Item
                                name="cuenta_id"
                            >
                                <Select
                                    showSearch
                                    disabled={true}
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(search) => this.getCuentas({search})}
                                    onFocus={() => {
                                        if(!cuentas){
                                            getCuentas()
                                        }
                                    }}
                                    notFoundContent={"No se encontraron las Cuentas"}
                                >
                                    {
                                        cuentas && cuentas.map(({ _id, nombre }) => {
                                            return <Select.Option key={_id} value={_id}>{nombre}</Select.Option>
                                        })
                                    }
                                    {
                                        data.cuenta && (
                                            <Select.Option key={data.cuenta._id} value={data.cuenta._id}>{data.cuenta.nombre}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={4} className="center">
                            <Form.Item
                                name="area_id"
                            >
                                <Select
                                    showSearch
                                    disabled={true}
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(search) => this.getAreas({search})}
                                    onSelect={(_id) => {
                                        setAreaId(_id)
                                        getRubros({area_id: _id})
                                    }}
                                    onFocus={() => {
                                        if(!areas){
                                            getAreas()
                                        }
                                    }}
                                    notFoundContent={"No se encontraron las Areas"}
                                >
                                    {
                                        areas && areas.map(({ _id, nombre }) => {
                                            return <Select.Option key={_id} value={_id}>{nombre}</Select.Option>
                                        })
                                    }
                                    {
                                        data.area && (
                                            <Select.Option key={data.area._id} value={data.area._id}>{data.area.nombre}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={3} className="center">
                            <Form.Item
                                name="rubro_id"
                            >
                                <Select
                                    showSearch
                                    disabled={true}
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(search) => this.getRubros({search})}
                                    onFocus={() => {
                                        if(!rubros){
                                            getRubros()
                                        }
                                    }}
                                    notFoundContent={"No se encontraron los Rubros"}
                                >
                                    {
                                        rubros && rubros.map(({ _id, nombre }) => {
                                            return <Select.Option key={_id} value={_id}>{nombre}</Select.Option>
                                        })
                                    }
                                    {
                                        data.rubro && (
                                            <Select.Option key={data.rubro._id} value={data.rubro._id}>{data.area.nombre}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col> */}
                            <Col xs={12} md={2} className="center">
                                <Popconfirm
                                    placement="topRight"
                                    title="¿Deseas realizar el pago?"
                                    onConfirm={() => pagarNomina()}
                                    disabled={disabled}
                                    okText="Sí"
                                    cancelText="No"
                                >
                                    <Button type="primary" loading={loading} className={`button-${disabled ? 'pagado' : 'pagar'}`} title="Pagar" icon={<IconCashier />} />
                                </Popconfirm>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}


export {
    CardHacienda,
    CardInfoHacienda,
    CardPlantasHacienda,
    CardPlantasValores,
    CardStatistics,
    CardStatisticsSmall,
    CardProducto,
    CardProductoVenta,
    CardCliente,
    CardUsuario,
    CardNominasContainer,
    CardEmpleadoFinanzas
}